import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE, VERIFY_REQUEST, VERIFY_SUCCESS, START_TRACKING, STOP_TRACKING, UPDATE_USER, CLEAR_USER } from '../actions'

const initialState = {
  isLoggingIn: false,
  isLoggingOut: false,
  isVerifying: false,
  loginError: null,
  logoutError: false,
  isAuthenticated: false,
  isTrackingUser: false,
  user: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER: 
      return {
        ...state,
        user: action.user
      }
    case CLEAR_USER: 
      return {
        ...state,
        user: {}
      }
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: action.payload
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        user: {}
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true
      };
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false
      };
    case VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false
      };
    case START_TRACKING:
      return {
        ...state,
        isTrackingUser: true
      }
    case STOP_TRACKING:
      return {
        ...state,
        isTrackingUser: false
      }
    default:
      return state;
  }
};