import React, { Component } from "react";

import firebase from "../../firebase";

import { Table, InputGroup, FormControl } from "react-bootstrap";

var database = firebase.database();
var isEqual = require("lodash.isequal");

class CompaniesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      filteredCompanies: [],
      
    };
  }

  async componentDidMount() {
    await this.getCompanies()

  }

  async getCompanies() {

    const companies = await database.ref(`/companies/`).once('value')
    const companiesData = Object.values(companies.val()).sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase()
    })

    this.setState({ companies: companiesData })
    this.setState({ filteredCompanies: companiesData})

  }

  handleSearch = (e) => {
    const { companies } = this.state
    const results = companies.filter((data) =>  JSON.stringify(data).toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    this.setState ({ filteredCompanies: results })
  }

  convertDate(dateString) {
    const dateRaw = new Date(dateString)
    const date = dateRaw.toString()
    return date
  }

  render() {
    const  companies = this.state.filteredCompanies;

    if (companies) {
      return (
        <>
        <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text id="inputGroup-sizing-default">Search Companies</InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl
    onChange={e => this.handleSearch(e)}
      aria-label="Default"
      aria-describedby="inputGroup-sizing-default"
    />
  </InputGroup>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Number of Rooms</th>
                <th>Company Id</th>
                <th>Status</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {companies.map((company) => (
                
               
                <>
                  <tr>
                    <td>{company.name}</td>
                    <td><a href={`https://console.firebase.google.com/project/gatheronline-573d7/database/gatheronline-dev/data/~2Fcompanies~2F${company.id}~2Frooms`}>{Object.keys(company.rooms).length}</a></td>
                    <a href={`https://console.firebase.google.com/project/gatheronline-573d7/database/gatheronline-dev/data/~2Fcompanies~2F${company.id}`}>{company.id}</a>
                    <td>{company.subscription.status} : {company.subscription.type}</td>
                    <td>{this.convertDate(company.createdAt)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </>
      );
    } else return null;
  }
}

export default CompaniesTable;
