import React, { Component } from "react"

import firebase from "../../../firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import Switch from "react-switch"
import Modal from "react-bootstrap/Modal"

import "./RoomView.css"

var database = firebase.database()

export class RoomCell extends Component {
    constructor(props) {
        super(props)

        this.state = {
            room: null,
            isSpaceManager: props.isSpaceManager,
            isPrivate: false,
            isManagingRoom: false,
            showUsersModal: false,
            newRoomName: "",
            uid: props.uid,
        }
    }

    componentDidMount() {
        this.streamRoom()
        this.createAllowedUsersNode()
    }

    componentWillUnmount() {
        const { roomId } = this.props

        database.ref(`/rooms/${roomId}/`).off("value")
    }

    componentDidUpdate(_prevProps, prevState) {
        const { room, isPrivate } = this.state
        const { isSpaceManager } = this.props

        if (room && isPrivate !== room.isPrivate) this.setState({ isPrivate: room.isPrivate })
        if (isSpaceManager !== prevState.isSpaceManager) {
            this.setState({ isSpaceManager })
        }
    }

    streamRoom() {
        const { roomId } = this.props

        database.ref(`/rooms/${roomId}/`).on("value", (roomSnap) => {
            this.setState({ room: roomSnap.val() })
        })
    }

    async changeRoomPrivacy() {
        const { isPrivate } = this.state
        const { roomId } = this.props

        const privacy = !isPrivate

        database.ref(`/rooms/${roomId}/isPrivate`).set(privacy)
    }

    async finishManaging() {
        const { newRoomName } = this.state
        const { roomId } = this.props
        if (newRoomName.length > 0 && newRoomName.length < 4) {
            this.setState({ isManagingRoom: false })
            this.setState({ newRoomName: "" })
            alert("Room name needs to be at least 4 characters!")
            return
        }

        if (newRoomName.length !== 0) {
            await database.ref(`/rooms/${roomId}/name`).set(newRoomName)
        }

        this.setState({ isManagingRoom: false })
        this.setState({ newRoomName: "" })
    }

    handleInput = (e) => {
        this.setState({ newRoomName: e.target.value })
    }

    allowUser(id) {
        const { room } = this.state
        const { roomId } = this.props
        const isAllowed = !room.allowedUsers[id]
        database.ref(`/rooms/${roomId}/allowedUsers/${id}`).set(isAllowed)
    }

    createAllowedUsersNode() {
        const { room } = this.state
        const { users, roomId } = this.props

        if (!room) return
        if (room.allowedUsers) return

        const usersInfo = Object.values(users)
        let allowedUsers = {}

        for (let i = 0; i < usersInfo.length; i++) {
            allowedUsers[usersInfo[i].id] = false
        }

        database.ref(`/rooms/${roomId}/allowedUsers`).set(allowedUsers)
    }

    getActivityName(currentActivity) {
        var text = "Playing Unknown Activity"

        if (!currentActivity) text = "No Current Activity"

        if (currentActivity && currentActivity.activityDetails && currentActivity.activityDetails.name) {
            text = `Playing ${currentActivity.activityDetails.name}`
        }

        return text
    }

    renderUsersModal() {
        const { showUsersModal, room } = this.state
        const { users } = this.props

        const usersInfo = Object.values(users)

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showUsersModal}
                onHide={() => this.setState({ showUsersModal: false })}
            >
                <Modal.Body>
                    <h6 className="mb-2 text-dark">Who can access this room?</h6>
                    <div className="d-flex justify-content-center align-items-center" />
                    <div className="row container">
                        {usersInfo.map((user) => (
                            <div key={user.id} className="col-4 d-flex justify-content-between">
                                <p>{user.displayName}</p>
                                <input
                                    type="checkbox"
                                    defaultChecked={room.allowedUsers ? room.allowedUsers[user.id] : false}
                                    onChange={this.allowUser.bind(this, user.id)}
                                />
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button
                        className="orange-btn cool-btn active-button"
                        onClick={() => this.setState({ showUsersModal: false })}
                    >
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderManagingView() {
        const { room, isPrivate } = this.state

        return (
            <div key={room.id} className="room-cell">
                <div className="d-flex flex-row w-100">
                    <label for="room-name-edit" className="mr-2 mb-0 align-self-center">
                        Name
                    </label>
                    <input
                        onChange={(e) => this.handleInput(e)}
                        className="form-control ml-3"
                        id="room-name-edit"
                        placeholder={room.name}
                    />
                </div>
                <div className="mt-2 mb-2 d-flex flex-row w-100 align-items-center justify-content-between">
                    <p className="mb-0">Private Room</p>
                    <Switch
                        className="align-items-center"
                        onChange={this.changeRoomPrivacy.bind(this)}
                        checked={this.state.isPrivate}
                    />
                </div>
                <div className="mt-2 d-flex flex-row w-100 align-items-center justify-content-between">
                    <button
                        className="room-join-btn hover-btn orange-btn cool-btn"
                        disabled={!isPrivate}
                        onClick={() => this.setState({ showUsersModal: true })}
                    >
                        Add Users
                    </button>
                    <button
                        className="room-join-btn hover-btn orange-btn cool-btn"
                        onClick={this.finishManaging.bind(this)}
                    >
                        Done
                    </button>
                </div>
            </div>
        )
    }

    renderRoomView() {
        const { room, isSpaceManager, uid } = this.state

        const MAX_ROOM_PEOPLE = 8

        const hasActiveCall = room.callSession
        const activeCallText = hasActiveCall ? "In Active Call · " : ""

        const people = room.who ? Object.values(room.who).length : 0
        const peopleText = people === 0 || people > 1 ? `${people} people` : `${people} person`
        const peopleColour = people < MAX_ROOM_PEOPLE ? "light-text" : "light-text"

        const canEnterRoom = people < MAX_ROOM_PEOPLE
        const hasPermissionToEnter = !room.isPrivate || (room.isPrivate && room.allowedUsers && room.allowedUsers[uid])

        var subText = this.getActivityName(room.currentActivity)

        return (
            <div key={room.id} className="room-cell">
                <h3 className="mb-2 light-text">{room.name}</h3>
                <h6 className={`text-align-right ${peopleColour} mb-1`}>
                    <span className="green-text">{activeCallText}</span>
                    {peopleText}
                </h6>
                <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-2">
                    <div className="room-join-btn-container">
                        {isSpaceManager ? (
                            <button
                                className="room-join-btn hover-btn orange-btn cool-btn"
                                onClick={() => this.setState({ isManagingRoom: !this.state.isManagingRoom })}
                            >
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                        ) : null}
                    </div>
                    <button
                        className="room-join-btn hover-btn blue-btn cool-btn"
                        disabled={!canEnterRoom || !hasPermissionToEnter}
                        onClick={() => this.props.enterRoomURL(room.id)}
                    >
                        Go to Room <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                </div>
            </div>
        )
    }

    render() {
        const { room, isManagingRoom } = this.state

        var view = null

        if (room) {
            view = (
                <div className="room-cell-container">
                    {isManagingRoom ? this.renderManagingView() : this.renderRoomView()}
                    {this.renderUsersModal()}
                </div>
            )
        }

        return view
    }
}

export default RoomCell
