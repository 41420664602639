import React, { Component } from "react";

import firebase from "../../firebase";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

import { Table, InputGroup, FormControl, Button, Modal, Tab } from "react-bootstrap";

var database = firebase.database();
var isEqual = require("lodash.isequal");

class CallHistoryTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callHistory: [],
      filteredCallHistory: [],
      companies: null,
      users: null,
      activities: null,
      callUsers: null,
      showUsersModal: false,
      showActivtiesModal: false,
    };
  }

  async componentDidMount() {
    await this.getGroupsWithCallHistory();
    await this.getCompanies();
    await this.getUsers();
  }

  async getGroupsWithCallHistory() {
    const allCalls = [];
    const groups = await database.ref(`/groups/`).once("value");
    const groupsData = Object.values(groups.val()).sort((a, b) => {
      return a.name.toLowerCase() > b.name.toLowerCase();
    });

    const groupsWithCallHistory = groupsData.filter(
      (g) => g.previousTokSessions
    );

    for (let i = 0; i < groupsWithCallHistory.length; i++) {
      const groupId = groupsWithCallHistory[i].id;
      const parentCompanyId = groupsWithCallHistory[i].parentCompanyId;
      const organizerId = groupsWithCallHistory[i].organizer;
      const groupCallHistory = Object.values(
        groupsWithCallHistory[i].previousTokSessions
      );

      for (let ii = 0; ii < groupCallHistory.length; ii++) {
        const sessionId = groupCallHistory[ii].sessionId;
        const timeStarted = groupCallHistory[ii].timeStarted;
        const timeEnded = groupCallHistory[ii].timeEnded;
        const duration = groupCallHistory[ii].duration
        const users = groupCallHistory[ii].users;
        const previousActivities = groupCallHistory[ii].previousActivities
        const callInfo = {
          groupId,
          parentCompanyId,
          organizerId,
          sessionId,
          timeStarted,
          timeEnded,
          duration,
          users,
          activities: previousActivities
        };
        allCalls.push(callInfo);
      }
    }
    const sortedCalls = this.sortCallsByDate(allCalls)
    this.setState({ callHistory: sortedCalls });
    this.setState({ filteredCallHistory: sortedCalls });
  }

  async getCompanies() {
    const companiesRaw = await database.ref(`/companies`).once("value");
    const companies = companiesRaw.val();
    this.setState({ companies });
  }

  async getUsers() {
    const usersSnap = await database.ref(`/users`).once("value");
    const users = usersSnap.val();
    this.setState({ users });
  }

  handleSearch = (e) => {
    const { callHistory } = this.state;
    const results = callHistory.filter(
      (data) =>
        JSON.stringify(data)
          .toLowerCase()
          .indexOf(e.target.value.toLowerCase()) !== -1
    );
    this.setState({ filteredCallHistory: results });
  };

  sortCallsByDate(calls) {
    const sortedCalls = calls.sort(function(a,b){
      return new Date(b.timeEnded) - new Date(a.timeEnded)
    })


    return sortedCalls
    
  }

  getOrganizerName(id, companyId) {
    const { users, companies } = this.state;
    if(!users || !companies) return 'fetching info...'
    let name = null
    let email = null
    if (companies && !id) {
      const companyUsers  = Object.values(companies[companyId].users)
      for (let i = 0; i < companyUsers.length; i++){
        if(companyUsers[i].type == "leader") {
          try{
            name = companyUsers[i].displayName
          const organizerId = companyUsers[i].id
           email = users[organizerId].info.providerData[0].email
          }
          catch(e){
            console.log(e)
          }
        }
      }
      return name + ' : ' + email
    }

    if (users) {
      name = users[id].info.displayName;
      email = users[id].info.providerData[0].email
      return name + ' : ' + email
    } else return null;
  }

  getCompanyName(id) {
    const { companies } = this.state;
    let name = null;
    try {
      name = companies[id].name;
    } catch (e) {
      name = "Deleted Company";
    }

    return name;
  }

  getCallLength(duration, start, end) {
    if(duration){
      if(duration <= 60) return duration + ' seconds'
      const callLengthRaw = duration / 60
      const callLength = Math.trunc(callLengthRaw)
      return callLength + ' minute(s)'
    }

    if(start && end){
      const startDate = new Date(start)
      const endDate = new Date(end)
      const differenceMs = Math.abs(endDate - startDate)
      const differenceS = differenceMs / 1000
      const callLength = Math.trunc(differenceS)
      if(callLength <= 60) return callLength + ' seconds'
      else {
        const callLengthMinutes = Math.trunc(callLength / 60)
        return callLengthMinutes + ' minutes'
      }
    }

    else return 'No call length available'
  }

  getUserNames(sessionId, allUserInfo) {
    const { users } = this.state;
    
    if (!allUserInfo) return 'User Info Unavailable'

    const userIds = Object.keys(allUserInfo);
    let allUserNames = "";

    if (users) {
      for (let i = 0; i < userIds.length; i++) {
        const userName = users[userIds[i]].info.displayName;
        allUserNames += userName + ` `;
      }
      return 'Names: ' + allUserNames;
    } else return null;
  }

  getCallUsers(users, id){
    const callUsers = []

    try {
    const response = fetch(`https://us-central1-gatheronline-573d7.cloudfunctions.net/api/getSessionDetails`, {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
        "Content-Type": "application/json",
      }
    })

    const resp = response.json()
    console.log('resp', resp)
    const users = resp.users
    for(let i = 0; i < users.content.length; i++){
      const userInfoRaw = JSON.parse(users.content[i].connections.content[0].clientData)
      const userInfo = {
        name: userInfoRaw.name,
        id: userInfoRaw.id
      }
      callUsers.push(userInfo)
    }
    this.setState({ showUsersModal: true, callUsers })
  } catch (error) {
    alert('error getting call users')
    users.numberOfElements = -1
  }

  }

  getActivities(activities){
    if(!activities) {
      alert('No activities played in this session.')
      return
    }
    
    const activityDetails = Object.values(activities)

    this.setState({ showActivtiesModal: true, activities: activityDetails })
  }

  convertTime(time){
    const secondsRaw = time / 1000
    const seconds = Math.trunc(secondsRaw)
    if(seconds <= 60) return seconds + ' second(s)'
    const minutes = seconds / 60
    const duration = Math.trunc(minutes)
    return duration + ' minute(s)'
  }

  renderActivitiesModal() {

    const { activities, showActivtiesModal } = this.state

    if(!activities) return
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showActivtiesModal}
        onHide={() => this.setState({ showActivtiesModal: false, activities: null })}
      >
        <Modal.Body>
          <h6 className='mb-2 text-dark'>Activites Played</h6>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Activity Name</th>
                <th>Activity Length</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity) => (
                <>
                  <tr>
                    <td>{activity.activityDetails.name}</td>
                    <td>{this.convertTime(activity.activityTime)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className='d-flex flex-row justify-content-between align-items-center'>
          <button className="orange-btn cool-btn active-button" onClick={() => this.setState({ showActivtiesModal: false, activities: null })}>
            Done
            </button>
        </Modal.Footer>
      </Modal>
    )

  }

  renderUsersModal(){
    const {  showUsersModal, callUsers } = this.state

    if(!callUsers) return
    return (
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showUsersModal}
        onHide={() => this.setState({ showUsersModal: false, callUsers: null })}
      >
        <Modal.Body>
          <h6 className='mb-2 text-dark'>Users In Call</h6>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Id</th>
              </tr>
            </thead>
            <tbody>
            {callUsers.map((user) => (
                <>
                  <tr>
                    <td>{user.name}</td>
                    <td>{user.id}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className='d-flex flex-row justify-content-between align-items-center'>
          <button className="orange-btn cool-btn active-button" onClick={() => this.setState({ showUsersModal: false, callUsers: null })}>
            Done
            </button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const callHistory = this.state.filteredCallHistory;
    if (callHistory) {
      return (
        <>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-default">
                Search Calls
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={(e) => this.handleSearch(e)}
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
            />
          </InputGroup>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Group Id</th>
                <th>Organizer</th>
                <th>Parent Company</th>
                <th>
                  Call Length
                </th>
                <th>Users</th>
                <th>Activities</th>
              </tr>
            </thead>
            <tbody>
              {callHistory.map((call) => (
                <>
                  <tr>
                    <td>
                      <a
                        href={`https://console.firebase.google.com/u/0/project/gatheronline-573d7/database/gatheronline-573d7/data/~2Fgroups/${call.groupId}`}
                      >
                        {call.groupId}
                      </a>
                    </td>
                    <td>{this.getOrganizerName(call.organizerId, call.parentCompanyId)}</td>
                    <td>
                      <a
                        href={`https://console.firebase.google.com/u/0/project/gatheronline-573d7/database/gatheronline-573d7/data/~2Fcompanies/${call.parentCompanyId}`}
                      >
                        {this.getCompanyName(call.parentCompanyId)}
                      </a>
                    </td>
                    <td>{this.getCallLength(call.duration, call.timeStarted, call.timeEnded)}</td>
                    <td><Button onClick={this.getCallUsers.bind(this, call.users, call.sessionId)}>Users</Button></td>
                    <td><Button onClick={this.getActivities.bind(this, call.activities)}>Activities</Button></td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
          {this.renderActivitiesModal()}
          {this.renderUsersModal()}
        </>
      );
    } else return null;
  }
}

export default CallHistoryTable;
