import firebase from "../firebase"

var database = firebase.database()

const pino = require('pino')({
    browser: {
        write: {
            info: async function (o) {
                const { level, time } = o
                await database.ref(`/logs/${level}/${time}`).set(o)
                console.log(o)
            },
            warn: async function (o) {
                const { level, time } = o
                await database.ref(`/logs/${level}/${time}`).set(o)
                console.log(o)
            },
            error: async function (o) {
                const { level, time } = o
                await database.ref(`/logs/${level}/${time}`).set(o)
                console.log(o)
            }
        }
    }
})

export default pino