import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { verifyAuth } from './actions/';
import reducers from './reducers';

export default function configureStore(persistedState) {
  const store = createStore(
    reducers,
    persistedState,
    applyMiddleware(thunk)
  );
  store.dispatch(verifyAuth());
  return store;
}