import React, { Component } from 'react'

import '../Widgets.css'
class QuotesWidget extends Component {

  constructor(props) {
    super(props)
  }

  render() {

    const layoutString = this.props.dimensions

    return (
      <div className={`cell-bg quotes-cell ${layoutString}`}>
        <h2>QUOTE_HERE</h2>
        <div className='d-flex flex-row align-items-center justify-content-end'>
          <p>MARCUS AURELIUS</p>
        </div>
      </div>
    )
  }
}

export default QuotesWidget
