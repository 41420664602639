import React, { Component } from "react"
import "../assets/css/Toolbar.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faHome,
    faPhoneSlash,
    faMicrophone,
    faVideo,
    faCog,
    faCommentAlt,
    faMicrophoneSlash,
    faVideoSlash,
    faPencilAlt,
    faInfo,
    faUserLock,
    faDesktop,
    faChevronUp,
} from "@fortawesome/free-solid-svg-icons"
import { faIntercom } from "@fortawesome/free-brands-svg-icons"

import Modal from "react-bootstrap/Modal"

import { startActivity, endActivity } from "../redux/actions"
import { connect } from "react-redux"

var isEqual = require("lodash.isequal")

export class Toolbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentActivity: null,
            currentRoom: null,
            databaseSession: null,
            roomId: "",
            home: true,
            intercom: false,
            audio: props.audio,
            video: props.video,
            queuedActivities: [],
            settings: props.settings,
            chat: props.chat,
            sharing: false,
            info: props.info,
            manage: props.manage,
            queue: props.queue,
            currentUserState: props.currentUserState,
            screenShareSubscriber: false,
            screenSharePublisher: false,

            session: props.session,
            isInActivity: props.isInActivity,
            startingCall: props.startingCall,
            codes: props.codes,
            isSpaceManager: props.isSpaceManager,
            hasUnreadMessages: props.hasUnreadMessages,
            hasActivitiesInQueue: props.hasActivitiesInQueue,
            isOutOfTime: props.isOutOfTime,
        }
    }

    componentDidMount() { }

    componentWillUnmount() { }

    static getDerivedStateFromProps(nextProps, prevState) {
        const shouldUpdateChat = nextProps.chat !== prevState.chat
        const shouldUpdateSettings = nextProps.settings !== prevState.settings
        const shouldUpdateSession = nextProps.session !== prevState.session
        const shouldUpdateRoomId = nextProps.roomId !== prevState.roomId
        const shouldUpdateStartingCall = nextProps.startingCall !== prevState.startingCall
        const shouldUpdateCurrentRoom = nextProps.currentRoom !== prevState.currentRoom
        const shouldUpdateNotification = nextProps.hasUnreadMessages !== prevState.hasUnreadMessages
        const shouldUpdateQueue = nextProps.queue !== prevState.queue
        const shouldUpdateQueueNotification = nextProps.hasActivitiesInQueue !== prevState.hasActivitiesInQueue
        const shouldUpdateAudio = nextProps.audio !== prevState.audio
        const shouldUpdateVideo = nextProps.video !== prevState.video
        const shouldUpdateCurrentUserState = nextProps.currentUserState !== prevState.currentUserState

        if (shouldUpdateChat) {
            return { chat: nextProps.chat }
        } else if (shouldUpdateSession) {
            return { session: nextProps.session }
        } else if (shouldUpdateSettings) {
            return { settings: nextProps.settings }
        } else if (shouldUpdateRoomId) {
            return { roomId: nextProps.roomId }
        } else if (shouldUpdateStartingCall) {
            return { startingCall: nextProps.startingCall }
        } else if (shouldUpdateCurrentRoom) {
            return { currentRoom: nextProps.currentRoom }
        } else if (shouldUpdateNotification) {
            return { hasUnreadMessages: nextProps.hasUnreadMessages }
        } else if (shouldUpdateQueue) {
            return { queue: nextProps.queue }
        } else if (shouldUpdateAudio) {
            return { audio: nextProps.audio }
        } else if (shouldUpdateVideo) {
            return { video: nextProps.video }
        } else if (shouldUpdateQueueNotification) {
            return { hasActivitiesInQueue: nextProps.hasActivitiesInQueue }
        } else if (shouldUpdateCurrentUserState) {
            return { currentUserState: nextProps.currentUserState }
        } else {
            return null
        }
    }

    async componentDidUpdate(_prevProps, prevState) {
        const {
            chat,
            session,
            settings,
            startingCall,
            currentRoom,
            hasUnreadMessages,
            queue,
            hasActivitiesInQueue,
            isSpaceManager,
            audio,
            video,
        } = this.props

        if (chat !== prevState.chat) {
            this.setState({ chat })
        }

        if (settings !== prevState.settings) {
            this.setState({ settings })
        }

        if (session !== prevState.session) {
            this.setState({ session })
        }

        if (startingCall !== prevState.startingCall) {
            this.setState({ startingCall })
        }

        if (!isEqual(currentRoom, prevState.currentRoom) && currentRoom) {
            this.updateCurrentRoomState(currentRoom)
        }

        if (hasUnreadMessages !== prevState.hasUnreadMessages) {
            this.setState({ hasUnreadMessages })
        }

        if (queue !== prevState.queue) {
            this.setState({ queue })
        }

        if (audio !== prevState.audio) {
            this.setState({ audio })
        }

        if (video !== prevState.video) {
            this.setState({ video })
        }

        if (hasActivitiesInQueue !== prevState.hasActivitiesInQueue) {
            this.setState({ hasActivitiesInQueue })
        }
        if (isSpaceManager !== prevState.isSpaceManager) {
            this.setState({ isSpaceManager })
        }
    }

    // Listeners

    updateCurrentRoomState(room) {
        const currentActivity = room.currentActivity
        const queueRaw = room.queue.raw
        const queuedActivities = room.queue.list
        const databaseSession = room.callSession.id.length > 0

        this.setState({
            currentRoom: room,
            roomId: room.id,
            currentActivity,
            databaseSession,
            queuedActivities,
            queueRaw,
        })
    }

    // toolbar functions

    async goHome() {
        const { session } = this.state

        if (session) {
            this.props.endCall(false)
        }

        this.setState({
            currentActivity: null,
            room: null,
            roomId: "",
            queuedActivities: [],
            session: null,
            isInActivity: false,
        })
        this.props.goHome()
    }

    endCall() {
        this.props.endCall()
    }

    toggleIntercom() {
        this.setState({ intercom: !this.state.intercom })
    }

    toggleAudio() {
        // this.setState({ audio: !this.state.audio })
        this.props.toggleAudio()
    }

    toggleVideo() {
        // this.setState({ video: !this.state.video })
        this.props.toggleVideo()
    }

    toggleScreenShare() {

        this.props.toggleScreenShare()
    }

    toggleSettings() {
        this.setState({ settings: !this.state.settings })
        this.props.toggleSettingsModal()
    }

    toggleChat() {
        this.setState({ chat: !this.state.chat })
        this.props.toggleChat()
    }

    toggleEdit() {
        this.setState({ edit: !this.state.edit })
        this.props.toggleEditMode()
    }

    toggleInfo() {
        this.setState({ info: !this.state.info })
        this.props.toggleInfoView()
    }

    toggleManage() {
        this.setState({ manage: !this.state.manage })
        this.props.toggleManageModal()
    }

    toggleQueue() {
        this.setState({ queue: !this.state.queue })
        this.props.toggleQueueDropdown()
    }

    async copyText(text) {
        await navigator.clipboard.writeText(text)
        this.shareRoom()
    }

    shareRoom() {
        this.setState({ sharing: !this.state.sharing })
    }

    toggleUserLocationType() {
        this.props.toggleUserLocationType()
    }

    getHelperCode() {
        this.props.getAndShowHelperCode()
    }

    async joinCall() {
        const { databaseSession } = this.state
        this.props.joinCall(databaseSession)
    }

    restartActivity() {
        this.start(true)
    }

    end() {
        const { currentActivity, currentRoom } = this.state
        const { dispatch } = this.props

        let who = currentRoom.callSession.users
        this.props.afterActivityMetrics(currentActivity.activityDetails.company_metrics, who)

        dispatch(endActivity())
    }

    async start(restartCurrent) {
        const { dispatch } = this.props
        // automatically handles initial/next activity
        dispatch(startActivity(restartCurrent))
    }

    async nextActivity() {
        const { currentActivity, currentRoom } = this.state

        if (!currentActivity) return null
        if (!currentActivity.queueInfo.next.length === 0) return null
        let who = currentRoom.callSession.users

        this.props.afterActivityMetrics(currentActivity.activityDetails.company_metrics, who)

        this.start(false)
    }

    isValidBrowserForOV() {
        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== "undefined"

        // Safari 3.0+ "[object HTMLElementConstructor]"
        var isSafari =
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
                return p.toString() === "[object SafariRemoteNotification]"
            })(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification))

        // Internet Explorer 6-11
        var isIE = /*@cc_on!@*/ false || !!document.documentMode

        // Edge 20+
        var isEdge = !isIE && !!window.StyleMedia

        // Chrome 1 - 79
        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)

        // Edge (based on chromium) detection
        var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1

        return !isEdge
    }

    // UI Helpers

    renderStartActivity() {
        return (
            <div key="Start" className="align-self-center mr-3">
                <button className="orange-btn hover-btn cool-btn" onClick={this.start.bind(this)}>
                    Start Activity
                </button>
            </div>
        )
    }

    renderRestartActivity() {
        return (
            <div key="Restart" className="align-self-center mr-3">
                <button className="orange-btn hover-btn cool-btn" onClick={this.restartActivity.bind(this)}>
                    Restart
                </button>
            </div>
        )
    }

    renderNextActivity() {
        return (
            <div key="Next" className="align-self-center mr-3">
                <button className="orange-btn hover-btn cool-btn" onClick={this.nextActivity.bind(this)}>
                    Next
                </button>
            </div>
        )
    }

    renderEndActivity() {
        return (
            <div key="End" className="align-self-center mr-3">
                <button className="red-btn hover-btn cool-btn" onClick={this.end.bind(this)}>
                    End
                </button>
            </div>
        )
    }

    renderHelperCodeButton() {
        return (
            <div key="Helper" className="align-self-center mr-3">
                <button className="purple-btn hover-btn cool-btn" onClick={this.getHelperCode.bind(this)}>
                    Helper Code
                </button>
            </div>
        )
    }

    renderUserLocationType() {
        const { currentUserState } = this.state

        if (currentUserState.length === 0) {
            return null
        }

        const backgroundClass = currentUserState === "REMOTE" ? "toggle-mode-blue" : "toggle-mode-red"
        const text = currentUserState === "REMOTE" ? "Remote" : "In Office"

        return (
            <div key="Helper" className="align-self-center ml-3">
                <button
                    className={`${backgroundClass} toggle-mode-btn hover-btn cool-btn`}
                    onClick={this.toggleUserLocationType.bind(this)}
                >
                    {text}
                </button>
            </div>
        )
    }

    renderInviteButton() {
        return (
            <div key="Invite" className="align-self-center mr-3">
                <button className="purple-btn hover-btn cool-btn" onClick={this.shareRoom.bind(this)}>
                    Invite Others
                </button>
            </div>
        )
    }

    renderCallButtons() {
        const { session, video, audio, startingCall, databaseSession, screenSharePublisher, screenShareSubscriber } = this.state
        const { isInRoom, isRoomHost } = this.props

        const isValidBrowser = this.isValidBrowserForOV()
        // join button or reconnect df isplays whether user is in a call while in the room
        if (isInRoom && isValidBrowser) {
            if (startingCall) {
                // render loading period
                return (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                        <p className="mb-0 text-center text-white">Starting a call...</p>
                    </div>
                )
            } else if (session) {
                // render toolbar calls
                return (
                    <div className="toolbar-grid toolbar-grid-center">
                        <div>
                            <div className={`round-btn-holder-call`}>
                                <FontAwesomeIcon
                                    icon={audio ? faMicrophone : faMicrophoneSlash}
                                    className="round-btn-holder-call-icon"
                                    onClick={this.toggleAudio.bind(this)}
                                />
                                {/* <FontAwesomeIcon icon={faChevronUp} className="round-btn-holder-call-icon-helper" /> */}
                            </div>
                        </div>
                        <div>
                            <div className={`round-btn-holder-call`}>
                                <FontAwesomeIcon
                                    icon={video ? faVideo : faVideoSlash}
                                    className="round-btn-holder-call-icon"
                                    onClick={this.toggleVideo.bind(this)}
                                />
                                {/* <FontAwesomeIcon icon={faChevronUp} className="round-btn-holder-call-icon-helper" /> */}
                            </div>
                        </div>
                        <div>
                            <div className={`round-btn-holder-call`}>
                                <FontAwesomeIcon
                                    icon={faDesktop}
                                    className={`round-btn-holder-call-icon ${screenShareSubscriber ? "disabled" : ""}`}
                                    style={{ opacity: !screenSharePublisher || !screenShareSubscriber ? 1 : 0.5 }}
                                    onClick={!screenShareSubscriber ? this.toggleScreenShare.bind(this) : null}
                                />
                            </div>
                        </div>

                    </div>
                )
            } else {
                // render start/join call buttons buttons

                const buttonText = databaseSession ? "Join Call" : isRoomHost ? "Start Call" : "Join Call"
                var button = (
                    <button className="hover-btn green-btn cool-btn" onClick={this.joinCall.bind(this)}>
                        {buttonText}
                    </button>
                )

                if (databaseSession && !isRoomHost) {
                    button = (
                        <button className="hover-btn green-btn cool-btn" onClick={this.joinCall.bind(this)}>
                            {buttonText}
                        </button>
                    )
                } else if (!databaseSession && !isRoomHost) {
                    button = <p className="mb-0 text-center">Waiting on host to start a call...</p>
                }

                return (
                    <div className="h-100 d-flex align-self-center justify-content-center">
                        {/* <div className="d-flex align-self-center justify-content-center">{button}</div> */}
                    </div>
                )
            }
        } else if (!isValidBrowser) {
            return (
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <p className="mb-0 text-center">Please use Google Chrome to start a call!</p>
                </div>
            )
        } else {
            return (
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <p className="mb-0 text-center">Join a room to start a call!</p>
                </div>
            )
        }
    }

    renderEndToolbar() {
        const { currentActivity, chat, settings, edit, isSpaceManager, isOutOfTime, hasUnreadMessages } = this.state
        const { isInRoom } = this.props

        const buttonView = []

        if (currentActivity && this.props.isRoomHost) buttonView.push(this.renderEndActivity())

        buttonView.push(this.renderInviteButton())

        const chatToggle = isInRoom ? (
            <div>
                <div
                    onClick={this.toggleChat.bind(this)}
                    className={`round-btn-holder ${chat ? "round-btn-holder-selected" : ""}`}
                >
                    <div className={`notification-icon ${!hasUnreadMessages ? "notification-icon-hidden" : ""}`} />
                    <FontAwesomeIcon icon={faCommentAlt} />
                </div>
            </div>
        ) : null

        return (
            <div className={`col-3 d-flex flex-row justify-content-end`}>
                {buttonView}
                <div className={`toolbar-grid ${isInRoom ? "toolbar-grid-end-extra" : "toolbar-grid-end"}`}>
                    {chatToggle}
                    <div>
                        <div
                            onClick={this.toggleSettings.bind(this)}
                            className={`round-btn-holder ${settings ? "round-btn-holder-selected" : ""}`}
                        >
                            <FontAwesomeIcon icon={faCog} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    renderSharingModal() {
        const { sharing, currentRoom } = this.state

        let roomUrl = ``
        let roomCode = ``

        if (currentRoom && currentRoom.id.length > 0) {
            roomUrl = `https://${window.location.hostname}/room/${currentRoom.id}`
            roomCode = `${currentRoom.quickAccessCode}`
        }

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={sharing}
                onHide={this.shareRoom.bind(this)}
            >
                <Modal.Body>
                    <h6 className="mb-0 text-dark">Sharing Information</h6>
                    <p className="dark-text">Share the URL or passcode to invite others to this room!</p>
                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            <label className="col-sm-2 col-form-label">URL</label>
                            <div className="col-sm-10">
                                <input className="sharing-code-input-url" readOnly value={roomUrl} />
                            </div>
                        </div>
                        <div className="d-flex">
                            <label className="col-sm-2 col-form-label">Passcode</label>
                            <div className="col-sm-10">
                                <input className="sharing-code-input-url" readOnly value={roomCode} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button className="orange-btn cool-btn active-button" onClick={this.shareRoom.bind(this)}>
                        Done
                    </button>
                    <button
                        className="orange-btn cool-btn active-button"
                        onClick={this.copyText.bind(this, roomCode)}
                    >
                        Copy
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        const { currentRoom, currentUserState } = this.state

        if (!currentRoom) return null

        let typeButton = null

        // if (currentUserState !== "") typeButton = this.renderUserLocationType()

        return (
            <>
                <div className="toolbar-bg">
                    <div className="col-3 d-flex flex-row justify-content-start">
                        <div className="toolbar-grid toolbar-grid-start">
                            <div>
                                <div
                                    onClick={this.goHome.bind(this)}
                                    className={`round-btn-holder ${!currentRoom || currentRoom.id.length === 0 ? "round-btn-holder-selected" : ""
                                        }`}
                                >
                                    <FontAwesomeIcon icon={faHome} />
                                </div>
                            </div>
                            <div>
                                <div
                                    onClick={this.toggleIntercom.bind(this)}
                                    id="custom-intercom-button"
                                    className="round-btn-holder"
                                >
                                    <FontAwesomeIcon icon={faIntercom} />
                                </div>
                            </div>
                        </div>
                        {typeButton}
                    </div>
                    <div className="col-6">{this.renderCallButtons()}</div>
                    {this.renderEndToolbar()}
                </div>
                {this.renderSharingModal()}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentRoom: state.room,
    }
}

export default connect(mapStateToProps)(Toolbar)
