export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

const updateNotification = (notify) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: notify
  };
};

export const notifyUser = (notify) => dispatch => {
  dispatch(updateNotification(notify))
}