import React, { Component } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

export class AnalyticsCardTotal extends Component {

handleClick() {
  alert("clicked")
}

  render() {
    return (
      <div className="AnalyticsCardTotal venue-holder align-items-center">
        <div className='total-points-container'>
          <h1>{this.props.totalPoints}</h1>
          <p>Points Overall</p>
          </div>
          <div className='row container'>

          <FontAwesomeIcon className='col-2' icon={faExclamationCircle} size='lg'/>
          <p className='col'>{this.props.healthDescription}</p>

          </div>

          <div className='row container'>
          <FontAwesomeIcon className='col-2' icon={faExclamationCircle} size='lg'/>

                <p className='col'>{this.props.scheduleTime}</p>
                </div>
          <button className="schedule-button justify-content-center venue-holder" onClick={this.handleClick.bind(this)}>Schedule Now</button>
      </div>
    );
  }
}

export default AnalyticsCardTotal;
