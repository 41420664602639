import firebase from "../../firebase"
import ReactGA from "react-ga"
import pino from "../../components/pino"

var database = firebase.database()

export const UPDATE_USER = "LOGIN_REQUEST"
export const CLEAR_USER = "LOGIN_REQUEST"
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILURE = "LOGOUT_FAILURE"
export const VERIFY_REQUEST = "VERIFY_REQUEST"
export const VERIFY_SUCCESS = "VERIFY_SUCCESS"
export const START_TRACKING = "START_TRACKING"
export const STOP_TRACKING = "STOP_TRACKING"

const requestLogin = () => {
    return {
        type: LOGIN_REQUEST,
    }
}

const receiveLogin = (user) => {
    return {
        type: LOGIN_SUCCESS,
        user,
    }
}

const loginError = (message) => {
    return {
        type: LOGIN_FAILURE,
        message,
    }
}

const requestLogout = () => {
    return {
        type: LOGOUT_REQUEST,
    }
}

const receiveLogout = () => {
    return {
        type: LOGOUT_SUCCESS,
    }
}

const logoutError = () => {
    return {
        type: LOGOUT_FAILURE,
    }
}

const verifyRequest = () => {
    return {
        type: VERIFY_REQUEST,
    }
}

const verifySuccess = () => {
    return {
        type: VERIFY_SUCCESS,
    }
}

const startTrackingUser = () => {
    return {
        type: START_TRACKING,
    }
}

const stopTrackingUser = () => {
    return {
        type: STOP_TRACKING,
    }
}

export const createUserWithEmailPassword = (name, email, password) => async (dispatch) => {
    try {
        const credential = await firebase.auth().createUserWithEmailAndPassword(email, password)
        // console.log('got credential', credential)
        // const userInfo = await firebase.auth().signInWithCredential(credential)
        // console.log('got userInfo', userInfo)
        const user = credential.user.toJSON()
        user["displayName"] = name

        const userToSend = {
            account_info: {
                type: "free",
                has_visited: false
            },
            info: user,
        }
        await database.ref(`/users/${user.uid}/`).set(userToSend)
        dispatch(receiveLogin(userToSend))
    } catch (error) {
        switch (error.code) {
            case "auth/email-already-in-use":
                alert("An account has already been created under this email!")
                break
            case "auth/invalid-email":
                alert("Invalid email address!")
                break
            case "auth/operation-not-allowed":
                alert('"Operation not allowed". Please email us at team@gatherapp.live with this error code!')
                break
            case "auth/weak-password":
                alert("This password is too weak")
                break
            default:
                console.log("default case")
        }
    }
}

export const loginWithEmailPassword = (email, password) => async (dispatch) => {
    try {
        const credential = await firebase.auth().signInWithEmailAndPassword(email, password)
        const userInfo = await firebase.auth().signInWithCredential(credential)
        const user = userInfo.user.toJSON()

        const userSnap = await database.ref(`/users/${user.uid}`).once("value")
        if (userSnap.exists()) {
            dispatch(receiveLogin(userSnap.val()))
        } else {
            const userToSend = {
                account_info: {
                    type: "free",
                    has_visited: false
                },
                info: user,
            }
            await database.ref(`/users/${user.uid}/`).set(userToSend)
            dispatch(receiveLogin(userToSend))
        }
    } catch (error) {
        switch (error.code) {
            case "auth/user-disabled":
                alert("This account has been disabled! Please check with your admin for more details")
                break
            case "auth/invalid-email":
                alert("Invalid email address!")
                break
            case "auth/user-not-found":
                alert("No user has been found with this email!")
                break
            case "auth/wrong-password":
                alert("Incorrect password!")
                break
            case "auth/too-many-requests":
                alert("Too many attempts! Please try again later")
                break
            default:
                console.log("default case")
                pino.error(error.code)
        }
    }
}

export const loginWithMicrosoft = () => async (dispatch) => {
    var provider = new firebase.auth.OAuthProvider("microsoft.com")
    provider.setCustomParameters({
        // Force re-consent.
        prompt: "consent",
    })
    provider.setCustomParameters({
        // tenant: 'a7c3f207-5739-4c59-b2dd-1b5ec900c79a'
    })

    firebase
        .auth()
        .signInWithPopup(provider)
        .then(async function (result) {
            // User is signed in.
            // IdP data available in result.additionalUserInfo.profile.
            // OAuth access token can also be retrieved:
            // result.credential.accessToken
            // OAuth ID token can also be retrieved:
            // result.credential.idToken

            const user = result.user.toJSON()

            const userSnap = await database.ref(`/users/${user.uid}`).once("value")
            if (userSnap.exists()) {
                dispatch(receiveLogin(userSnap.val()))
            } else {
                const userToSend = {
                    account_info: {
                        type: "free",
                        has_visited: false
                    },
                    info: user,
                }
                await database.ref(`/users/${user.uid}/`).set(userToSend)
                dispatch(receiveLogin(userToSend))
            }
        })
        .catch(function (error) {
            // Handle error.
            pino.error(error)
            alert(error.message)
        })
}

export const loginWithFacebook = (accessToken) => async (dispatch) => {
    //  accessToken come in from react-facebook-login component
    // this only runs if client-end creation was succesful
    dispatch(requestLogin())
    try {
        // get firebase credential from tokens
        const credential = firebase.auth.FacebookAuthProvider.credential(accessToken)

        // sign in and get user information
        const userInfo = await firebase.auth().signInWithCredential(credential)
        const user = userInfo.user.toJSON()

        const userSnap = await database.ref(`/users/${user.uid}`).once("value")
        if (userSnap.exists()) {
            dispatch(receiveLogin(userSnap.val()))
        } else {
            await database.ref(`/users/${user.uid}/`).set({
                account_info: {
                    type: "free",
                    has_visited: false
                },
                info: user,
            })
            var userToSend = {
                account_info: {
                    type: "free",
                },
                info: user,
            }
            dispatch(receiveLogin(userToSend))
        }
    } catch (error) {
        dispatch(loginError(error.message))
    }
}

export const loginWithGoogle = (idToken, accessToken) => async (dispatch) => {
    //  accessToken come in from react-facebook-login component
    // this only runs if client-end creation was succesful
    dispatch(requestLogin())
    try {
        // get firebase credential from tokens
        const credential = firebase.auth.GoogleAuthProvider.credential(idToken, accessToken)

        // sign in and get user information
        const userInfo = await firebase.auth().signInWithCredential(credential)
        const user = userInfo.user.toJSON()

        const userSnap = await database.ref(`/users/${user.uid}`).once("value")
        if (userSnap.exists()) {
            console.log("user exists, dispatching")
            dispatch(receiveLogin(userSnap.val()))
        } else {
            const userToSend = {
                account_info: {
                    type: "free",
                    has_visited: false
                },
                info: user,
            }
            console.log("creating user", userToSend)
            await database.ref(`/users/${user.uid}/`).set(userToSend)
            dispatch(receiveLogin(userToSend))
        }
    } catch (error) {
        dispatch(loginError(error.message))
    }
}

export const logoutUser = () => async (dispatch) => {
    dispatch(requestLogout())
    try {
        await firebase.auth().signOut()
        dispatch(receiveLogout())
    } catch (error) {
        dispatch(logoutError())
    }
}

export const receiveError = (error, details) => async (dispatch) => {
    dispatch({ type: "LOGIN_FAILURE", payload: { error, details } })
}

export const verifyAuth = () => (dispatch) => {
    dispatch(verifyRequest())

    firebase.auth().onAuthStateChanged(async (user) => {
        if (user !== null) {
            const userSnap = await database.ref(`/users/${user.uid}`).once("value")
            dispatch(receiveLogin(userSnap.val()))
        }
        dispatch(verifySuccess())
    })
}

export const startTrackingSession = (id) => (dispatch) => {
    dispatch(startTrackingUser())

    ReactGA.initialize("UA-168851028-1", {
        debug: false,
        titleCase: false,
        gaOptions: {
            userId: id,
        },
    })
}

export const stopTrackingSession = () => (dispatch) => {
    dispatch(stopTrackingUser())
}

export const fetchUser = (id) => (dispatch) => {
    database.ref(`/users/${id}`).on("value", (snap) => {
        const user = snap.val()
        dispatch(receiveLogin(user))
    })
}

export const detachListener = (id) => (dispatch) => {
    database.ref(`/users/${id}`).off("value")
}
