import React, { Component } from "react"
import ReactGA from "react-ga"
import { connect } from "react-redux"
import * as moment from "moment"
import { withRouter } from "react-router-dom"
import OT from "@opentok/client"
import { ResonanceAudio } from "resonance-audio"

import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeadphonesAlt, faMicrophone, faVideo } from "@fortawesome/free-solid-svg-icons"

import "../assets/css/LandingPage.css"
import "../assets/css/RoomPage.css"

import UserModel from "../models/UserModel"
import firebase from "../firebase"
import Toolbar from "./Toolbar"
import Analytics from "./Analytics"
import CreateALounge from "./CreateALounge"
import Chat from "./Chat"

import pino from "./pino"
import useCheckBrowser from './hooks/useCheckBrowser'
import useGetOs from './hooks/useGetOs'


import { logoutUser, listenToRoom, detachRoomListener, detachListener } from "../redux/actions"

var database = firebase.database()
var isEqual = require("lodash.isequal")

let activity = {}
let panningInfo = {}


class EphemeralRoomPage extends Component {
    constructor(props) {
        super(props)

        const initialGrid = this.generateGrid()

        this.state = {
            roomCode: "",
            currentRoom: null,
            user: {},
            error: null,
            errorDetails: null,

            forcedViewPage: "",
            chat: false,

            roomUrl: "",
            startingCall: false,
            endedCall: false,
            currentAudioDevice: "",
            currentVideoDevice: "",
            audioDevices: [],
            videoDevices: [],
            newFacultyInfo: {
                name: "",
            },
            isHost: true,
            activityText: "",

            subscribers: [],
            session: null,
            isOutOfTime: false,

            // toolbar buttons
            isShowingAnalytics: false,
            isShowingSettings: false,

            notifications: {
                hasUnreadMessages: false,
            },

            grid: initialGrid,
            availableGrid: initialGrid,
            volumeGrid: initialGrid,
            layout: {},
            oldLayout: {},
            initialIndex: null,
            isButtonDisabled: true,
            userIndexOn2DGrid: 0,
            toUnsub: [],
            toSub: [],
            globalXY: {},

            helperCode: "",
            isShowingHelperCode: false,
            showPerimssionsModal: false,

            // opentok
            streams: [],
            audio: true,
            video: true,
            inPersonUsers: [],
            remoteUsers: [],
            currentUserState: "", // REMOTE, IN_PERSON
            publisherId: '',
            screenSharePublisher: null,
            screenShareSubscriber: null,
            screenSharing: false,

            isSpatialCall: false,

            lastUpdatedMic: 0,

            // audio calibration process
            calibrationState: 'HEAD_CHECK', // HEAD_CHECK, BASE_SOUND_CHECK, CALIB_CHECK, DONE
            isCalibratingResonance: false,
            devicesIndex: {
                previous: -1,
                current: 0,
            },
            deviceId: '',
            no: false,
        }

        this.screenShareRef = React.createRef()
        this.setupResonanceAudio()
    }

    async componentDidMount() {
        window.addEventListener("beforeunload", this.onBeforeUnload.bind(this))

        const { match } = this.props
        const { params: { roomCode } } = match
        this.setState({ roomCode })

        await this.handleRoomRedirect(roomCode)
        const index = this.getNextAvailableGridIndex()
        this.setGridIndex(true, index)
    }

    async componentWillUnmount() {

        document.addEventListener("keydown", this.handleKeyPress, false)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var shouldUpdateUser = !isEqual(nextProps.user, prevState.user)
        var shouldUpdateRoom = !isEqual(nextProps.currentRoom, prevState.currentRoom)
        var shouldUpdateNotification = !isEqual(nextProps.notifications, prevState.notifications)

        if (shouldUpdateUser) {
            return { user: nextProps.user }
        } else if (shouldUpdateNotification) {
            return { notifications: nextProps.notifications }
        } else if (shouldUpdateRoom) {
            return { currentRoom: nextProps.currentRoom }
        } else {
            return null
        }
    }

    async componentDidUpdate(_prevProps, prevState) {
        if (!isEqual(this.props.user, prevState.user)) {
            if (!prevState.user && !this.state.spaceCode) {
                await this.handleRoomRedirect(this.state.roomCode)
                await this.getAllUserLounges()
            }
            this.setState({ user: this.props.user })
        }

        if (!isEqual(this.props.currentRoom, prevState.currentRoom)) {
            const room = this.props.currentRoom
            // only update room if it has been fetch from the DB
            // otherwise layout dict doesn't update properly and can cause
            // the dreaded translucent screen bug
            if (room.id.length > 0) this.updateStateWithRoom(prevState.currentRoom, room)
        }

        if (!isEqual(this.props.notifications, prevState.notifications)) {
            this.setState({ notifications: this.props.notifications })
        }

        if (!isEqual(prevState.screenShareSubscriber, this.state.screenShareSubscriber)) {
            if (this.state.screenShareSubscriber) {
                this.screenShareRef.current.classList.remove("d-none")
            } else {
                // this.removeScreenShareChildren()
                this.screenShareRef.current.classList.add("d-none")
            }
        }

        if (!isEqual(prevState.screenSharePublisher, this.state.screenSharePublisher)) {
            if (this.state.screenSharePublisher) {
                const pubView = this.renderPublisherView()

                this.screenShareRef.current.appendChild(pubView)
                this.screenShareRef.current.classList.remove("d-none")
            } else {
                this.removeScreenShareChildren()
                this.screenShareRef.current.classList.add("d-none")
            }
        }

        // calibration
        if (!isEqual(prevState.devicesIndex, this.state.devicesIndex)) {
            this.cleanUpResonanceAudio()
            this.cleanUpCalibrationPublisher()

            this.startCalibrationPublisher()
        }
    }

    onBeforeUnload(e) {
        this.quickCleanUpCallSession()
    }

    setupResonanceAudio() {

        this.audioContext = new AudioContext()
        this.resonanceAudioScene = new ResonanceAudio(this.audioContext)
        this.resonanceAudioScene.output.connect(this.audioContext.destination)
        let roomMaterials = {
            left: 'wood-panel',
            right: 'wood-panel',
            up: 'wood-ceiling',
            down: 'wood-panel',
            front: 'wood-panel',
            back: 'wood-panel'
        }

        let roomDimensions = {
            width: 8,
            height: 5,
            depth: 0,
        }

        this.resonanceAudioScene.setRoomProperties(roomDimensions, roomMaterials)

    }

    playResonanceTestAudio() {

        this.audioEl = document.createElement('audio')
        this.audioEl.src = require('../assets/calibration.mp3')
        this.audioEl.loop = true

        this.audioElSource = this.audioContext.createMediaElementSource(this.audioEl)
        let source = this.resonanceAudioScene.createSource()
        this.audioElSource.connect(source.input)

        source.setPosition(-5, 0, 0)

        this.audioEl.play()
        // audioEl2.play()

    }

    cleanUpResonanceAudio() {
        this.audioEl.pause()
        this.audioEl.currentTime = 0
        this.audioEl.remove()
        this.audioElSource.disconnect()
    }

    async handleRoomRedirect(roomCode) {
        // validate room code
        // check parent space id from room code
        // validate spaceId from user companies
        // success

        // use user data (stored companies) and space code to handle url accordingly
        const { user } = this.props

        if (!user) return

        const account_info = user.account_info


        // if space code doesn't exist
        if (!roomCode) {
            // send to default space
            if (account_info.id) {
                this.leaveRoom()
                return
            }
        }

        const roomSnap = await database.ref(`/rooms/${roomCode}`).once("value")
        // get first matching code from database request

        if (!roomSnap.exists()) {
            this.setState({ error: "ROOM_DOES_NOT_EXIST" })
            return
        }

        await this.getRoomInformation(roomCode)

    }

    async getRoomInformation(roomCode) {
        this.startListenerForRoom(roomCode)
        const room = await this.getRoom(roomCode)

        const who = room.who ? Object.keys(room.who) : []
        if (who.length === 8) {
            if (this.state.space && this.state.space.codes) {
                const spaceId = this.state.space.codes.id
                this.pushURL(`/space/${spaceId}`)
            } else {
                this.pushURL("/space")
            }
            return
        }

        this.addUserToRoom()
        this.setRoomHost(room)
        if (!room.who && room.callSession) {
            this.removeEntireSession()
        }
    }

    async getRoom(roomId) {
        return new Promise(async (resolve, reject) => {
            if (roomId.length > 0) {
                const roomSnap = await database.ref(`/rooms/${roomId}`).once("value")

                if (roomSnap.exists()) {
                    const room = roomSnap.val()
                    const roomUrl = `/rooms/${room.id}`

                    this.setState({ roomUrl })
                    resolve(room)
                } else {
                    reject()
                }
            }
        })
    }

    leaveRoom() {
        const { history } = this.props
        history.push(`/space`)
    }

    checkIfDeathIsHere() {
        const { space } = this.state

        const spaceSubscriptionEnd = space.subscription.period
        const currentTime = moment().unix()
        if (spaceSubscriptionEnd >= currentTime) {
            this.setState({ isOutOfTime: true })
        }
    }

    isValidType(obj) {
        return typeof obj !== "undefined"
    }

    isValidActivityNode(currentActivity) {
        if (!this.isValidType(currentActivity)) return false

        return (
            this.isValidType(currentActivity) &&
            this.isValidType(currentActivity.activityDetails) &&
            this.isValidType(currentActivity.didEndGathering) &&
            this.isValidType(currentActivity.didStartGathering) &&
            this.isValidType(currentActivity.endDate) &&
            this.isValidType(currentActivity.info) &&
            this.isValidType(currentActivity.queueInfo) &&
            this.isValidType(currentActivity.startDate)
        )
    }

    updateStateWithRoom(prevRoom, room) {
        let layout = {}
        if (room.callSession.layout) {
            layout = room.callSession.layout
        }

        const oldLayout = prevRoom ? (prevRoom.callSession.layout ? prevRoom.callSession.layout : {}) : {}
        const uid = this.props.user.info.uid

        const who = room.who ? Object.values(room.who) : []

        const inPersonUsers = []
        const remoteUsers = []
        let currentUserState = ""

        who.map((u) => {
            const type = u.type

            if (u.uid === uid) currentUserState = u.type

            if (type === "REMOTE") remoteUsers.push(u.uid)
            else inPersonUsers.push(u.uid)
        })

        const availableGrid = this.getAvailableGrid(layout)
        const currentRoom = room

        let userIndexOn2DGrid = this.state.userIndexOn2DGrid

        // update user index on 2d grid only if current user changes layout index
        if (oldLayout[uid] !== layout[uid]) {
            let new2DIndex = this.updatePanIndexes(layout)
            userIndexOn2DGrid = new2DIndex
        }

        let globalXY = {}
        if (!isEqual(oldLayout, layout)) {
            globalXY = this.getXYCoords(layout)
        }

        const isRoomHost = currentRoom ? currentRoom.host === uid : false

        // number of cells in the grid (40) subtracted by the current limit of people (15)
        // 40 - 15 = 25 available indexes
        const isRoomFull = availableGrid.length === 25

        this.setState(
            {
                currentRoom,
                inPersonUsers,
                remoteUsers,
                layout,
                currentUserState,
                availableGrid,
                userIndexOn2DGrid,
                isRoomHost,
                globalXY,
                isRoomFull
            },
            () => {
                this.updateLocalHTMLVariables()

                // run every layout change
                if (!isEqual(oldLayout, layout)) {
                    this.setVolumeLevelsForGrid(layout[uid])
                }

                this.parseLayout(oldLayout)
                this.checkForVideoPlacement()
            }
        )
    }

    setChoosingView(viewType) {
        this.setState({ isCreatingRoom: true, forcedViewPage: viewType })
    }

    generateHelperCode() {
        const { roomCode, user } = this.state

        const helperCode = Math.random().toString(16).substr(2, 8).toUpperCase()
        const uid = user.info.uid

        database.ref(`/ot_codes/${helperCode}/`).set({
            userId: uid,
            roomId: roomCode,
        })

        this.setState({ helperCode })
        return helperCode
    }

    getHelperCode() {
        const { helperCode } = this.state

        if (helperCode.length === 0) {
            this.generateHelperCode()
        }

        this.toggleHelperCodeModal()
    }

    startListenerForRoom(id) {
        const { dispatch } = this.props
        dispatch(listenToRoom(id))
    }

    setRoomHost(room) {
        const user = this.props.user.info
        const { roomUrl } = this.state

        if (!room.host) {
            database.ref(`${roomUrl}/host/`).set(user.uid)
        }
    }

    addUserToRoom() {
        const user = this.props.user.info
        var email = user.email
        if (!user.email) email = user.providerData[0].email

        const userInfo = {
            uid: user.uid,
            name: user.displayName,
            email: email,
            type: "",
        }

        database.ref(`${this.state.roomUrl}/who/${user.uid}`).set(userInfo)
    }

    parseLayout(oldLayout) {
        // move child from dom to dom

        const { layout } = this.state

        for (var id in layout) {
            const oldIndex = oldLayout[id]
            const newIndex = layout[id]

            const oldIndexExists = typeof oldIndex !== "undefined"
            const hasChangedIndex = oldIndex !== newIndex

            if (oldIndexExists && hasChangedIndex) {
                var oldParent = document.getElementById(`${oldIndex}_video_content`)
                var newParent = document.getElementById(`${newIndex}_video_content`)

                while (oldParent.hasChildNodes()) {
                    newParent.appendChild(oldParent.firstChild)
                }

            }
        }
    }

    async checkForVideoPlacement() {
        const { publisherId, user, layout, streams } = this.state

        if (publisherId === '') return

        const uid = user.info.uid

        const publisherCorrectIndex = layout[uid]

        const publisherVideoElementParent = document.getElementById(publisherId).parentNode.id

        const publisherCurrentIndex = Number(publisherVideoElementParent.replace('_video_content', ''))

        if (publisherCurrentIndex !== publisherCorrectIndex) {
            const videoElement = document.getElementById(publisherId)
            const correctParent = document.getElementById(`${publisherCorrectIndex}_video_content`)
            correctParent.appendChild(videoElement)
        }

        if (streams.length > 0) {
            for (let i = 0; i < streams.length; i++) {
                const obj = streams[i]
                const betweenId = obj.userId
                const streamId = obj.streamManager.id
                const correctIndex = layout[betweenId]
                const videoElementParent = document.getElementById(streamId).parentNode.id
                const currentIndex = Number(videoElementParent.replace('_video_content', ''))

                if (currentIndex !== correctIndex) {
                    const videoElement = document.getElementById(streamId)
                    const correctParent = document.getElementById(`${correctIndex}_video_content`)
                    correctParent.appendChild(videoElement)
                }

            }
        }




    }

    updateLocalHTMLVariables() {
        const height = window.innerHeight - 70

        document.documentElement.style.setProperty(`--viewHeight`, `${height}px`)
        document.documentElement.style.setProperty(
            `--gridHeight`,
            "calc(var(--viewHeight) / var(--rowNum) - 2.5rem * (var(--rowNum) - 1) / var(--rowNum))"
        )
    }

    findObjInMultiArray(arr, k) {
        for (let i = 0; i < arr.length; i++) {
            var index = arr[i].indexOf(k)
            if (index > -1) return index
        }
    }

    findXYInMultiArray(arr, k) {
        for (let i = 0; i < arr.length; i++) {
            var index = arr[i].indexOf(k)
            if (index > -1) return [index, i]
        }
    }

    getXYCoords(layout) {

        const { user } = this.state

        const gridRows = [
            [0, 1, 2, 3, 4, 5, 6, 7],
            [8, 9, 10, 11, 12, 13, 14, 15],
            [16, 17, 18, 19, 20, 21, 22, 23],
            [24, 25, 26, 27, 28, 29, 30, 31],
            [32, 33, 34, 35, 36, 37, 38, 39],
        ]

        let currentUserXY = this.findXYInMultiArray(gridRows, layout[user.info.uid])

        if (!currentUserXY) return null

        let panningIndexes = {}

        for (var uid in layout) {
            if (uid !== user.info.uid) {

                let userXY = this.findXYInMultiArray(gridRows, layout[uid])

                panningIndexes[uid] = {
                    x: userXY[0] - currentUserXY[0],
                    y: currentUserXY[1] - userXY[1],
                }
            }
        }

        return panningIndexes

    }

    updatePanIndexes(layout) {
        const { user } = this.state

        const currentUserLayoutIndex = layout[user.info.uid]

        const gridRows = [
            [0, 1, 2, 3, 4, 5, 6, 7],
            [8, 9, 10, 11, 12, 13, 14, 15],
            [16, 17, 18, 19, 20, 21, 22, 23],
            [24, 25, 26, 27, 28, 29, 30, 31],
            [32, 33, 34, 35, 36, 37, 38, 39],
        ]

        var userIndex = this.findObjInMultiArray(gridRows, currentUserLayoutIndex)

        return userIndex
    }

    logUserOut() {
        let loggingOut = window.confirm("Are you sure you want to log out?")

        if (!loggingOut) {
            return
        } else {
            const { dispatch } = this.props

            this.leave()

            dispatch(detachListener(this.state.user.info.uid))
            dispatch(logoutUser())
        }
    }

    quickCleanUpCallSession() {
        const { roomUrl, user } = this.state
        const userId = user.info.uid

        database.ref(`${roomUrl}/who/${userId}`).remove()
        database.ref(`${roomUrl}/callSession/layout/${userId}`).remove()

    }

    leave() {
        this.leaveSession()
        this.quickCleanUpCallSession()
    }

    removeUserFromRoom(userId, roomId) {
        return new Promise(async (resolve, reject) => {
            const data = { userId, roomId }
            try {
                await fetch("https://us-central1-between-87295.cloudfunctions.net/api/removeUserFromRoom", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                resolve(true)
            } catch (error) {
                console.log(error)
            }
        })
    }

    /* Call Helpers */

    getSavedMicId() {
        let savedMicId = ''

        try {
            savedMicId = window.localStorage.getItem('micId')
        } catch (error) {
            console.log('error accessing saved mic id')
        }
        return savedMicId
    }

    async setMicId(micId) {
        window.localStorage.setItem('micId', micId)
    }

    async resetMicId() {
        window.localStorage.removeItem('micId')
    }

    async checkAudioVideoPermissions() {

        return new Promise(async (resolve, reject) => {

            let hasDevices = false
            try {
                let localStream = await OT.getUserMedia()
                localStream.getTracks().forEach((track) => {
                    track.stop();
                });
                hasDevices = true
                resolve(hasDevices)
            } catch (error) {
                console.log(error.message)
                reject(error.message)
            }
        })

    }

    temp_setTypeAndStartCall(type) {
        const { currentRoom, user } = this.state

        const userId = user.info.uid

        database.ref(`/rooms/${currentRoom.id}/who/${userId}/type`).set(type)
        this.startTheCall(type)
    }

    async chooseTypeAndConfirmPermissions(type) {

        // temp type for calibrating process
        this.type = type

        const savedMicId = this.getSavedMicId()

        if (typeof savedMicId !== "string") {
            // has not gone through process yet and we don't have permission
            // show permissions toggle which will call `startFirstTimeCalibrationProcess`
            this.togglePermissionsModal()
        } else {
            // has already gone through process
            // check list of current available devices and see if any audio sources match the savedMicId

            let hasDevices = false
            try {
                hasDevices = await this.checkAudioVideoPermissions()
            } catch (error) {
                console.log(error)
                alert(error.message)
            }

            // continue checking mic id and setup from here
            if (hasDevices) {
                // gave us permissions

                if (savedMicId.length === 0) {
                    // went through setup process but did not have headphones or failed resonance calibration
                    // start basic call without resonance
                    this.startBaseVolumetricCall()
                    return
                }

                // otherwise start 
                let devices = []
                try {
                    devices = await this.getDevices()
                } catch (error) {
                    console.log(error)
                }

                // get array of device ids from device list
                // check if savedMicId is included
                const deviceIds = devices.map(({ deviceId }) => deviceId)
                const isValid = deviceIds.includes(savedMicId)

                if (isValid) {
                    this.setState({ isSpatialCall: true, isCalibratingResonance: false, deviceId: savedMicId }, () => {
                        this.setTypeAndStartCall()
                    })
                } else {
                    // went through resonance process but browser sessions changed so deviceIds changed
                    // go through resonance calibration again
                    console.log('went through resonance process but browser sessions changed so deviceIds changed')
                    this.showHeadphonesCheck()
                }

            } else {
                // has gone through process but permissions were reset
                // show permissions toggle which will call `startFirstTimeCalibrationProcess`
                console.log('has gone through process but we were just reject permissions, ')
                this.resetMicId()
                this.togglePermissionsModal()
            }

        }


    }

    async startFirstTimeCalibrationProcess(hasPermission) {

        // close permissions toggle
        this.togglePermissionsModal()

        // request permission popup
        let hasDevices = false
        try {
            hasDevices = this.checkAudioVideoPermissions()
        } catch (error) {
            console.log(error)
            alert('Error , please check your device permissions and try again.')
            this.resetCalibration()
        }

        if (!hasDevices) {
            // did not give us permission
            alert('Error , please check your device permissions and try again.')
            this.resetCalibration()
        }

        // check store and cross reference with current devices
        this.showHeadphonesCheck()

    }

    setTypeAndStartCall() {
        const { currentRoom, user } = this.state

        const userId = user.info.uid

        database.ref(`/rooms/${currentRoom.id}/who/${userId}/type`).set(this.type)
        this.startTheCall(this.type)

    }

    setPanningForIndex(userId) {

        const { globalXY } = this.state

        if (!globalXY[userId]) return

        const { x, y } = globalXY[userId]

        if (panningInfo[userId]) {
            panningInfo[userId].setPosition(x, y, 0)
        }

    }

    adjustVolumeForGrid() {
        const { streams, layout, volumeGrid, toSub, toUnsub, remoteUsers, isSpatialCall } = this.state

        for (let i = 0; i < streams.length; i++) {
            const sub = streams[i]
            const userId = sub.getUserId()

            const layoutIndex = layout[userId]

            let volumeLevel = 1

            if (toSub.includes(userId)) {
                // get default volume level for the user based on the hierarchy
                // 1.0 / 0.6 / 0.05
                volumeLevel = volumeGrid[layoutIndex] * 100
            } else if (toUnsub.includes(userId)) {
                volumeLevel = 0
            }

            // set volume here
            if (!isSpatialCall) {
                sub.getStreamManager().setAudioVolume(volumeLevel)
            } else {
                if (remoteUsers.includes(userId) && toSub.includes(userId)) {
                    // set panning values here
                    this.setPanningForIndex(userId)
                }
            }
        }
    }

    setRemoteLevels(userLayoutIndex) {

        const { userIndexOn2DGrid, layout, inPersonUsers, user, currentUserState } = this.state

        // if user is in person, stop function
        if (currentUserState === 'IN_PERSON') {
            return
        }

        // remote people set volume of inperson stream based on layout
        // first check: remote check to see if inPersonUsers[0] is in primary or secondary
        // second check: if not in primary or secondary, then check for other inPersonUsers in primary or secondary
        // third check: if multiple inPersonUsers in primary or secondary, choose [0] from that smaller array and mute from inPersonUsers[0],
        let toUnsub = []
        let toSub = []
        let inPersonToSub = []

        const tre = [6, 7, 14, 15] // top right edge
        const ble = [24, 25, 32, 33] // bottom left edge

        var type = ""
        if (tre.includes(userLayoutIndex)) type = "TRE"
        else if (ble.includes(userLayoutIndex)) type = "BLE"

        for (var uid in layout) {
            if (uid !== user.info.uid) {
                if (inPersonUsers.includes(uid)) {
                    const layoutIndex = layout[uid]

                    const isPrimary = this.isPrimaryVolume(userLayoutIndex, layoutIndex, type, userIndexOn2DGrid)
                    const isSecondary = this.isSecondaryVolume(userLayoutIndex, layoutIndex, type, userIndexOn2DGrid)

                    if (inPersonUsers[0] === uid && (isPrimary || isSecondary)) {
                        // check to see if inPersonUsers[0] is in primary or secondary, add to inPersonToSub
                        inPersonToSub.push(uid)
                    } else if (inPersonUsers[0] === uid && (!isPrimary || !isSecondary)) {
                        // check to see if inPersonUsers[0] is not in primary or secondary, add to toUnsub
                        toUnsub.push(uid)
                    } else if (isPrimary || isSecondary) {
                        // check if is primary or secondary, add to inPersonToSub
                        inPersonToSub.push(uid)
                    } else if (!isPrimary || !isSecondary) {
                        // check if not primary or secondary, add to toUnsub
                        toUnsub.push(uid)
                    }
                } else {
                    // if user is remote, add to toSub
                    toSub.push(uid)
                }
            }
        }

        const deleted = inPersonToSub.splice(1, inPersonToSub.length) // only be subcribed to one inperson at a time
        toUnsub = toUnsub.concat(deleted)

        toSub = toSub.concat(inPersonToSub) // add the single in-person mic to all other subscribed streams

        this.setState({ toUnsub, toSub }, () => {
            this.adjustVolumeForGrid()
        })

    }

    setInPersonLevels() {

        const { currentUserState, inPersonUsers, user, remoteUsers } = this.state

        // if user is remote, stop function
        if (currentUserState === 'REMOTE') {
            return
        }

        // if current user is in person, don't subscribe to their audio
        // leave toSub empty so that `adjustVolumeForGrid` dynamically adjusts volume
        let toUnsub = inPersonUsers.filter((u) => u !== user.info.uid)
        let toSub = [].concat(remoteUsers)

        this.setState({ toUnsub, toSub }, () => {
            this.adjustVolumeForGrid()
        })

    }

    setVolumeLevelsForGrid(userLayoutIndex) {
        const { userIndexOn2DGrid } = this.state
        const grid = this.generateGrid()
        const tre = [6, 7, 14, 15] // top right edge
        const ble = [24, 25, 32, 33] // bottom left edge

        var type = ""
        if (tre.includes(userLayoutIndex)) type = "TRE"
        else if (ble.includes(userLayoutIndex)) type = "BLE"

        for (let i = 0; i < grid.length; i++) {
            if (this.isPrimaryVolume(userLayoutIndex, i, type, userIndexOn2DGrid)) {
                grid[i] = 1.0
            } else if (this.isSecondaryVolume(userLayoutIndex, i, type, userIndexOn2DGrid)) {
                grid[i] = 0.6
            } else {
                grid[i] = 0.05
            }
        }

        this.setState({ volumeGrid: grid }, () => {
            this.setInPersonLevels()
            this.setRemoteLevels(userLayoutIndex)
        })
    }

    isPrimaryVolume(mainIndex, subIndex, type, userIndexOn2DGrid) {
        const n = mainIndex

        if (userIndexOn2DGrid + 1 > 7) {
            return (
                subIndex === n ||
                subIndex === n - 1 ||
                subIndex === n - 1 - 8 ||
                subIndex === n - 1 + 8 ||
                subIndex === n + 8 ||
                subIndex === n - 8
            )
        } else if (userIndexOn2DGrid - 1 < 0) {
            return (
                subIndex === n ||
                subIndex === n + 8 ||
                subIndex === n - 8 ||
                subIndex === n + 1 ||
                subIndex === n + 1 - 8 ||
                subIndex === n + 1 + 8
            )
        }

        return (
            subIndex === n ||
            subIndex === n - 1 ||
            subIndex === n - 1 - 8 ||
            subIndex === n - 1 + 8 ||
            subIndex === n + 8 ||
            subIndex === n - 8 ||
            subIndex === n + 1 ||
            subIndex === n + 1 - 8 ||
            subIndex === n + 1 + 8
        )
    }

    isSecondaryVolume(mainIndex, subIndex, type, userIndexOn2DGrid) {
        const n = mainIndex

        if (userIndexOn2DGrid + 1 > 7) {
            if (type === "TRE") {
                return (
                    subIndex === n - 2 ||
                    subIndex === n - 2 - 16 ||
                    subIndex === n - 2 - 8 ||
                    subIndex === n - 2 + 16 ||
                    subIndex === n - 2 + 8 ||
                    subIndex === n - 1 - 16 ||
                    subIndex === n - 1 + 16 ||
                    subIndex === n + 16 ||
                    subIndex === n - 16
                )
            }
            return (
                subIndex === n - 2 ||
                subIndex === n - 2 - 16 ||
                subIndex === n - 2 - 8 ||
                subIndex === n - 2 + 16 ||
                subIndex === n - 2 + 8 ||
                subIndex === n - 1 - 16 ||
                subIndex === n - 1 + 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 2 + 16
            )
        } else if (userIndexOn2DGrid + 2 > 7) {
            if (type === "TRE") {
                return (
                    subIndex === n - 2 ||
                    subIndex === n - 2 - 16 ||
                    subIndex === n - 2 - 8 ||
                    subIndex === n - 2 + 16 ||
                    subIndex === n - 2 + 8 ||
                    subIndex === n - 1 - 16 ||
                    subIndex === n - 1 + 16 ||
                    subIndex === n + 16 ||
                    subIndex === n - 16 ||
                    subIndex === n + 1 + 16
                )
            }
            return (
                subIndex === n - 2 ||
                subIndex === n - 2 - 16 ||
                subIndex === n - 2 - 8 ||
                subIndex === n - 2 + 16 ||
                subIndex === n - 2 + 8 ||
                subIndex === n - 1 - 16 ||
                subIndex === n - 1 + 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 1 - 16 ||
                subIndex === n + 2 + 16
            )
        } else if (userIndexOn2DGrid - 1 < 0) {
            if (type === "BLE") {
                return (
                    subIndex === n + 16 ||
                    subIndex === n - 16 ||
                    subIndex === n + 1 + 16 ||
                    subIndex === n + 1 - 16 ||
                    subIndex === n + 2 + 8 ||
                    subIndex === n + 2 + 16 ||
                    subIndex === n + 2 - 8 ||
                    subIndex === n + 2 - 16 ||
                    subIndex === n + 2
                )
            }
            return (
                subIndex === n - 2 - 16 ||
                subIndex === n - 1 - 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 1 - 16 ||
                subIndex === n + 2 + 8 ||
                subIndex === n + 2 + 16 ||
                subIndex === n + 2 - 8 ||
                subIndex === n + 2 - 16 ||
                subIndex === n + 2
            )
        } else if (userIndexOn2DGrid - 2 < 0) {
            if (type === "BLE") {
                return (
                    subIndex === n - 1 - 16 ||
                    subIndex === n - 1 + 16 ||
                    subIndex === n + 16 ||
                    subIndex === n - 16 ||
                    subIndex === n + 1 + 16 ||
                    subIndex === n + 1 - 16 ||
                    subIndex === n + 2 + 8 ||
                    subIndex === n + 2 + 16 ||
                    subIndex === n + 2 - 8 ||
                    subIndex === n + 2 - 16 ||
                    subIndex === n + 2
                )
            }
            return (
                subIndex === n - 2 - 16 ||
                subIndex === n - 1 - 16 ||
                subIndex === n - 1 + 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 1 - 16 ||
                subIndex === n + 2 + 8 ||
                subIndex === n + 2 + 16 ||
                subIndex === n + 2 - 8 ||
                subIndex === n + 2 - 16 ||
                subIndex === n + 2
            )
        } else {
            return (
                subIndex === n - 2 ||
                subIndex === n - 2 - 16 ||
                subIndex === n - 2 - 8 ||
                subIndex === n - 2 + 16 ||
                subIndex === n - 2 + 8 ||
                subIndex === n - 1 - 16 ||
                subIndex === n - 1 + 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 1 - 16 ||
                subIndex === n + 2 + 8 ||
                subIndex === n + 2 + 16 ||
                subIndex === n + 2 - 8 ||
                subIndex === n + 2 - 16 ||
                subIndex === n + 2
            )
        }
    }

    findIndexIn2DArray(layoutIndex, array, isLeft) {
        var userIndex = null
        var i = 0
        var j = 0

        while (!userIndex && i < array.length) {
            const row = array[i]
            j = isLeft ? row.length - 1 : 0

            if (isLeft) {
                while (j >= 0 && !userIndex) {
                    if (row[j] === layoutIndex) userIndex = j
                    j--
                }
            } else {
                while (j < row.length) {
                    if (row[j] === layoutIndex) userIndex = j
                    j++
                }
            }
            i++
        }

        return userIndex
    }

    async startTheCall(currentUserState) {
        // if (!databaseSessionExists) return
        const { user } = this.state

        try {
            this.setState({ startingCall: true }, async () => {
                await this.startOTSession(currentUserState)
            })

            ReactGA.event({
                category: "Call",
                action: `Joined/Started A Call`,
            })
        } catch (error) {
            console.log('error here', error)
            alert(error)
            this.peen('error', 'Error while starting the call', error)
            ReactGA.ga("send", "exception", {
                exDescription: JSON.stringify(error.message),
                exFatal: true,
            })
        }
    }

    async startOTSession(currentUserState) {
        const { currentRoom, user, initialIndex, isRoomHost, deviceId } = this.state
        let userId = user.info.uid
        let sessionId = currentRoom.callSession.id
        let token = ""

        if (sessionId.length > 0) {
            // session already exists, create token from sessionId
            token = await this.createOTToken(sessionId, userId)
        } else {
            sessionId = await this.createOTSession(currentRoom.id)
            token = await this.createOTToken(sessionId, userId)
        }

        this.sessionHelper = OT.initSession("47252744", sessionId)

        // subscribe to created streams
        this.subscribeToStreamCreatedOT()
        this.subscribeToStreamDestroyedOT()

        console.log(`using deviceId: ${deviceId} as mic`)

        this.publisher = OT.initPublisher(
            `${initialIndex}_video_content`,
            {
                insertMode: "append",
                width: "100%",
                height: "100%",
                audioSource: deviceId.length > 0 ? deviceId : true,
                // if we have a deviceId use it, otherwise set to true so that it can handle by itself
            }, (error) => {
                if (error && error.code === 1500) {
                    alert('Error starting the call, please check your browser permissions and try again.')
                    this.resetCalibration()
                }

                this.handleError(error)
            }
        )

        const publisherId = this.publisher.id

        this.setState({ publisherId })


        if (isRoomHost && currentUserState === "IN_PERSON") {
            // this.publisher.on("audioLevelUpdated", (event) => {
            //     this.handleAudioLevel(event.audioLevel, userId)
            // })
        }

        if (currentUserState === "REMOTE") {
            // this.startListenerForPirmaryMic()
        }

        // Connect to the session
        this.sessionHelper.connect(token, (error) => {
            // If the connection is successful, initialize a publisher and publish to the session
            if (error) {
                this.handleError(error)
            } else {
                this.sessionHelper.publish(this.publisher, this.handleError)
                document.addEventListener("keydown", this.handleKeyPress.bind(this), false)
                this.setState({ startingCall: false })
                this.setDevices()
            }
        })
    }

    handleAudioLevel(audioLevel, uid) {
        let { lastUpdatedMic } = this.state
        // incorporate layout into dictating which individual
        // remote user should be subscribed to which inperson user
        // could be all same or all different
        // for current implementation

        const BASE_AUDIO_LEVEL = 0.2 // initial audio threshold to count as talking
        const BASE_ACTIVE_TIME = 2000 // 2 second minimum active time
        const BASE_INACTIVE_TIME = 3000 // 3 second minimum active time
        const BASE_UPDATE_TIME = 5000 // 5 second minimum update time

        const now = Date.now()

        const userInfo = activity[uid]

        if (audioLevel > BASE_AUDIO_LEVEL) {
            // console.log("audioLevel > BASE_AUDIO_LEVEL")
            if (!userInfo) {
                // starting talking for the FIRST TIME IN FOREVER
                activity[uid] = {
                    uid,
                    timestamp: now,
                    audioLevel: 0,
                    movingAvg: 0,
                    active: false,
                }
            } else if (userInfo["active"]) {
                // if active and still talking update audio level
                userInfo["timestamp"] = now
                userInfo["audioLevel"] = this.getAdjustedAudioLevel(uid, audioLevel)
                // console.log(`${uid} is talking, setting timestamp and updating audio level to ${audioLevel}`)
            } else if (now - userInfo["timestamp"] > BASE_ACTIVE_TIME) {
                // detected audio activity for more than 2s
                userInfo["active"] = true
                // console.log(`${uid} is active`)
            }
        } else if (userInfo && now - userInfo["timestamp"] > BASE_INACTIVE_TIME) {
            // detected low audio activity for more than 3s
            if (userInfo["active"]) {
                userInfo["active"] = false
                userInfo["audioLevel"] = 0
                userInfo["movingAvg"] = 0
                // console.log(`${uid} is inactive`)
            }
        }

        let timeThresholdPassed = now - lastUpdatedMic > BASE_UPDATE_TIME
        let isNowActive = audioLevel > BASE_AUDIO_LEVEL && userInfo && now - userInfo["timestamp"] > BASE_ACTIVE_TIME
        let isNowInactive = userInfo && userInfo["active"] === true && now - userInfo["timestamp"] > BASE_INACTIVE_TIME

        if (lastUpdatedMic === 0 || timeThresholdPassed || isNowActive || isNowInactive) {
            // update mic every 5 seconds or when uid has gona active or inactive
            this.setActiveMic()
        }
    }

    getAdjustedAudioLevel(uid, current) {

        let avg = activity[uid]["movingAvg"]

        if (avg === null || avg <= current) {
            avg = current;
        } else {
            avg = 0.7 * avg + 0.3 * current;
        }

        // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
        var logLevel = (Math.log(avg) / Math.LN10) / 1.5 + 1;
        logLevel = Math.min(Math.max(logLevel, 0), 1);

        activity[uid]["movingAvg"] = avg

        return logLevel

    }

    setActiveMic() {
        const { roomCode } = this.state
        let lastUpdatedMic = Date.now()

        let deactivate = []
        let activate = []

        // TODO
        // untested sort implemention of audioLevel
        const userActivity = Object.values(activity)
        const sorted = userActivity.sort((a, b) => b.audioLevel - a.audioLevel)

        for (var i = 0; i < sorted.length; i++) {
            var info = sorted[i]
            const uid = info.uid

            if (!info.active) {
                deactivate.push(uid)
            } else {
                // activate depending on something
                if (activate.length === 0) {
                    // only push the first active mic (for now)
                    activate.push(uid)
                } else {
                    // push other mics to deactivate (for now)
                    deactivate.push(uid)
                }
            }
        }


        if (activate[0]) {
            database.ref(`/rooms/${roomCode}/callSession/activeGeneralMic`).set(activate[0])
        }
        this.setState({ lastUpdatedMic })
    }

    startListenerForPirmaryMic() {
        const { roomCode } = this.state

        database.ref(`/rooms/${roomCode}/callSession/activeGeneralMic`).on("value", (snap) => {
            const { inPersonUsers } = this.state
            if (snap.exists()) {
                const uidToSub = snap.val()

                // toSub: the active inperson user to subscribe to
                const toSub = [uidToSub]

                // toUnsub: the in-active inperson users to unsubscribe to
                const toUnsub = inPersonUsers.filter((p) => p !== uidToSub)

                this.setState({ toSub, toUnsub }, () => {
                    this.adjustVolumeForGrid()
                })
            }
        })
    }

    handleError(error) {
        if (error) {
            this.peen('error', 'Error in handle error function', error)
        }
    }

    subscribeToStreamCreatedOT() {
        this.sessionHelper.on("streamCreated", (event) => {
            const { layout, streams, isRoomHost, inPersonUsers, user, isSpatialCall, currentUserState } = this.state
            const client_uid = JSON.parse(event.stream.connection.data).userId

            if (event.stream.videoType === "screen") {

                const subscriber = this.sessionHelper.subscribe(
                    event.stream,
                    'screen-share-view',
                    {
                        insertMode: "append",
                        width: "100%",
                        height: "100%",
                    },
                    this.handleError
                )
                this.setState({ screenShareSubscriber: subscriber })

                return
            }

            const indexInGrid = layout[client_uid]

            let id = `${indexInGrid}_video_content`
            if (!indexInGrid) {
                const index = this.getNextAvailableGridIndex()
                id = `${index}_video_content`
            }

            const subscriber = this.sessionHelper.subscribe(
                event.stream,
                id,
                {
                    insertMode: "append",
                    width: "100%",
                    height: "100%",
                },
                (error) => {

                    if (!isSpatialCall) return

                    if (currentUserState === 'IN_PERSON' && inPersonUsers.includes(client_uid)) {
                        const subEl = subscriber.element.querySelector('video')
                        subEl.muted = true
                        subscriber.setAudioVolume(0)
                    } else {

                        // only run resonance code if this is a spatial call
                        const subEl = subscriber.element.querySelector('video')
                        const mediaStream = subEl.srcObject

                        let source = this.resonanceAudioScene.createSource()
                        panningInfo[client_uid] = source
                        const streamClone = mediaStream.clone()
                        subEl.muted = true
                        subscriber.setAudioVolume(0)

                        var audioSource = this.audioContext.createMediaStreamSource(streamClone)
                        audioSource.connect(source.input)

                    }

                }
            )


            if (isRoomHost && inPersonUsers.includes(client_uid)) {
                // change to only have listener for in-person users
                // subscriber.on("audioLevelUpdated", (event) => {
                //     this.handleAudioLevel(event.audioLevel, client_uid)
                // })
            }

            const newUser = new UserModel()
            newUser.setStreamManager(subscriber)
            newUser.setConnectionId(event.stream.connection.connectionId)
            newUser.setType("remote")
            newUser.setNickname("name_here")
            newUser.setUserId(client_uid)
            streams.push(newUser)

            this.setState({ streams }, () => {
                this.setVolumeLevelsForGrid(layout[user.info.uid])
            })
        })
    }

    subscribeToStreamDestroyedOT() {
        this.sessionHelper.on("streamDestroyed", (event) => {
            const { layout } = this.state

            if (event.stream.videoType === 'screen') {
                this.setState({ screenShareSubscriber: null })
                return
            }

            const client_uid = JSON.parse(event.stream.connection.data).userId
            const indexInGrid = layout[client_uid]

            this.deleteSubscriberOT(client_uid)

            const vidContent = document.getElementById(`${indexInGrid}_video_content`)

            while (vidContent.hasChildNodes) {
                vidContent.removeChild(vidContent.firstChild)
            }
            event.preventDefault()
        })
    }

    deleteSubscriberOT(client_uid) {
        const remoteUsers = this.state.streams
        const userStreamIndex = remoteUsers.findIndex((user) => user.getUserId() === client_uid)
        if (userStreamIndex > -1) {
            remoteUsers.splice(userStreamIndex, 1)
            this.setState({
                streams: remoteUsers,
            })
        }
    }

    handleKeyPress(event) {
        if (event.target.className === 'new-message') {
            return;
        }

        switch (event.keyCode) {
            case 38:
                this.handleMovementUp()
                break
            case 40:
                this.handleMovementDown()
                break
            case 37:
                this.handleMovementLeft()
                break
            case 39:
                this.handleMovementRight()
                break
            case 77:
                this.micStatusChanged()
                break
            case 86:
                this.camStatusChanged()
                break
            default:
                break
        }
    }

    handleMovementUp() {
        const { layout, user, availableGrid } = this.state
        const currentIndex = layout[user.info.uid]

        const te = [0, 1, 2, 3, 4, 5, 6, 7]

        if (te.includes(currentIndex)) return

        let indexes = []
        let index = currentIndex
        while (!te.includes(index)) {
            const nextIndex = index - 8 // above current index
            if (availableGrid.includes(nextIndex)) indexes.splice(0, 0, nextIndex)
            index = nextIndex
        }

        indexes.push(currentIndex)
        const newIndex = indexes.indexOf(currentIndex) - 1
        const indexToSet = indexes[newIndex]
        this.setGridIndex(true, indexToSet)
    }

    handleMovementDown() {
        const { layout, user, availableGrid } = this.state
        const currentIndex = layout[user.info.uid]

        const be = [32, 33, 34, 35, 36, 37, 38, 39]

        if (be.includes(currentIndex)) return

        let indexes = []
        let index = currentIndex
        while (!be.includes(index)) {
            const nextIndex = index + 8 // below current index
            if (availableGrid.includes(nextIndex)) indexes.push(nextIndex)
            index = nextIndex
        }

        indexes.splice(0, 0, currentIndex)
        const newIndex = indexes.indexOf(currentIndex) + 1
        const indexToSet = indexes[newIndex]
        this.setGridIndex(true, indexToSet)
    }

    handleMovementRight() {
        // add one to current layout index for user
        const { layout, user, availableGrid } = this.state

        const currentIndex = layout[user.info.uid]
        if (currentIndex === 39) return

        const newIndex = availableGrid.indexOf(currentIndex) + 1
        const indexToSet = availableGrid[newIndex]
        this.setGridIndex(true, indexToSet)
    }

    handleMovementLeft() {
        // minus one to current layout index for user
        const { layout, user, availableGrid } = this.state

        const currentIndex = layout[user.info.uid]
        if (currentIndex === 0) return

        const newIndex = availableGrid.indexOf(currentIndex) - 1
        const indexToSet = availableGrid[newIndex]
        this.setGridIndex(true, indexToSet)
    }

    leaveSession() {
        if (this.sessionHelper) this.sessionHelper.disconnect()
        this.sessionHelper = null
    }

    removeEntireSession() {
        const { roomUrl } = this.state
        database.ref(`/${roomUrl}/callSession/`).remove()
    }

    createOTSession(roomId) {
        const { user } = this.state
        return new Promise(async (resolve, reject) => {
            try {
                const requestBody = {
                    roomId: roomId,
                }

                const resp = await fetch("https://us-central1-between-87295.cloudfunctions.net/api/createOTSession", {
                    method: "POST",
                    body: JSON.stringify(requestBody),
                })

                if (resp.status === 200) {
                    // close modal and refresh spaces
                    const response = await resp.json()
                    resolve(response.sessionId)
                } else if (resp.status === 400) {
                    // ahh error, show modal with error and hope for the best
                    const response = await resp.json()
                    const errorMessage = response
                    alert("Error Creating Session: ", errorMessage)

                    this.peen('error', 'Error while creating OT session', errorMessage)

                }
            } catch (error) {
                this.peen('error', 'Error while creating OT session', error)

            }
        })
    }

    createOTToken(sessionId, userId) {
        const { user } = this.state
        return new Promise(async (resolve, reject) => {
            try {
                const requestBody = {
                    sessionId: sessionId,
                    userId: userId,
                }

                const resp = await fetch("https://us-central1-between-87295.cloudfunctions.net/api/createOTToken", {
                    method: "POST",
                    body: JSON.stringify(requestBody),
                })

                if (resp.status === 200) {
                    // close modal and refresh spaces
                    const response = await resp.json()
                    resolve(response.token)
                } else if (resp.status === 400) {
                    // ahh error, show modal with error and hope for the best
                    const response = await resp.json()
                    const errorMessage = response
                    alert("Error Creating Session: ", errorMessage)


                    this.peen('error', 'Error while creating OT token', errorMessage)

                }
            } catch (error) {

                this.peen('error', 'Error while creating OT token', error)

            }
        })
    }

    async getDevices() {
        // type: 'audio', 'video'
        return new Promise(async (resolve, reject) => {
            let devicesValid = false
            while (!devicesValid) {
                try {
                    const devices = await this.getDevicesHelper()
                    if (devices) {
                        devicesValid = true
                        resolve(devices)
                    }
                    else await this.getDevices()
                } catch (error) {
                    reject(error)
                }
            }
        })
    }

    async setDevices() {

        const { deviceId } = this.state

        let devices = []
        try {
            devices = await this.getDevices()
        } catch (error) {
            alert(error)
        }

        const videoDevices = devices.filter((d) => d.kind === "videoInput")
        const audioDevices = devices.filter((d) => d.kind === "audioInput")

        let currentAudioDevice = this.state.currentAudioDevice
        let currentVideoDevice = this.state.currentVideoDevice

        if (currentAudioDevice.length === 0) currentAudioDevice = deviceId ? audioDevices[0].deviceId : ""
        if (currentVideoDevice.length === 0) currentVideoDevice = videoDevices[0] ? videoDevices[0].deviceId : ""

        this.setState({ currentVideoDevice, currentAudioDevice, audioDevices, videoDevices })

    }

    getDevicesHelper() {
        const { user } = this.state
        return new Promise((resolve, reject) => {
            OT.getDevices(async (error, devices) => {
                if (error) {
                    reject(error)


                    this.peen('error', 'Error while getting devices', error)

                    return
                }

                if (devices.length === 0 || !devices[0].deviceId) {
                    resolve(null)
                    return
                } else {
                    resolve(devices)
                    return
                }
            })
        })
    }

    async changeAudioDevice(e) {
        const deviceId = e.target.value
        this.setState({ currentAudioDevice: deviceId })
        await this.setDevice(deviceId, "audio")
    }

    async changeVideoDevice(e) {
        const device = e.target.value
        this.setState({ currentVideoDevice: device })
        await this.setDevice(device, "video")
    }

    async setDevice(device, type) {
        if (type === "audio") {
            this.publisher.setAudioSource(device)
        } else if (type === "video") {
            this.publisher.setVideoSource(device)
        }
    }

    showHeadphonesCheck() {

        this.setState({ calibrationState: 'HEAD_CHECK', isCalibratingResonance: true })

    }

    async playInitialCenteredSound() {

        this.centeredAudio = document.createElement('audio')
        this.centeredAudio.src = require('../assets/calibration.mp3')
        this.centeredAudio.loop = true
        this.centeredAudio.play()

    }

    async startCalibrationProcess() {

        // change UI to show left/right
        this.nextCalibrationProcess()

        // stop initial centered audio
        this.centeredAudio.pause()
        this.centeredAudio.currentTime = 0
        this.centeredAudio.remove()

        // request permission popup
        let hasDevices = false
        try {
            let localStream = await OT.getUserMedia()
            localStream.getTracks().forEach((track) => {
                track.stop();
            });
            hasDevices = true
        } catch (error) {
            console.log(error)
            alert('Error Calibrating, please check your device permissions and try again.')
            this.resetCalibration()
        }

        if (!hasDevices) return

        let devices = [];
        try {
            devices = await this.getDevices()
        } catch (error) {
            alert('Error Calibrating, please check your device permissions and try again.')
        }

        let audioDevices = devices.filter((device) => device.kind === 'audioInput');

        console.log('audioDevices', audioDevices)

        this.setState({ audioDevices }, async () => {
            await this.startTestCallForCalibration()
        })

    }

    async startTestCallForCalibration() {

        let sessionId = await this.createOTSession("")

        this.calibrationSessionHelper = OT.initSession("47252744", sessionId)

        this.setState({ calibrationSessionId: sessionId }, () => {
            this.startCalibrationPublisher()
        })

    }

    cleanUpCalibrationPublisher() {

        this.calibrationPublisher.destroy()
        this.calibrationSessionHelper.disconnect()

    }

    async startCalibrationPublisher() {

        const { calibrationSessionId, audioDevices, devicesIndex, user } = this.state

        let token = await this.createOTToken(calibrationSessionId, user.info.userId)

        let deviceId = audioDevices[devicesIndex.current].deviceId

        this.calibrationPublisher = OT.initPublisher(
            {
                videoSource: null,
                insertDefaultUI: false,
                audioSource: deviceId
            },
            this.handleError
        )

        this.calibrationSessionHelper.connect(token, (error) => {
            // If the connection is successful, initialize a publisher and publish to the session
            if (error) {
                this.handleError(error)
            } else {
                this.calibrationSessionHelper.publish(this.calibrationPublisher, (error) => {
                    if (error) {
                        this.handleError(error)
                        return
                    }

                    this.playResonanceTestAudio()
                })
            }
        })

    }

    setResonanceMic(isCurrentChoiceResonated) {

        const { devicesIndex, audioDevices } = this.state

        if (isCurrentChoiceResonated) {

            let deviceId = audioDevices[devicesIndex.current].deviceId

            try {
                window.localStorage.setItem('micId', deviceId)
            } catch (error) {
                console.log('could not set micId in storage')
            }

            this.cleanUpResonanceAudio()
            this.cleanUpCalibrationPublisher()
            this.setState({ isCalibratingResonance: false, isSpatialCall: true, deviceId }, () => {
                this.setTypeAndStartCall()
            })

        } else {

            if (devicesIndex.current === audioDevices.length - 1) {
                // is the last device, disable resonance
                // go to basic volumetric control like a basic bitch

                // set to empty string so that they we know they've done calibration with no success
                window.localStorage.setItem('micId', '')

                this.cleanUpResonanceAudio()
                this.cleanUpCalibrationPublisher()
                this.startBaseVolumetricCall()
                return
            }

            // continue looping and setting index

            let newDevicesIndex = {
                previous: devicesIndex.current,
                current: devicesIndex.current + 1,
            }

            this.setState({ devicesIndex: newDevicesIndex })

        }

    }

    resetCalibration() {

        window.localStorage.removeItem('micId')
        window.location.reload()

    }

    startBaseVolumetricCall() {

        // reset parameters
        window.localStorage.setItem('micId', '')
        this.setState({ isCalibratingResonance: false, isSpatialCall: false, deviceId: '', calibrationState: "HEAD_CHECK" }, () => {
            this.setTypeAndStartCall()
        })

    }

    nextCalibrationProcess() {

        const { calibrationState } = this.state

        let newState = 'HEAD_CHECK'

        if (calibrationState === 'HEAD_CHECK') {
            newState = 'BASE_SOUND_CHECK'
            this.playInitialCenteredSound()
        }
        else if (calibrationState === 'BASE_SOUND_CHECK') newState = 'CALIB_CHECK'
        else if (calibrationState === 'CALIB_CHECK') newState = 'DONE'

        this.setState({ calibrationState: newState })

    }

    /* Misc Helpers */

    peen(pinoType, context, error) {
        const { user } = this.state

        const uid = user.info.uid
        const browser = useCheckBrowser()
        const os = useGetOs()

        const log = {
            browser,
            os,
            error,
            uid,
            context
        }

        if (pinoType === 'error') {
            pino.error(log)
        }

    }

    pushURL(url) {
        const { history } = this.props
        history.push(url)
    }

    toggleChat() {
        this.setState({ chat: !this.state.chat })

    }

    toggleSettingsModal() {
        this.setState({ isShowingSettings: !this.state.isShowingSettings })
    }

    toggleAnalyticsView() {
        this.setState({ isShowingAnalytics: !this.state.isShowingAnalytics })
    }

    toggleHelperCodeModal() {
        this.setState({ isShowingHelperCode: !this.state.isShowingHelperCode })
    }

    togglePermissionsModal() {
        this.setState({ showPerimssionsModal: !this.state.showPerimssionsModal })
    }

    camStatusChanged() {
        this.publisher.publishVideo(!this.state.video)
        this.setState({ video: !this.state.video })
    }

    micStatusChanged() {
        this.publisher.publishAudio(!this.state.audio)
        this.setState({ audio: !this.state.audio })
    }

    toggleScreenShare() {
        const { screenSharePublisher, screenShareSubscriber } = this.state

        if (screenSharePublisher) {
            this.stopScreenShare()
        } else if (!screenSharePublisher && !screenShareSubscriber) {
            this.startScreenShare()
        }

    }

    startScreenShare() {
        OT.checkScreenSharingCapability((response) => {
            if (!response.supported) {
                // not supported
                alert("Screen Sharing not supprted")
            } else if (response.supported) {

                this.setState({ screenSharing: true }, () => {
                    let screenPublisher = OT.initPublisher(
                        `screen-share-view`,
                        {
                            videoSource: "screen",
                            videoContentHint: "detail",
                            insertDefaultUI: false,
                            audioSource: null,
                        })

                    this.setState({ screenSharePublisher: screenPublisher })

                    this.sessionHelper.publish(screenPublisher, this.handleError)
                })

            }
        })
    }

    stopScreenShare() {
        const { screenSharePublisher } = this.state
        screenSharePublisher.destroy()
        this.setState({ screenSharing: false, screenSharePublisher: null })
    }

    goHome() {
        const { currentRoom } = this.state
        const { dispatch } = this.props


        dispatch(detachRoomListener(currentRoom.id))
        this.leave()
        this.leaveRoom()
    }

    generateGrid() {
        let grid = []

        for (let i = 0; i < 40; i++) {
            grid.push(null)
        }

        return grid
    }

    async setGridIndex(isInitialIndex, index) {
        const { layout, currentRoom, user } = this.state

        const allIndexes = Object.values(layout)
        const isIndexTaken = allIndexes.includes(index)
        let ableToMove = null
        if (isIndexTaken) {
            ableToMove = false
        }
        else {
            database.ref(`/rooms/${currentRoom.id}/callSession/layout/${user.info.uid}`).set(index)
            if (isInitialIndex) {
                this.setState({ initialIndex: index })
                ableToMove = true
            }
        }

        return ableToMove
    }

    getNextAvailableGridIndex() {
        const { availableGrid } = this.state
        const randomIndex = Math.floor(Math.random() * (availableGrid.length - 1))
        return randomIndex
    }

    getAvailableGrid(layout) {
        const { grid, user } = this.state

        let indexesTaken = Object.values(layout)

        // don't include layout index of current user
        const userId = user.info.uid
        const currentUserLayoutIndex = layout[userId]
        if (currentUserLayoutIndex) {
            const indexOfLayoutInLayout = indexesTaken.indexOf(currentUserLayoutIndex)
            indexesTaken.splice(indexOfLayoutInLayout, 1)
        }

        const availableGrid = []
        grid.forEach((item, index) => {
            if (!indexesTaken.includes(index)) availableGrid.push(index)
        })

        return availableGrid
    }

    changeUserState() {
        const { currentUserState, currentRoom, user } = this.state
        const userId = user.info.uid

        if (currentUserState === "REMOTE") {
            // change to in-person
            database.ref(`/rooms/${currentRoom.id}/who/${userId}/type`).set("IN_PERSON")
        } else {
            // change to remote
            database.ref(`/rooms/${currentRoom.id}/who/${userId}/type`).set("REMOTE")
        }
    }

    // UI Helpers
    renderCell(isInActiveSession, index, type, item, who, layout) {
        let view = null

        const ids = Object.keys(who)
        let doesHaveVideo = false
        let id = ''
        let name = ''
        let meetingTypeRaw = ''
        let meetingType = ''

        for (let i = 0; i < ids.length; i++) {
            if (layout[ids[i]] === index) {
                id = who[ids[i]].uid
                doesHaveVideo = true
                name = who[ids[i]].name
                meetingTypeRaw = who[ids[i]].type
            }
        }

        if (meetingTypeRaw === "REMOTE") meetingType = 'remote'
        else if (meetingTypeRaw === "IN_PERSON") meetingType = 'office'

        const typeLabel = (
            <div className={`${meetingType}-label-container`}>
                <p>{meetingType.charAt(0).toUpperCase() + meetingType.slice(1)}</p>
            </div>
        )

        const loadingAnimation = (
            <div className='cell-loading-animation'>
                <img
                    className='loading-spinner'
                    src={require("../assets/spinner.gif")}
                    alt={'loading'}
                />
            </div>
        )

        const opacity = item === 0.05 ? 0.3 : item

        if (type === "empty") {
            view = (
                <div
                    key={`${index}_cell`}
                    id={`${index}_cell`}
                    onClick={() => { this.setGridIndex(false, index) }}
                    style={{ opacity: isInActiveSession ? opacity : 0 }}
                    className="video-cell"
                >
                    <div className="square-cell-base">
                        <div id={`${index}_video_content`} className="video-content" />
                    </div>
                    {doesHaveVideo ? typeLabel : null}
                    {doesHaveVideo && meetingType === '' ? loadingAnimation : null}
                    <div className={`${meetingType}-cell-hover-view`}>
                        <div className={`${meetingType}`}>
                            {doesHaveVideo ? <p className={`${meetingType}-text`}>{name}</p> : null}
                        </div>
                    </div>
                </div>
            )
        }

        return view
    }

    renderGrid() {
        const { volumeGrid, currentUserState, currentRoom, user, isCalibratingResonance, showPerimssionsModal } = this.state

        const hasVisited = user.account_info.has_visited
        const isInActiveSession = this.sessionHelper ? (this.sessionHelper.connection ? true : false) : false
        let who = {}
        let layout = {}
        if (currentRoom.who) who = currentRoom.who
        if (currentRoom.callSession.layout) layout = currentRoom.callSession.layout


        const content = volumeGrid.map((item, index) => {
            return this.renderCell(isInActiveSession, index, "empty", item, who, layout)
        })

        return (
            <>
                <div id="no-activity-call-container" className="no-activity-call-container">
                    {content}
                </div>
                {currentUserState.length > 0 && !hasVisited ? this.renderTutorial() : null}
                {currentUserState.length === 0 ? this.renderChoosingView() : null}
                {isCalibratingResonance ? this.renderCalibrationProcess() : null}
                {showPerimssionsModal ? this.renderCallPermissionsModal() : null}
            </>
        )
    }

    renderHeadCheck() {

        return (
            <div className="calibrate-choosing-container">
                <h1 className="hero_text align-self-center">Calibrating Spatial Audio...</h1>
                <div className='type-choosing-view'>
                    <div className='d-flex flex-column align-items-center justify-content-center w-75'>
                        <FontAwesomeIcon icon={faHeadphonesAlt} size={"8x"} color={'white'} className="mb-5 align-self-center" />
                        <h3 className='text-light text-center mb-3'>Do you have headphones connected?</h3>
                        <p className='text-light text-center'>For 3D Audio/Spatial Audio to work, you will need to wear headphones.</p>
                        <div className='d-flex flex-row align-items-center justify-content-around mt-4 w-25'>
                            <button className="purple-btn hover-btn cool-btn" onClick={this.startBaseVolumetricCall.bind(this)}>No</button>
                            <button className="purple-btn hover-btn cool-btn" onClick={this.nextCalibrationProcess.bind(this)}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    renderInitialSoundCheck() {

        return (
            <div className="calibrate-choosing-container">
                <h1 className="hero_text align-self-center">Calibrating Spatial Audio...</h1>
                <div className='type-choosing-view'>
                    <div className='d-flex flex-column align-items-center justify-content-center w-75'>
                        <h3 className='text-light text-center'>We are currently using normal stereo audio to play this sound, meaning you should hear it from <span className='font-weight-bold'>BOTH LEFT AND RIGHT</span>.</h3>
                        <div className='d-flex flex-row align-items-center justify-content-around mt-4 w-25'>
                            <button className="purple-btn hover-btn cool-btn" onClick={this.startCalibrationProcess.bind(this)}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    renderCalibrationProcess() {

        const { audioDevices, calibrationState } = this.state

        if (calibrationState === 'HEAD_CHECK') {
            // initial headphone check
            // if yes, move to next step and play music
            // if no, straight to basic volumetric control

            return this.renderHeadCheck()

        } else if (calibrationState === 'BASE_SOUND_CHECK') {

            return this.renderInitialSoundCheck()

        } else if (calibrationState === 'CALIB_CHECK') {

            return this.renderCalibratingView()
        }

    }

    renderCalibratingView() {

        return (
            <div className="calibrate-choosing-container">
                <h1 className="hero_text align-self-center">Calibrating Spatial Audio...</h1>
                <div className='type-choosing-view'>
                    {/* <video
                        loop autoPlay muted
                        className="calibrate-video"
                        src={require("../assets/audio_waveform.mp4")} /> */}
                    <div className='d-flex flex-column align-items-center justify-content-center w-75'>
                        <h3 className='text-light text-center'>Can you hear the sound specifically from your <span className='font-weight-bold'>LEFT</span> and that it's <span className='font-weight-bold'>QUIETER</span>?</h3>
                        <div className='d-flex flex-row align-items-center justify-content-around mt-4 w-25'>
                            <button className="purple-btn hover-btn cool-btn" onClick={this.setResonanceMic.bind(this, false)}>No</button>
                            <button className="purple-btn hover-btn cool-btn" onClick={this.setResonanceMic.bind(this, true)}>Yes</button>
                        </div>
                    </div>
                    {/* <video
                        loop autoPlay muted
                        className="calibrate-video o-50"
                        src={require("../assets/audio_waveform.mp4")} /> */}
                </div>
            </div>
        )

    }

    renderSettingsModal() {
        const { isShowingSettings, user, currentAudioDevice, currentVideoDevice, audioDevices, videoDevices } =
            this.state

        var callView = null

        // is in an active call/room, show video/audio settings
        if (this.sessionHelper) {
            callView = (
                <div className="d-flex flex-column">
                    <h6 className="mb-3 text-dark">Call Settings</h6>
                    <div className="d-flex flex-row">
                        <div className="col d-flex flex-row">
                            <FontAwesomeIcon icon={faMicrophone} size={"sm"} className="m-2 align-self-center" />
                            <Form.Control
                                as="select"
                                onChange={this.changeAudioDevice.bind(this)}
                                value={currentAudioDevice}
                            >
                                {audioDevices.map((audioDevice) => (
                                    <option key={audioDevice.label} value={audioDevice.deviceId}>
                                        {audioDevice.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                        <div className="col d-flex flex-row">
                            <FontAwesomeIcon icon={faVideo} size={"sm"} className="m-2 align-self-center" />
                            <Form.Control
                                as="select"
                                onChange={this.changeVideoDevice.bind(this)}
                                value={currentVideoDevice}
                            >
                                {videoDevices.map((videoDevice) => (
                                    <option key={videoDevice.label} value={videoDevice.deviceId}>
                                        {videoDevice.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isShowingSettings}
                onHide={this.toggleSettingsModal.bind(this)}
            >
                <Modal.Body>
                    <h6 className="mb-0 text-dark">Your Information</h6>
                    <div className="d-flex justify-content-center align-items-center">
                        <img
                            src={user.info.photoURL}
                            alt="activityphoto"
                            height="80px"
                            width="80px"
                            className="rounded img-fluid mb-3"
                        />
                    </div>
                    <h3 className="text-center mb-0">{user.info.displayName}</h3>
                    <p className="text-center text-muted mb-2">{user.info.email}</p>
                    <button className="orange-btn cool-btn active-button" onClick={this.resetCalibration.bind(this)}>
                        Reset Mic Calibration
                    </button>
                    {callView}
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button className="orange-btn cool-btn active-button" onClick={this.toggleSettingsModal.bind(this)}>
                        Done
                    </button>
                    <button className="red-btn cool-btn" variant="danger" onClick={this.logUserOut.bind(this)}>
                        Log Out
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderHelperCodeModal() {
        const { isShowingHelperCode, helperCode } = this.state

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isShowingHelperCode}
                onHide={this.toggleHelperCodeModal.bind(this)}
            >
                <Modal.Body>
                    <h6 className="mb-0 text-dark">Your Helper Code</h6>
                    <h3 className="mb-0">{helperCode}</h3>
                    <p className="text-muted mb-2">Paste this into the Between helper app!</p>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button
                        className="orange-btn cool-btn active-button"
                        onClick={this.toggleHelperCodeModal.bind(this)}
                    >
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderRoomFullModal() {

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={() => null}
            >
                <Modal.Body>
                    <h2 className="text-center">Max Capacity Reached!</h2>
                    <p className="text-center mb-0">This call is full! Join another call or start your own call.</p>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-center align-items-center">
                    <button
                        className="orange-btn cool-btn active-button"
                        onClick={this.goHome.bind(this)}
                    >
                        Go Home
                    </button>
                </Modal.Footer>
            </Modal>
        )

    }

    renderCallPermissionsModal() {

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={true}
                onHide={() => null}
            >
                <Modal.Body>
                    <h2 className="text-center">Please Allow Video/Audio Permissions!</h2>
                    <p className="text-center mb-0">To have the best experience with Between, we'll have to ask you to allow us access to your audio and video.</p>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-center align-items-center">
                    <button
                        className="orange-btn cool-btn active-button"
                        onClick={this.startFirstTimeCalibrationProcess.bind(this, false)}
                    >
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
        )

    }

    renderChatView() {
        const { chat, roomCode, user } = this.state

        const name = user.info.displayName
        const uid = user.info.uid

        return (
            <div className={`abs-chat-view cool-box-shadow ${!chat ? "hide-chat" : ""}`}>
                {<Chat type={'groups'} id={roomCode} displayName={name} uid={uid} isShowing={chat} toggleChat={this.toggleChat.bind(this)} />}
            </div>
        )
    }

    renderTutorial() {
        const { isButtonDisabled } = this.state
        setTimeout(() => this.setState({ isButtonDisabled: false }), 6000);
        return (
            <div className='tutorial-modal-container'>
                <div className='tutorial-modal'>
                    <video
                        loop="true"
                        autoplay="autoplay"
                        className='tutorial-gif'
                        src={require("../assets/tutorial.mp4")} />
                    <h3>Click or use the arrow keys to move!</h3>
                    <div className='button-container'>
                        <button
                            className="purple-btn hover-btn cool-btn"
                            disabled={isButtonDisabled}
                            onClick={this.finishTutorial.bind(this)}>
                            Got it</button>
                    </div>
                </div>
            </div>
        )

    }

    async finishTutorial() {
        const { user } = this.state
        const uid = user.info.uid
        await database.ref(`/users/${uid}/account_info/has_visited`).set(true)
    }

    renderChoosingView() {
        return (
            <div className="type-choosing-container">
                <h1 className="hero_text align-self-center ">Where Are You?</h1>
                <div className="type-choosing-view">
                    <div className="type-choosing-image-container">
                        <img
                            src={require("../assets/call-remote.png")}
                            onClick={this.temp_setTypeAndStartCall.bind(this, "REMOTE")}
                            alt="remote"
                        />
                    </div>
                    <div className="type-choosing-image-container">
                        <img
                            src={require("../assets/call-in-office.png")}
                            onClick={this.temp_setTypeAndStartCall.bind(this, "IN_PERSON")}
                            alt="in-office"
                        />
                    </div>
                </div>
            </div>
        )
    }

    removeScreenShareChildren() {
        const screenShareView = document.getElementById("screen-share-view")
        if (!screenShareView) return

        while (screenShareView.firstChild) {
            screenShareView.removeChild(screenShareView.firstChild)
        }
    }

    renderPublisherView() {
        const screenBase = document.createElement("div")
        screenBase.className = "d-flex h-100 w-100 flex-column align-items-center justify-content-center"

        const text = document.createElement("h4")
        text.innerText = "You Are Screensharing"
        text.className = "text-center text-white mb-4"

        const btn = document.createElement("button")
        btn.innerHTML = "Stop Screensharing"
        btn.className = "purple-btn cool-btn hover-btn"
        btn.onclick = this.toggleScreenShare.bind(this)

        screenBase.appendChild(text)
        screenBase.appendChild(btn)

        return screenBase
    }

    renderRoomInfo() {
        const {
            user,
            space,
            chat,
            isShowingSettings,
            isShowingAnalytics,
            roomCode,
            currentRoom,
            startingCall,
            notifications,
            isOutOfTime,
            audio,
            video,
            currentUserState,
            isRoomFull,
        } = this.state


        const isInActiveSession = this.sessionHelper ? (this.sessionHelper.connection ? true : false) : false
        const isRoomHost = currentRoom ? currentRoom.host === user.info.uid : false
        const { hasUnreadMessages } = notifications

        // chat window view
        const chatView = this.renderChatView()

        let screenShareView = <div ref={this.screenShareRef} id='screen-share-view' className='screen-share-view d-none' />

        const defaultView = (
            <div className="room-main-view">
                <div className="room-content-holder"><div className="no-activity-top-view">{this.renderGrid()}</div></div>
                {screenShareView}
            </div>
        )

        return (
            <div className="main-bg container-grid">
                {chatView}
                {isShowingAnalytics ? <Analytics facultyName={space.name} /> : defaultView}
                <Toolbar
                    // data
                    roomId={roomCode}
                    // isLoungeManager={userType === 'leader'}
                    isRoomHost={isRoomHost}
                    // bools
                    isInRoom={true}
                    session={isInActiveSession}
                    currentUserState={currentUserState}
                    toggleChat={this.toggleChat.bind(this)}
                    chat={chat}
                    settings={isShowingSettings}
                    showCompanyCode={isShowingAnalytics}
                    startingCall={startingCall}
                    hasUnreadMessages={hasUnreadMessages}
                    isOutOfTime={isOutOfTime}
                    audio={audio}
                    video={video}
                    // functions
                    toggleSettingsModal={this.toggleSettingsModal.bind(this)}
                    toggleAnalytics={this.toggleAnalyticsView.bind(this)}
                    toggleAudio={this.micStatusChanged.bind(this)}
                    toggleVideo={this.camStatusChanged.bind(this)}
                    toggleScreenShare={this.toggleScreenShare.bind(this)}
                    toggleUserLocationType={this.changeUserState.bind(this)}
                    goHome={this.goHome.bind(this)}
                    joinCall={this.startTheCall.bind(this)}
                    endCall={this.goHome.bind(this)}
                    setChoosingView={this.setChoosingView.bind(this)}
                    getAndShowHelperCode={this.getHelperCode.bind(this)}
                />
                {this.renderSettingsModal()}
                {this.renderHelperCodeModal()}
                {isRoomFull ? this.renderRoomFullModal() : null}
            </div>
        )
    }

    renderError() {
        const { error, errorDetails } = this.state

        if (error === "NOT_IN_COMPANY") {
            return <CreateALounge showCodeEntry companyDetails={errorDetails} pushURL={this.pushURL.bind(this)} />
        } else if (error === "NOT_IN_ANY") {
            return <CreateALounge pushURL={this.pushURL.bind(this)} />
        } else if (error === "ROOM_DOES_NOT_EXIST") {
            return (
                <div className="main-wrapper main-bg not-found-error">
                    <div className="not-found-container">
                        <p className="text-center mt-0 error-message">There was an error loading into the room. If you are the creator of this room we recommend refreshing. If not please double check that the link or code is valid.</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <button className="orange-btn cool-btn" onClick={() => this.pushURL("/space")}>
                                Go Home
                            </button>
                        </div>
                        <div className='divider'>OR</div>
                        <div className="d-flex align-items-center justify-content-center">
                            <button className="orange-btn cool-btn" onClick={() => window.location.reload()}>
                                Refresh Page
                            </button>
                        </div>


                    </div>
                </div>
            )
        } else {
            return (
                <div className="main-wrapper main-bg">
                    <p className="dark-text text-center mt-4">{error}</p>
                </div>
            )
        }
    }

    render() {
        const { currentRoom, error, no } = this.state

        if (error) return this.renderError()
        else if (currentRoom && currentRoom.id.length > 0) {
            return this.renderRoomInfo()
        } else {
            return (
                <div className="main-wrapper main-bg align-items-center justify-content-center">
                    <h5 className="dark-text text-center">Loading...</h5>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        notifications: state.notify,
        currentRoom: state.room,
    }
}

export default withRouter(connect(mapStateToProps)(EphemeralRoomPage))