import React, { Component } from "react"
import { connect } from "react-redux"
import * as moment from "moment"

import "../assets/css/LandingPage.css"
import "../assets/css/react-big-calendar.css"

import Modal from "react-bootstrap/Modal"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPlus,
    faTrash,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faInfo,
} from "@fortawesome/free-solid-svg-icons"
import firebase from "../firebase"
import { withRouter } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import Analytics from "./Analytics"
import Toolbar from "./Toolbar"
import CreateALounge from "./CreateALounge"
import LoungeManager from "./LoungeManager"
// import Chat from "./Chat"

import { Button, Table } from "react-bootstrap"

import RoomView from "./widgets/RoomView/RoomView"
import QuotesWidget from "./widgets/Quotes/QuotesWidget"

import { logoutUser, detachListener } from "../redux/actions"

import pino from "./pino"

var database = firebase.database()

var isEqual = require("lodash.isequal")

class CompanyPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user: {},
            error: null,
            errorDetails: null,

            viewType: "default", // 'default', 'company_creation'

            spaces: [],

            spaceUrl: "",

            // toolbar buttons
            chat: false,
            isShowingAnalytics: false,
            isShowingSettings: false,
            isShowingInfo: false,
            isShowingManagement: false,
            isEditingWidgets: false,

            allWidgets: {},
            widgets: {},
            widgetsArray: [
                ["empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"],
                ["empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"],
                ["empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"],
                ["empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"],
                ["empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"],
                ["empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"],
                ["empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"],
                ["empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"],
                ["empty", "empty", "empty", "empty", "empty", "empty", "empty", "empty"],
            ],

            chosenSettingsView: "user", // 'user', 'lounge'

            // space
            spaceId: "",
            space: null,
            rooms: [],
            roomNames: [],
            code: "",
            isSpaceManager: false,
            isOutOfTime: false,

            showCheckoutModal: false,
            checkoutModalType: "",

            notifications: {
                hasUnreadMessages: false,
            },

            // room creation
            showRoomCreationModal: false,
            roomCreationName: "",
            roomCreationType: "public", // 'public', 'private'
            roomCreationAllowedMembers: [],
        }
    }

    async componentDidMount() {
        const { match } = this.props
        const {
            params: { spaceId, checkoutSession },
        } = match

        if (checkoutSession) {
            this.setState({
                spaceId,
                showCheckoutModal: true,
                checkoutModalType: checkoutSession,
            })
        } else {
            this.setState({ spaceId })
        }

        await this.handleSpaceRedirect(spaceId)
        // await this.updateMetrics()
        await this.getAllUserSpaces()
        // await this.getAllAvailableWidgets()
        // await this.debugCompanies()
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var shouldUpdateUser = !isEqual(nextProps.user, prevState.user)
        var shouldUpdateNotification = !isEqual(nextProps.notifications, prevState.notifications)

        if (shouldUpdateUser) {
            return { user: nextProps.user }
        } else if (shouldUpdateNotification) {
            return { notifications: nextProps.notifications }
        } else {
            return null
        }
    }

    async componentDidUpdate(_prevProps, prevState) {
        if (!isEqual(this.props.user, prevState.user)) {
            if (!prevState.user && !this.state.spaceId) {
                await this.handleSpaceRedirect(this.state.spaceId)
                await this.getAllUserSpaces()
            }
            this.setState({ user: this.props.user })
        }

        if (!isEqual(this.props.notifications, prevState.notifications)) {
            this.setState({ notifications: this.props.notifications })
        }
    }

    componentWillUnmount() {
        const { space } = this.state
        const spaceId = space.id

        database.ref(`/spaces/${spaceId}/`).off("value")
    }

    async debugCompanies() { }

    streamSpace() {
        const { spaceId, user } = this.state
        if (!spaceId) return

        const uid = user.info.uid

        database.ref(`/spaces/${spaceId}/`).on("value", (spaceSnap) => {
            this.setState({ space: spaceSnap.val() })

            const spaceData = spaceSnap.val()
            const type = spaceData.users[uid].type
            if (type === "user") this.setState({ isSpaceManager: false })
            if (type === "leader") this.setState({ isSpaceManager: true })
        })
    }

    logUserOut() {
        let loggingOut = window.confirm("Are you sure you want to log out?")

        if (!loggingOut) {
            return
        } else {
            const { dispatch } = this.props

            dispatch(detachListener(this.state.user.info.uid))
            dispatch(logoutUser())
        }
    }

    /* Database Helpers */

    async getSpaceInformation(spaceId) {
        const spaceSnap = await database.ref(`/spaces/${spaceId}/`).once("value")
        if (!spaceSnap.exists()) return

        const space = spaceSnap.val()

        this.setState({ space, spaceUrl: `/spaces/${spaceId}` }, async () => {
            this.listenForNewRooms()
            this.streamSpace()
            // this.listenForCompanyWidgets();
            // this.checkIfDeathIsHere();
        })
    }

    async createNewRoom() {
        const { space, user, roomCreationName } = this.state

        try {
            const requestBody = {
                roomName: roomCreationName,
                spaceId: space.id,
                userId: user.info.id,
            }

            const resp = await fetch("https://us-central1-between-87295.cloudfunctions.net/api/createRoomForSpace", {
                method: "POST",
                body: JSON.stringify(requestBody),
            })

            if (resp.status === 200) {
                // close modal and refresh spaces
                const response = await resp.json()
                console.log("response", response)
                this.setState({ roomCreationName: "", showRoomCreationModal: false })
            } else if (resp.status === 400) {
                // ahh error, show modal with error and hope for the best
                const response = await resp.json()
                const errorMessage = response.message
                alert("Error Creating Room: ", errorMessage)
            }
        } catch (error) { }
    }

    async enterRoomURL(id) {
        this.pushURL(`/room/${id}`)
    }

    async getAllUserSpaces() {
        const { user } = this.props

        if (!user) return

        const spaces = []
        const userSpaces = user.spaces ? Object.values(user.spaces) : []

        for (let i = 0; i < userSpaces.length; i++) {
            const spaceId = userSpaces[i]

            const spaceInfo = await database.ref(`/spaces/${spaceId}`).once("value")
            spaces.push(spaceInfo.val())
        }

        this.setState({ spaces })
    }

    async getAllAvailableWidgets() {
        const widgetsSnap = await database.ref(`/widgets`).once("value")
        const allWidgets = widgetsSnap.val()

        this.setState({ allWidgets })
    }

    /* Listeners */

    listenForNewRooms() {
        const { space } = this.state

        var roomUrl = `/spaces/${space.id}/rooms/`

        database.ref(roomUrl).on("value", async (roomSnap) => {
            if (roomSnap.exists()) {
                // update rooms in state
                const rooms = Object.values(roomSnap.val())
                this.setState({ rooms })
            }
        })
    }

    listenForCompanyWidgets() {
        database.ref(`${this.state.spaceUrl}/widgets/`).on("value", (widgetsSnap) => {
            const widgets = widgetsSnap.val()

            const { widgetsArray } = this.state

            for (var widgetId in widgets) {
                const dimensionsString = widgets[widgetId].dimensions

                const dimensions = dimensionsString.split(" ")
                const gridColumnStart = Number(dimensions.find((e) => e.includes("gcs")).charAt(4)) - 1
                const gridColumnEnd = Number(dimensions.find((e) => e.includes("gce")).charAt(4)) - 1
                const gridRowStart = Number(dimensions.find((e) => e.includes("grs")).charAt(4)) - 2
                const gridRowEnd = Number(dimensions.find((e) => e.includes("gre")).charAt(4)) - 2

                widgets[widgetId]["dimensions_obj"] = {
                    gridColumnStart,
                    gridColumnEnd,
                    gridRowStart,
                    gridRowEnd,
                }

                for (let i = 0; i < widgetsArray.length; i++) {
                    const column = widgetsArray[i]
                    for (let j = 0; j < column.length; j++) {
                        if (
                            i >= gridRowStart &&
                            i <= gridRowEnd - 1 &&
                            j >= gridColumnStart &&
                            j <= gridColumnEnd - 1
                        ) {
                            widgetsArray[i][j] = widgetId
                        }
                    }
                }
            }

            this.setState({ widgets, widgetsArray })
        })
    }

    /* Message Helpers */

    /* Lobby Helpers */

    goToSpace(code) {
        const { history } = this.props
        history.push(`/space/${code}`)
    }

    // TODO
    // set delete lounge
    async deleteLounge(id) {
        // delete rooms
        // delete codes from 'codes' node
        // delete from each users 'companies' node
        //
        // if only user space, change account type to 'free'
        // else if not only space, then change account id to next space
        //
        // set state variables to null
        // send back to '/space'

        const { space, user } = this.state

        const wantsToDelete = window.confirm("Are you sure you want to delete this lounge?")
        if (!wantsToDelete) return

        this.setState({ space: null })
    }

    toggleChat() {
        this.setState({ chat: !this.state.chat })
    }

    toggleSettingsModal() {
        this.setState({ isShowingSettings: !this.state.isShowingSettings })
    }

    toggleCheckoutModal() {
        this.setState({ showCheckoutModal: !this.state.showCheckoutModal })
    }

    toggleAnalyticsView() {
        this.setState({ isShowingAnalytics: !this.state.isShowingAnalytics })
    }

    toggleEditMode() {
        this.setState({ isEditingWidgets: !this.state.isEditingWidgets })
    }

    toggleManageModal() {
        this.setState({ isShowingManagement: !this.state.isShowingManagement })
    }

    toggleRoomCreationModal() {
        this.setState({ showRoomCreationModal: !this.state.showRoomCreationModal })
    }

    goHome() {
        const { space } = this.state

        const id = space.id

        this.goToSpace(id)
    }

    toggleInfoView() {
        this.setState({ isShowingInfo: !this.state.isShowingInfo })
    }

    handleRoomCreationName(e) {
        const name = e.target.value
        if (name.length < 20) {
            this.setState({ roomCreationName: name })
        }
    }

    setRoomType(e) {
        this.setState({ roomCreationType: e.target.value })
    }

    /* User Helpers */

    async handleSpaceRedirect(spaceId) {
        // use user data (stored companies) and company code to handle url accordingly
        const { user } = this.props

        if (!user) return

        const account_info = user.account_info

        var userIsInSpace = false

        // if space code doesn't exist
        if (!spaceId) {
            // send to default space
            if (account_info.id) {
                this.goToSpace(account_info.spaceId)
                return
            }
        }

        // check first if space is part of user 'spaces' dictionary
        const userSpaces = user.spaces ? Object.values(user.spaces) : []
        const filteredSpace = userSpaces.filter((c) => c === spaceId)
        userIsInSpace = filteredSpace.length !== 0

        // fetch space info from id
        const spaceSnap = await database.ref(`/spaces/${spaceId}`).once("value")
        // get first matching code from database request
        const isValidSpace = spaceSnap.exists()
        const fetchedSpace = isValidSpace ? spaceSnap.val() : null

        if (userIsInSpace && isValidSpace && fetchedSpace.id === spaceId) {
            // if user has space in their 'spaces' dictionary
            // and it matches spaceId
            await this.getSpaceInformation(spaceId)
            return
        } else if (!userIsInSpace && isValidSpace && fetchedSpace.id === spaceId) {
            // user doesn't have space but space is valid
            // prompt the code entering
            this.setState({ error: "NOT_IN_COMPANY", errorDetails: fetchedSpace })
        } else if (!isValidSpace) {
            // invalid space, show error
            this.setState({ error: "DOES_NOT_EXIST" })
        } else {
            // go back to where you came form
            this.pushURL(`/space`)
        }
    }

    changeUserRole(id, role, name) {
        const { space, user } = this.state

        const spaceId = space.id
        const currentUserId = user.info.uid
        if (id === currentUserId) {
            alert("You can't change your own role to user! Please select a different user.")
            return
        }
        let isChangingRole = null

        if (role === "leader") {
            isChangingRole = window.confirm(
                `Removing admin prvileges will not allow ${name} to manage this space. Do you want to continue?`
            )
        } else if (role === "user") {
            isChangingRole = window.confirm(
                `Making ${name} an admin will allow them to make changes to this space. Do you want to continue?`
            )
        }
        let changedRole = ""

        if (!isChangingRole) {
            return
        } else {
            if (role === "user") changedRole = "leader"
            else if (role === "leader") changedRole = "user"

            database.ref(`/spaces/${spaceId}/users/${id}/type`).set(changedRole)
        }
    }

    async updateMetrics() {
        const { space, spaceUrl } = this.state

        if (!space) return

        const usersRaw = space.users

        const userInfo = Object.values(usersRaw)
        const metrics = []
        let connectivity = 0
        let cooperation = 0
        let creativity = 0
        let enjoyment = 0
        let wellness = 0

        for (let i = 0; i < userInfo.length; i++) {
            let allMetrics = userInfo[i].company_metrics
            metrics.push(allMetrics)
        }

        for (let i = 0; i < metrics.length; i++) {
            connectivity += metrics[i].connectivity
            cooperation += metrics[i].cooperation
            creativity += metrics[i].creativity
            enjoyment += metrics[i].enjoyment
            wellness += metrics[i].wellness
        }

        connectivity /= metrics.length
        cooperation /= metrics.length
        creativity /= metrics.length
        enjoyment /= metrics.length
        wellness /= metrics.length

        const avgMetrics = {
            connectivity: connectivity,
            cooperation: cooperation,
            creativity: creativity,
            enjoyment: enjoyment,
            wellness: wellness,
        }

        await database.ref(`${spaceUrl}/company_metrics`).set(avgMetrics)
    }

    async afterActivityMetrics(activityMetrics, who) {
        if (!who) return
        const { company } = this.state
        //Capture all users in the company
        const usersRaw = company.users
        //Store user info that contains metric stats and id in array of objects
        const allUsersInfo = Object.values(usersRaw)
        //Store the categories associated with the activity passed as props into an array
        const metricCategories = Object.keys(activityMetrics)
        //Store the values associated with the categories of the activity passed as props into an array
        const metricValues = Object.values(activityMetrics)

        //Declare variables needed later
        const convertredValues = []
        let usersToUpdate = null
        const allUsersIds = []
        const indexesToUpdate = []

        //Convert the rating of 1-3 to appropriate percentage value and store in new array
        for (let i = 0; i < metricValues.length; i++) {
            let value = null
            if (metricValues[i] === 1) value = 0.05
            else if (metricValues[i] === 2) value = 0.075
            else if (metricValues[i] === 3) value = 0.1

            convertredValues.push(value)
        }

        //Store the user ids of everyone in the call at the time of ending activity into an array
        if (who) {
            usersToUpdate = Object.keys(who)
        }

        //Store the user ids of everyone associated with the company to compare against the previous array
        for (let i = 0; i < allUsersInfo.length; i++) {
            let userId = allUsersInfo[i].id
            allUsersIds.push(userId)
        }

        //Store the index of all users that need to have metrics updated
        for (let i = 0; i < allUsersIds.length; i++) {
            let shouldUpdate = usersToUpdate.includes(allUsersIds[i])
            if (shouldUpdate) indexesToUpdate.push(i)
        }

        //Calculate the new scores for the metrics that should be updated and set in database
        for (let i = 0; i < indexesToUpdate.length; i++) {
            let metricsToUpdate = allUsersInfo[indexesToUpdate[i]].company_metrics

            let value1 = metricsToUpdate[metricCategories[0]]
            let value2 = metricsToUpdate[metricCategories[1]]
            let value3 = metricsToUpdate[metricCategories[2]]

            let increase1 = value1 * convertredValues[0]
            let increase2 = value2 * convertredValues[1]
            let increase3 = value3 * convertredValues[2]

            value1 += increase1
            value2 += increase2
            value3 += increase3

            metricsToUpdate[metricCategories[0]] = value1

            if (metricsToUpdate[metricCategories[0]] > 100) metricsToUpdate[metricCategories[0]] = 100

            //Check if more than one category to update exists and ensure it never surpasses 100
            if (metricCategories[1]) metricsToUpdate[metricCategories[1]] = value2
            if (metricCategories[1] && metricsToUpdate[metricCategories[1]] > 100)
                metricsToUpdate[metricCategories[1]] = 100

            if (metricCategories[2]) metricsToUpdate[metricCategories[2]] = value3
            if (metricCategories[2] && metricsToUpdate[metricCategories[2]] > 100)
                metricsToUpdate[metricCategories[2]] = 100

            database
                .ref(`companies/${company.id}/users/${allUsersInfo[indexesToUpdate[i]].id}/company_metrics`)
                .set(metricsToUpdate)
        }
    }

    /* Misc Helpers */

    pushURL(url) {
        const { history } = this.props
        history.push(url)
    }

    validateWidgetMove(widget) {
        const { allWidgets, widgets } = this.state
    }

    checkIfDeathIsHere() {
        const { space } = this.state

        const spaceSubscriptionEnd = parseInt(space.subscription.period.end)
        const currentTime = moment().unix()
        if (currentTime >= spaceSubscriptionEnd) {
            this.setState({ isOutOfTime: true })
        }
    }

    async deleteUserFromSpace(id, name) {
        const { space, user } = this.state

        const uid = user.info.uid

        if (id === uid) {
            alert("You can't remove yourself from a space!")
            return
        }

        const isRemoving = window.confirm(`Are you sure you want to remove ${name} from this space?`)

        if (isRemoving) {
            try {
                const requestBody = {
                    spaceId: space.id,
                    userId: id,
                    removedBy: uid,
                }

                const resp = await fetch(
                    "https://us-central1-between-87295.cloudfunctions.net/api/deleteUserFromSpace",
                    {
                        method: "POST",
                        body: JSON.stringify(requestBody),
                    }
                )

                if (resp.status === 200) {
                    // close modal and refresh spaces
                    const response = await resp.json()
                    console.log("response", response)
                } else if (resp.status === 400) {
                    // ahh error, show modal with error and hope for the best
                    const response = await resp.json()
                    const errorMessage = response.message
                    alert("Error Removing User: ", errorMessage)
                }
            } catch (error) { }
        }
    }
    /* UI Helpers */

    renderError() {
        const { error, errorDetails } = this.state

        if (error === "NOT_IN_COMPANY") {
            return <CreateALounge showCodeEntry companyDetails={errorDetails} pushURL={this.pushURL.bind(this)} />
        } else if (error === "DOES_NOT_EXIST") {
            return (
                <div className="main-wrapper main-bg">
                    <h2 className="dark-text text-center mt-4">404</h2>
                    <p className="dark-text text-center mt-0">The space you are looking for does not exist!</p>
                    <div className="d-flex align-items-center justify-content-center">
                        <button className="orange-btn cool-btn" onClick={() => this.pushURL("/space")}>
                            Go Home
                        </button>
                    </div>
                </div>
            )
        } else if (error === "NOT_IN_ANY") {
            return <CreateALounge pushURL={this.pushURL.bind(this)} />
        } else {
            return (
                <div className="main-wrapper main-bg">
                    <p className="dark-text text-center mt-4">{error}</p>
                </div>
            )
        }
    }

    renderChatView() {
        const { chat, space, user } = this.state

        const name = user.info.displayName
        const uid = user.info.uid

        return (
            <div className={`abs-chat-view cool-box-shadow ${!chat ? "hide-chat" : ""}`}>
                {/* <Chat type={'companies'} id={company.id} displayName={name} uid={uid} isShowing={chat} toggleChat={this.toggleChat.bind(this)} /> */}
            </div>
        )
    }

    renderSettingsModal() {
        const { isShowingSettings, user } = this.state

        var email = user.info.email
        if (!user.info.email) email = user.info.providerData ? user.info.providerData[0].email : ""

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isShowingSettings}
                onHide={this.toggleSettingsModal.bind(this)}
            >
                <Modal.Body>
                    <h6 className="mb-0 text-dark">Your Information</h6>
                    <div className="d-flex justify-content-center align-items-center">
                        <img
                            src={user.info.photoURL}
                            alt="activityphoto"
                            height="80px"
                            width="80px"
                            className="rounded img-fluid mb-3"
                        />
                    </div>
                    <h3 className="text-center mb-0">{user.info.displayName}</h3>
                    <p className="text-center text-muted mb-2">{email}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button className="orange-btn cool-btn active-button" onClick={this.toggleSettingsModal.bind(this)}>
                        Done
                    </button>
                    <button className="red-btn cool-btn" variant="danger" onClick={this.logUserOut.bind(this)}>
                        Log Out
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderCheckoutModal() {
        const { showCheckoutModal, checkoutModalType } = this.state

        var subView = null

        if (checkoutModalType === "success") {
            subView = (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={require("../assets/happy-face.png")} alt="success" className="img-fluid w-50" />
                    <h4 className="mt-3">Payment Complete!</h4>
                    <h6 className="mb-0 font-weight-normal">You've successfully upgraded your account!</h6>
                </div>
            )
        } else if (checkoutModalType === "cancel") {
            subView = (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="mt-3">Payment Failed!</h4>
                    <h6 className="mb-0 font-weight-normal">Please go your lounge settings to review your options.</h6>
                </div>
            )
        }

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showCheckoutModal}
                onHide={this.toggleCheckoutModal.bind(this)}
            >
                <Modal.Body>{subView}</Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button className="orange-btn cool-btn active-button" onClick={this.toggleCheckoutModal.bind(this)}>
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderRoomCreationModal() {
        const { showRoomCreationModal, roomCreationName, roomCreationType, roomCreationAllowedMembers } = this.state

        const isValidEntry = roomCreationName.length > 3 && roomCreationName.length < 20

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showRoomCreationModal}
                onHide={this.toggleRoomCreationModal.bind(this)}
            >
                <Modal.Body>
                    <h3 className="mb-3">Create A Room</h3>
                    <div className="d-flex">
                        <div className="col-5 manage-lounge-name">
                            <label className="">Name</label>
                            <div className="f-1">
                                <input
                                    className=""
                                    value={roomCreationName}
                                    onChange={this.handleRoomCreationName.bind(this)}
                                />
                            </div>
                        </div>
                        {/* <div className='col-5 manage-lounge-name'>
              <label className="">Type</label>
              <div className="f-1">
                <div className='small-radio-btn-container'>
                  <label className={`small-radio-btn ${roomCreationType === 'public' ? 'small-radio-btn-active' : ''}`} >
                    <input type='radio' value='public' checked={roomCreationType === 'public'}
                      onChange={this.setRoomType.bind(this)} />
                    <small className='mb-0'>Public</small>
                  </label>
                  <label className={`small-radio-btn ${roomCreationType === 'private' ? 'small-radio-btn-active' : ''}`} >
                    <input type='radio' value='private' checked={roomCreationType === 'private'}
                      onChange={this.setRoomType.bind(this)} />
                    <small className='mb-0'>Private</small>
                  </label>
                </div>
              </div>
            </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button className="red-btn cool-btn hover-button" onClick={this.toggleRoomCreationModal.bind(this)}>
                        Cancel
                    </button>
                    <button
                        className="orange-btn cool-btn hover-button"
                        disabled={!isValidEntry}
                        onClick={this.createNewRoom.bind(this)}
                    >
                        Create
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderManageModal() {
        const { isShowingManagement, space } = this.state
        const users = Object.values(space.users)

        return (
            <Modal
                size="lg"
                scrollable={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isShowingManagement}
                onHide={() => this.setState({ isShowingManagement: false })}
            >
                <Modal.Header>
                    <Modal.Title>User Management</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container" />

                    {users.map((user) => (
                        <div key={user.id} className="row user-management-container d-flex align-items-center mb-4">
                            <div className="col-4">
                                <p>{user.displayName}</p>
                            </div>
                            <div className="col-4">
                                {user.type == "leader" ? (
                                    <Button
                                        variant="outline-warning"
                                        onClick={this.changeUserRole.bind(this, user.id, user.type, user.displayName)}
                                    >
                                        Remove admin privileges
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outline-success"
                                        onClick={this.changeUserRole.bind(this, user.id, user.type, user.displayName)}
                                    >
                                        Make admin
                                    </Button>
                                )}
                            </div>
                            <div className="col-4">
                                <Button
                                    variant="outline-danger"
                                    onClick={this.deleteUserFromSpace.bind(this, user.id, user.displayName)}
                                >
                                    Remove from Space
                                </Button>{" "}
                            </div>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button
                        className="orange-btn cool-btn active-button"
                        onClick={() => this.setState({ isShowingManagement: false })}
                    >
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderSpacesList() {
        const { space, spaces, user, isSpaceManager } = this.state

        // show different spaces
        const spacesList = []
        for (let i = 0; i < spaces.length; i++) {
            const currentSpace = spaces[i]
            const spaceId = currentSpace.id

            const isCurrentSpace = spaceId === space.id

            const deleteIcon = isSpaceManager ? (
                <div className="lounge-delete-icon" onClick={this.deleteLounge.bind(this, spaceId)}>
                    <p className="mb-0">
                        <FontAwesomeIcon icon={faTrash} />
                    </p>
                </div>
            ) : null

            const infoIcon = isCurrentSpace ? (
                <div className="lounge-delete-icon" onClick={this.toggleInfoView.bind(this)}>
                    <p className="mb-0">
                        <FontAwesomeIcon icon={faInfo} />
                    </p>
                </div>
            ) : null

            const onClick = !isCurrentSpace ? () => this.pushURL(`/space/${spaceId}`) : null

            spacesList.push(
                <div key={spaceId} className={`lounge-cell ${isCurrentSpace ? "selected-icon" : ""}`} onClick={onClick}>
                    {infoIcon}
                    <h2>{currentSpace.name[0]}</h2>
                </div>
            )
        }

        spacesList.push(
            <div key={"add-lounge"} className="lounge-cell add-icon" onClick={this.pushURL.bind(this, `/space/`)}>
                <h2>
                    <FontAwesomeIcon icon={faPlus} />
                </h2>
            </div>
        )

        return (
            <div className="main-lounge-container">
                <div className="lounge-list">{spacesList}</div>
            </div>
        )
    }

    renderAllWidgets() {
        const { allWidgets, widgets } = this.state

        const widgetList = []
        const enabledWidgets = Object.keys(widgets)

        for (var widgetKey in allWidgets) {
            const widget = allWidgets[widgetKey]
            const alreadyInPage = enabledWidgets.includes(widgetKey)

            widgetList.push(
                <div
                    key={widgetKey}
                    className={`lounge-cell widget-cell ${alreadyInPage ? "widget-cell-disabled" : ""}`}
                >
                    <p className="mb-0 dark-text">{widget.name}</p>
                </div>
            )
        }

        return (
            <div className="main-lounge-container">
                <div className="lounge-list">{widgetList}</div>
            </div>
        )
    }

    renderWidget(id, widgetData) {
        if (id === "room_view") {
            const { user, space, rooms, isSpaceManager } = this.state

            return (
                <RoomView
                    key={id}
                    users={space.users}
                    dimensions={widgetData.dimensions}
                    uid={user.info.uid}
                    rooms={rooms}
                    allowedRooms={10}
                    isSpaceManager={isSpaceManager}
                    createNewRoom={this.toggleRoomCreationModal.bind(this)}
                    enterRoomURL={this.enterRoomURL.bind(this)}
                />
            )
        } else if (id === "daily_quotes") {
            return <QuotesWidget dimensions={widgetData.dimensions} />
        }
    }

    renderEditWidget(widget) {
        const { allWidgets } = this.state

        const widgetId = widget.id

        const prettyWidgetName = allWidgets[widgetId] ? allWidgets[widgetId].name : ""
        return (
            <div key={widgetId} className={`widget-edit-view ${widget.dimensions}`}>
                <div className="d-flex f-1 align-items-start justify-content-center w-100">
                    <FontAwesomeIcon color="blue" size="lg" icon={faArrowUp} />
                </div>
                <div className="d-flex f-1 align-items-center justify-content-center w-100">
                    <FontAwesomeIcon color="blue" size="lg" icon={faArrowLeft} />
                    <h6 className="f-1 text-center">{prettyWidgetName}</h6>
                    <FontAwesomeIcon color="blue" size="lg" icon={faArrowRight} />
                </div>
                <div className="d-flex f-1 align-items-end justify-content-center w-100">
                    <FontAwesomeIcon color="blue" size="lg" icon={faArrowDown} />
                </div>
            </div>
        )
    }

    renderWidgets() {
        const { isEditingWidgets, widgets, widgetsArray } = this.state

        if (!widgets) return null

        const widgetKeys = Object.keys(widgets)
        const widgetList = []

        // continue showing widgets
        for (let i = 0; i < widgetsArray.length; i++) {
            const column = widgetsArray[i]
            for (let j = 0; j < column.length; j++) {
                const widgetId = widgetsArray[i][j]

                if (widgetKeys.includes(widgetId)) {
                    const widget = widgets[widgetId]

                    if (widget.dimensions_obj.gridColumnStart === j && widget.dimensions_obj.gridRowStart === i) {
                        if (isEditingWidgets) {
                            widgetList.push(this.renderEditWidget(widget))
                        } else {
                            widgetList.push(this.renderWidget(widgetId, widget))
                        }
                    }
                } else if (isEditingWidgets) {
                    widgetList.push(<div key={`${i}_${j}`} className="widget-empty-cell" />)
                }
            }
        }

        return widgetList
    }

    renderSpaceInfo() {
        const {
            user,
            space,
            rooms,
            chat,
            isShowingSettings,
            isShowingManagement,
            isShowingAnalytics,
            isEditingWidgets,
            isShowingInfo,
            isSpaceManager,
            notifications,
            isOutOfTime,
        } = this.state

        console.log("rooms", rooms)

        // chat window view
        const chatView = this.renderChatView()
        const loungeView = isEditingWidgets ? this.renderAllWidgets() : this.renderSpacesList()

        const spaceName = `${space.name}`

        const managingView = <LoungeManager isShowingInfo={isShowingInfo} company={space} />
        const widgets = this.renderWidgets()

        const subview = isShowingInfo || isOutOfTime ? managingView : widgets
        const defaultView = (
            <div className="main-view">
                {loungeView}
                <div className="content-holder">
                    <div className="inner-content-holder">
                        <h1 className="hero_text align-self-center ">{spaceName}</h1>
                        <RoomView
                            users={space.users}
                            dimensions={"gcs-1 gce-9 grs-2 gre-5"}
                            uid={user.info.uid}
                            rooms={rooms}
                            allowedRooms={10}
                            isSpaceManager={isSpaceManager}
                            createNewRoom={this.toggleRoomCreationModal.bind(this)}
                            enterRoomURL={this.enterRoomURL.bind(this)}
                        />
                    </div>
                </div>
            </div>
        )

        const { hasUnreadMessages } = notifications

        return (
            <div className="main-bg container-grid">
                {chatView}
                {isShowingAnalytics ? <Analytics facultyName={space.name} /> : defaultView}
                <Toolbar
                    // data
                    groupId={""}
                    codes={space.codes}
                    isRoomOrganizer={false}
                    databaseSessionExists={false}
                    // bools
                    isInRoom={false}
                    session={false}
                    toggleChat={this.toggleChat.bind(this)}
                    chat={chat}
                    settings={isShowingSettings}
                    manage={isShowingManagement}
                    info={isShowingInfo}
                    showCompanyCode={isShowingAnalytics}
                    startingCall={false}
                    isSpaceManager={isSpaceManager}
                    hasUnreadMessages={hasUnreadMessages}
                    isOutOfTime={isOutOfTime}
                    // functions
                    afterActivityMetrics={this.afterActivityMetrics.bind(this)}
                    toggleSettingsModal={this.toggleSettingsModal.bind(this)}
                    toggleAnalytics={this.toggleAnalyticsView.bind(this)}
                    toggleEditMode={this.toggleEditMode.bind(this)}
                    toggleManageModal={this.toggleManageModal.bind(this)}
                    toggleInfoView={this.toggleInfoView.bind(this)}
                    goHome={this.goHome.bind(this)}
                />
                {this.renderSettingsModal()}
                {this.renderCheckoutModal()}
                {this.renderRoomCreationModal()}
                {this.renderManageModal()}
            </div>
        )
    }

    renderData() {
        const { viewType } = this.state

        if (viewType === "default") {
            return this.renderSpaceInfo()
        } else if (viewType === "company_creation") {
            return <CreateALounge pushURL={this.pushURL.bind(this)} />
        }
    }

    render() {
        const { error, space } = this.state

        if (error) return this.renderError()
        else if (space) return this.renderData()
        else {
            return (
                <div className="main-wrapper main-bg align-items-center justify-content-center">
                    <h5 className="light-text text-center">Loading...</h5>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        notifications: state.notify,
    }
}

export default withRouter(connect(mapStateToProps)(CompanyPage))
