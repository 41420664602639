import React, { Component } from "react";

import firebase from "../../firebase";

import { Table, InputGroup, FormControl } from "react-bootstrap";

var database = firebase.database();
var isEqual = require("lodash.isequal");

class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: null,
      adminUsers: [],
      filteredUsers: [],
    };
  }

  async componentDidMount() {
    this.getValidDevUsers();
    this.getUsers();
  }

  async getValidDevUsers() {
    const users = await database.ref(`/admin_users/`).once("value");
    const adminUsers = Object.values(users.val());

    this.setState({ adminUsers });
  }

  async getUsers() {
    const { adminUsers } = this.state;

    const usersSnap = await database.ref(`/users/`).once("value");

    const usersData = Object.values(usersSnap.val()).filter((u) => {
      const isNewerThanNovFirst = parseInt(u.info.createdAt) > 1604188800000;
      const isUs = adminUsers.filter((au) => au.id === u.info.uid).length > 0;
      return isNewerThanNovFirst || isUs;
    });

    const users = Object.values(usersData).sort((a, b) => {
      return (
        a.info.displayName.toLowerCase() > b.info.displayName.toLowerCase()
      );
    });

    this.setState({ users: users });
    this.setState({ filteredUsers: users })
  }

  handleSearch = (e) => {
    const { users } = this.state
    const results = users.filter((data) => JSON.stringify(data).toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    this.setState({ filteredUsers: results })
  }

  convertTime(unixTime) {
    const dateRaw = new Date(0)
    dateRaw.setUTCMilliseconds(unixTime)
    const date = dateRaw.toString()
    return date
  }

  render() {
    const users = this.state.filteredUsers;

    if (users) {
      return (
        <>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-default">Search Users</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              onChange={e => this.handleSearch(e)}
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
            />
          </InputGroup>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>User Id</th>
                <th data-sortable='true' data-sort-order='desc' >Last Logged In</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <>
                  <tr>
                    <td>{user.info.displayName}</td>
                    <td><a href={`mailto:${user.info.providerData[0].email}`}>{user.info.providerData[0].email}</a></td>
                    <td><a href={`https://console.firebase.google.com/project/gatheronline-573d7/database/gatheronline-dev/data/users/${user.info.uid}`}>{user.info.uid}</a></td>
                    <td>{this.convertTime(user.info.lastLoginAt)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </>
      );
    } else return null;
  }
}

export default UsersTable;
