import React, { Component } from "react";

export class AnalyticsCardSingle extends Component {
  render() {
    return (
      <div className="AnalyticsCardSingle venue-holder col-4">
        <div className='single-card-top'>
          
        <h2>{this.props.points}</h2>
      
        <p>points</p>
        </div>
        <p>{this.props.category}</p>
      </div>
    );
  }
}

export default AnalyticsCardSingle;
