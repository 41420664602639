import React, { Component } from "react";

import firebase from "../../firebase";

import { Table, InputGroup, FormControl } from "react-bootstrap";

var database = firebase.database();
var isEqual = require("lodash.isequal");

class ActiveRoomsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeGroupa: [],
      filteredActiveGroups: [],
      companies: null
      
    };
  }

  async componentDidMount() {
    await this.getActiveRooms()
    await this.getCompanies()

  }

  async getActiveRooms() {

    const groupSnap = await database.ref(`/groups/`).once('value')
    const groups = Object.values(groupSnap.val())
    const activeGroups = groups.filter(g => g.tokSession)

    this.setState({ activeGroups })
    this.setState({ filteredActiveGroups: activeGroups })


  }

  async getCompanies() {
    const companiesRaw = await database.ref(`/companies`).once("value")
    const companies = companiesRaw.val()
    this.setState({ companies })
  }

  handleSearch = (e) => {
    const { companies } = this.state
    const results = companies.filter((data) =>  JSON.stringify(data).toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    this.setState ({ filteredCompanies: results })
  }

 getCompanyName(id) {
    const { companies } = this.state
    let name = null
    try{
       name = companies[id].name

    } catch (e){
      name = 'Deleted Company'
    }

    return name
 }

  convertTime(dateString) {
    const dateRaw = new Date(dateString)
    const date = dateRaw.toString()
    return date
  }

  getCallLength(dateString) {
    const currentDate = new Date
    const dateStarted = new Date(dateString)
    const difference = currentDate - dateStarted    
    var diffInHours = difference/1000/60/60;

    return `${Math.round(diffInHours)} hours`
  }

  render() {
    const  activeGroups = this.state.filteredActiveGroups;

    if (activeGroups) {
      return (
        <>
        <InputGroup className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Text id="inputGroup-sizing-default">Search Active Rooms</InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl
    //onChange={e => this.handleSearch(e)}
      aria-label="Default"
      aria-describedby="inputGroup-sizing-default"
    />
  </InputGroup>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Room Name</th>
                <th>Room Id</th>
                <th>Parent Company</th>
                <th>Time Started</th>
                <th>Length</th>
              </tr>
            </thead>
            <tbody>
            {activeGroups.map((group) => (
                
               
                <>
                  <tr>
                    <td>{group.name}</td>
                    <td><a href={`https://console.firebase.google.com/project/gatheronline-573d7/database/gatheronline-dev/data/groups~2F${group.id}`}>{group.id}</a></td>
                    <td><a href={`https://console.firebase.google.com/project/gatheronline-573d7/database/gatheronline-dev/data/~2Fcompanies~2F${group.parentCompanyId}`}>{this.getCompanyName(group.parentCompanyId)}</a></td>
                    <td>{this.convertTime(group.tokSession.timeStarted)}</td>
                    <td>{this.getCallLength(group.tokSession.timeStarted)}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </>
      );
    } else return null;
  }
}

export default ActiveRoomsTable;
