import React, { Component } from "react"
import ReactGA from "react-ga"
import { connect } from "react-redux"
import * as moment from "moment"
import { withRouter } from "react-router-dom"
import OT from "@opentok/client"

import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMicrophone, faVideo } from "@fortawesome/free-solid-svg-icons"

import "../assets/css/LandingPage.css"
import "../assets/css/RoomPage.css"
import UserModel from "../models/UserModel"
import firebase from "../firebase"
import Toolbar from "./Toolbar"
import Analytics from "./Analytics"
import CreateALounge from "./CreateALounge"
// import Chat from "./Chat"
import LoungeManager from "./LoungeManager"
import { SliderRail, Handle, Track, Tick } from "./SliderComponents"

import { logoutUser, listenToRoom, detachRoomListener, detachListener } from "../redux/actions"
import pino from "./pino"

var database = firebase.database()
var isEqual = require("lodash.isequal")

const defaultValues = [40, 44000]
let activity = {}

class RoomPage extends Component {
    constructor(props) {
        super(props)

        const initialGrid = this.generateGrid()

        this.audioContext = new window.AudioContext()

        this.biquadFilter = null
        this.state = {
            roomCode: "",
            currentRoom: null,
            user: {},
            error: null,
            errorDetails: null,

            forcedViewPage: "",
            chat: false,

            roomUrl: "",
            startingCall: false,
            endedCall: false,
            currentAudioDevice: "",
            currentVideoDevice: "",
            audioDevices: [],
            videoDevices: [],
            newFacultyInfo: {
                name: "",
            },
            isHost: true,
            activityText: "",

            subscribers: [],
            session: null,
            isOutOfTime: false,

            // toolbar buttons
            isShowingAnalytics: false,
            isShowingSettings: false,

            notifications: {
                hasUnreadMessages: false,
            },

            grid: initialGrid,
            availableGrid: initialGrid,
            volumeGrid: initialGrid,
            layout: {},
            stereoIndexes: {
                left: [],
                right: [],
                center: [],
            },
            initialIndex: null,
            userIndexOn2DGrid: 0,
            layoutIndexDict: {},
            freq: 1,
            quality: 1,
            maxValue: 0,
            domain: defaultValues,
            defaultValues: defaultValues,
            values: defaultValues.slice(),
            update: defaultValues.slice(),
            toUnsub: [],
            toSub: [],

            helperCode: "",
            isShowingHelperCode: false,

            // space
            space: null,
            spaceUrl: "",

            // opentok
            streams: [],
            audio: true,
            video: true,
            inPersonUsers: [],
            remoteUsers: [],
            currentUserState: "", // REMOTE, IN_PERSON

            lastUpdatedMic: 0,
        }
    }

    async componentDidMount() {
        window.addEventListener("beforeunload", this.onBeforeUnload.bind(this))

        const { match } = this.props
        const {
            params: { roomCode },
        } = match
        this.setState({ roomCode })

        await this.handleRoomRedirect(roomCode)
        const index = this.getNextAvailableGridIndex()
        this.setGridIndex(true, index)
    }

    async componentWillUnmount() {
        document.addEventListener("keydown", this.handleKeyPress, false)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var shouldUpdateUser = !isEqual(nextProps.user, prevState.user)
        var shouldUpdateRoom = !isEqual(nextProps.currentRoom, prevState.currentRoom)
        var shouldUpdateNotification = !isEqual(nextProps.notifications, prevState.notifications)

        if (shouldUpdateUser) {
            return { user: nextProps.user }
        } else if (shouldUpdateRoom) {
            return { currentRoom: nextProps.currentRoom }
        } else if (shouldUpdateNotification) {
            return { notifications: nextProps.notifications }
        } else {
            return null
        }
    }

    async componentDidUpdate(_prevProps, prevState) {
        if (!isEqual(this.props.user, prevState.user)) {
            if (!prevState.user && !this.state.spaceCode) {
                await this.handleRoomRedirect(this.state.roomCode)
                await this.getAllUserLounges()
            }
            this.setState({ user: this.props.user })
        }

        if (!isEqual(this.props.currentRoom, prevState.currentRoom)) {
            const room = this.props.currentRoom
            if (room) this.updateStateWithRoom(prevState.currentRoom, room)
        }

        if (!isEqual(this.props.notifications, prevState.notifications)) {
            this.setState({ notifications: this.props.notifications })
        }
    }

    onBeforeUnload(e) {
        this.quickCleanUpCallSession()
    }

    async handleRoomRedirect(roomCode) {
        // validate room code
        // check parent space id from room code
        // validate spaceId from user companies
        // success

        // use user data (stored companies) and space code to handle url accordingly
        const { user } = this.props

        if (!user) return

        const account_info = user.account_info

        var userIsInSpace = false

        // if space code doesn't exist
        if (!roomCode) {
            // send to default space
            if (account_info.id) {
                this.goToLounge(account_info.id)
                return
            }
        }

        // fetch space info from id code
        const roomSnap = await database.ref(`/rooms/${roomCode}`).once("value")
        // get first matching code from database request

        if (!roomSnap.exists()) {
            this.setState({ error: "ROOM_DOES_NOT_EXIST" })
            return
        }

        const fetchedRoom = roomSnap.exists() ? roomSnap.val() : {}
        const parentSpaceId = fetchedRoom.parentSpaceId

        // check first if space is part of user 'spaces' dictionary
        const userSpaces = user.spaces ? Object.values(user.spaces) : []
        const filteredSpace = userSpaces.filter((c) => c === parentSpaceId)
        userIsInSpace = filteredSpace.length !== 0

        // fetch space info from id
        const spaceSnap = await database.ref(`/spaces/${parentSpaceId}`).once("value")
        // get first matching code from database request
        const isValidSpace = spaceSnap.exists()
        const fetchedSpace = isValidSpace ? spaceSnap.val() : null

        if (userIsInSpace && isValidSpace && fetchedSpace.id === parentSpaceId) {
            // if user has space in their 'companies' dictionary
            // and it matches fetchedSpace.id
            // get room code
            await this.getSpaceInformation(parentSpaceId)
            await this.getRoomInformation(roomCode)

            const { currentRoom } = this.state
            const uid = user.info.uid
            const code = this.state.space.id

            if (currentRoom.isPrivate) {
                if (!currentRoom.allowedUsers[uid]) {
                    alert("This is a private room you are not a part of. Contact your space admin for access.")
                    this.pushURL(`/space/${code}`)
                }
            }

            return
        } else if (!userIsInSpace && isValidSpace && fetchedSpace.id.length > 0) {
            // user doesn't have space but space is valid
            // prompt the code entering
            this.setState({ error: "NOT_IN_COMPANY", errorDetails: fetchedSpace })
        } else if (!isValidSpace) {
            // invalid space, show error
            this.setState({ error: "DOES_NOT_EXIST" })
        } else {
            // go back to where you came form
            this.pushURL(`/space`)
        }
    }

    async getSpaceInformation(spaceId) {
        const spaceSnap = await database.ref(`/spaces/${spaceId}/`).once("value")
        if (!spaceSnap.exists()) return

        const space = spaceSnap.val()
        this.setState({ space, spaceUrl: `/spaces/${spaceId}` }, () => {
            // this.checkIfDeathIsHere()
        })
    }

    async getRoomInformation(roomCode) {
        this.startListenerForRoom(roomCode)
        const room = await this.getRoom(roomCode)

        const who = room.who ? Object.keys(room.who) : []
        if (who.length === 8) {
            if (this.state.space && this.state.space.codes) {
                const spaceId = this.state.space.codes.id
                this.pushURL(`/space/${spaceId}`)
            } else {
                this.pushURL("/space")
            }
            return
        }

        this.addUserToRoom()
        this.setRoomHost(room)
        if (!room.who && room.callSession) {
            this.removeEntireSession()
        }
    }

    async getRoom(roomId) {
        return new Promise(async (resolve, reject) => {
            if (roomId.length > 0) {
                const roomSnap = await database.ref(`/rooms/${roomId}`).once("value")

                if (roomSnap.exists()) {
                    const room = roomSnap.val()
                    const roomUrl = `/rooms/${room.id}`

                    this.setState({ roomUrl })
                    resolve(room)
                } else {
                    reject()
                }
            }
        })
    }

    goToLounge(code) {
        const { history } = this.props
        history.push(`/space/${code}`)
    }

    checkIfDeathIsHere() {
        const { space } = this.state

        const spaceSubscriptionEnd = space.subscription.period
        const currentTime = moment().unix()
        if (spaceSubscriptionEnd >= currentTime) {
            this.setState({ isOutOfTime: true })
        }
    }

    isValidType(obj) {
        return typeof obj !== "undefined"
    }

    isValidActivityNode(currentActivity) {
        if (!this.isValidType(currentActivity)) return false

        return (
            this.isValidType(currentActivity) &&
            this.isValidType(currentActivity.activityDetails) &&
            this.isValidType(currentActivity.didEndGathering) &&
            this.isValidType(currentActivity.didStartGathering) &&
            this.isValidType(currentActivity.endDate) &&
            this.isValidType(currentActivity.info) &&
            this.isValidType(currentActivity.queueInfo) &&
            this.isValidType(currentActivity.startDate)
        )
    }

    updateStateWithRoom(prevRoom, room) {
        let layout = {}
        if (room.callSession.layout) {
            layout = room.callSession.layout
        }

        const oldLayout = prevRoom ? (prevRoom.callSession.layout ? prevRoom.callSession.layout : {}) : {}
        const uid = this.props.user.info.uid

        const oldWho = prevRoom ? (prevRoom.who ? prevRoom.who : {}) : {}
        const who = room.who ? Object.values(room.who) : []

        const inPersonUsers = []
        const remoteUsers = []
        let currentUserState = ""

        who.map((u) => {
            const type = u.type

            if (u.uid === uid) currentUserState = u.type

            if (type === "IN_PERSON") inPersonUsers.push(u.uid)
            else remoteUsers.push(u.uid)
        })

        const availableGrid = this.getAvailableGrid(layout)
        const currentRoom = room

        let stereoIndexes = this.state.stereoIndexes
        let userIndexOn2DGrid = this.state.userIndexOn2DGrid
        let layoutIndexDict = this.state.layoutIndexDict

        // update stereo panning indexes only if current user changes layout index
        if (oldLayout[uid] !== layout[uid]) {
            let info = this.updatePanIndexes(layout)
            stereoIndexes = info.stereoIndexes
            userIndexOn2DGrid = info.userIndexOn2DGrid
            layoutIndexDict = info.layoutIndexDict
        }

        const isRoomHost = currentRoom ? currentRoom.host === uid : false

        this.setState(
            {
                currentRoom,
                layout,
                inPersonUsers,
                remoteUsers,
                currentUserState,
                availableGrid,
                stereoIndexes,
                userIndexOn2DGrid,
                layoutIndexDict,
                isRoomHost,
            },
            () => {
                this.updateLocalHTMLVariables()

                // run every layout change
                if (!isEqual(oldLayout, layout)) {
                    this.getVolumeLevelForIndex(layout[uid])
                }

                this.parseLayout(oldLayout)

                const { startingCall, endedCall } = this.state
                const isProd = false // window.location.hostname !== "localhost"
                const canStartCallAsHost = (!room.host || room.host === uid) && !startingCall && isProd
                const canJoinCall = room.host !== uid && room.callSession.id.length > 0 && !startingCall && isProd
                const isInActiveSession = this.sessionHelper ? (this.sessionHelper.connection ? true : false) : false
                const hasProperRoom = room.id.length > 0
                if ((canStartCallAsHost || canJoinCall) && !isInActiveSession && hasProperRoom && !endedCall) {
                    this.startTheCall()
                }

                // if (isInActiveSession && inPersonUsers.includes(uid)) {
                //     if (inPersonUsers[0] === uid) {
                //         // if first inperson user, then use microphone
                //         this.publisher.publishAudio(true)
                //         this.setState({ audio: true })
                //     } else {
                //         // if not first inperson user, then mute
                //         this.publisher.publishAudio(false)
                //         this.setState({ audio: false })
                //     }
                // }
            }
        )
    }

    setChoosingView(viewType) {
        this.setState({ isCreatingRoom: true, forcedViewPage: viewType })
    }

    generateHelperCode() {
        const { roomCode, user } = this.state

        const helperCode = Math.random().toString(16).substr(2, 8).toUpperCase()
        const uid = user.info.uid

        database.ref(`/ot_codes/${helperCode}/`).set({
            userId: uid,
            roomId: roomCode,
        })

        this.setState({ helperCode })
        return helperCode
    }

    getHelperCode() {
        const { helperCode } = this.state

        if (helperCode.length === 0) {
            this.generateHelperCode()
        }

        this.toggleHelperCodeModal()
    }

    startListenerForRoom(id) {
        const { dispatch } = this.props
        dispatch(listenToRoom(id))
    }

    setRoomHost(room) {
        const user = this.props.user.info
        const { roomUrl } = this.state

        if (!room.host) {
            database.ref(`${roomUrl}/host/`).set(user.uid)
        }
    }

    addUserToRoom() {
        const user = this.props.user.info
        var email = user.email
        if (!user.email) email = user.providerData[0].email

        const userInfo = {
            uid: user.uid,
            name: user.displayName,
            email: email,
            type: "",
        }

        database.ref(`${this.state.roomUrl}/who/${user.uid}`).set(userInfo)
    }

    parseLayout(oldLayout) {
        // move child from dom to dom
        const { layout } = this.state

        for (var id in layout) {
            const oldIndex = oldLayout[id]
            const newIndex = layout[id]

            const oldIndexExists = typeof oldIndex !== "undefined"
            const hasChangedIndex = oldIndex !== newIndex

            if (oldIndexExists && hasChangedIndex) {
                var oldParent = document.getElementById(`${oldIndex}_video_content`)
                var newParent = document.getElementById(`${newIndex}_video_content`)

                while (oldParent.hasChildNodes()) {
                    newParent.appendChild(oldParent.firstChild)
                }
            }
        }
    }

    updateLocalHTMLVariables() {
        const height = window.innerHeight - 75

        document.documentElement.style.setProperty(`--viewHeight`, `${height}px`)
        document.documentElement.style.setProperty(
            `--gridHeight`,
            "calc(var(--viewHeight) / var(--rowNum) - 2rem * (var(--rowNum) - 1) / var(--rowNum))"
        )
    }

    findObjInMultiArray(arr, k) {
        for (let i = 0; i < arr.length; i++) {
            var index = arr[i].indexOf(k)
            if (index > -1) return index
        }
    }

    updatePanIndexes(layout) {
        const { user } = this.state

        const currentUserLayoutIndex = layout[user.info.uid]

        const gridRows = [
            [0, 1, 2, 3, 4, 5, 6, 7],
            [8, 9, 10, 11, 12, 13, 14, 15],
            [16, 17, 18, 19, 20, 21, 22, 23],
            [24, 25, 26, 27, 28, 29, 30, 31],
            [32, 33, 34, 35, 36, 37, 38, 39],
        ]

        var userIndex = this.findObjInMultiArray(gridRows, currentUserLayoutIndex)

        const left = []
        const right = []
        const center = []
        const layoutIndexDict = {}

        for (let k = 0; k < gridRows.length; k++) {
            const row = gridRows[k]

            var leftToUpdate = row.slice(0, userIndex)
            var rightToUpdate = row.slice(userIndex + 1)
            var centerToUpdate = row[userIndex]

            const leftLength = leftToUpdate.length
            const rightLength = rightToUpdate.length

            leftToUpdate.forEach((item, index) => {
                layoutIndexDict[item] = {
                    index,
                    length: leftLength,
                    type: "LEFT",
                }
            })

            rightToUpdate.forEach((item, index) => {
                layoutIndexDict[item] = {
                    index,
                    length: rightLength,
                    type: "RIGHT",
                }
            })

            layoutIndexDict[centerToUpdate] = {
                userIndex,
                length: 0,
                type: "CENTER",
            }

            left.push(leftToUpdate)
            right.push(rightToUpdate)
            center.push(centerToUpdate)
        }

        const stereoIndexes = {
            left,
            right,
            center,
        }

        return {
            stereoIndexes,
            layoutIndexDict,
            userIndexOn2DGrid: userIndex,
        }
    }

    logUserOut() {
        let loggingOut = window.confirm("Are you sure you want to log out?")

        if (!loggingOut) {
            return
        } else {
            const { dispatch } = this.props

            this.leave()

            dispatch(detachListener(this.state.user.info.uid))
            dispatch(logoutUser())
        }
    }

    quickCleanUpCallSession() {
        const { roomUrl, user } = this.state
        const userId = user.info.uid

        database.ref(`${roomUrl}/who/${userId}`).remove()
        database.ref(`${roomUrl}/callSession/layout/${userId}`).remove()
    }

    leave() {
        this.leaveSession()
        this.quickCleanUpCallSession()
    }

    removeUserFromRoom(userId, roomId) {
        return new Promise(async (resolve, reject) => {
            const data = { userId, roomId }
            try {
                await fetch("https://us-central1-between-87295.cloudfunctions.net/api/removeUserFromRoom", {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })

                resolve(true)
            } catch (error) {
                console.log(error)
            }
        })
    }

    /* Call Helpers */

    chooseTypeAndStartCall(type) {
        const { currentRoom, user } = this.state

        const userId = user.info.uid

        database.ref(`/rooms/${currentRoom.id}/who/${userId}/type`).set(type)
        this.startTheCall(type)
    }

    setPanningForIndex(mediaStream, layoutInfo, layoutIndex) {
        // get subscriber stream
        const panningValue = this.getPanningValue(layoutInfo)
        console.log(`panning user at layoutIndex ${layoutIndex} towards ${layoutInfo.type} to ${panningValue}`)

        if (!mediaStream) return

        const mediaTrack = mediaStream.getAudioTracks()[0]

        var audioContext = new AudioContext()
        var panner = new StereoPannerNode(audioContext, {
            pan: panningValue,
        })
        var audioSource = audioContext.createMediaStreamSource(new MediaStream([mediaTrack]))
        var audioDestination = audioContext.createMediaStreamDestination()
        // stereo panning
        audioSource.connect(panner)
        panner.connect(audioDestination)

        mediaStream.removeTrack(mediaTrack)
        mediaStream.addTrack(audioDestination.stream.getAudioTracks()[0])
    }

    adjustVolumeForGrid() {
        const { streams, layout, volumeGrid, inPersonUsers, currentUserState, toSub, toUnsub } = this.state

        for (let i = 0; i < streams.length; i++) {
            const sub = streams[i]
            const userId = sub.getUserId()

            const layoutIndex = layout[userId]

            let volumeLevel = volumeGrid[layoutIndex] * 100

            if (inPersonUsers.includes(userId) && currentUserState === "IN_PERSON") volumeLevel = 0
            else if (toSub.includes(userId)) volumeLevel = 100
            else if (toUnsub.includes(userId)) volumeLevel = 0

            // set volume here
            sub.getStreamManager().setAudioVolume(volumeLevel)

            // set panning values here
            // console.log(`setting panning value for ${userId} @ layoutIndex ${layoutIndex}`)
            // const mediaStream = document.getElementById(`${layoutIndex}_video_content`).querySelector("video").srcObject
            // this.setPanningForIndex(null, layoutIndexDict[layoutIndex], layoutIndex)
        }
    }

    getVolumeLevelForIndex(userIndex) {
        const { userIndexOn2DGrid, layout, inPersonUsers, user } = this.state
        const grid2D = [
            [0, 1, 2, 3, 4, 5, 6, 7],
            [8, 9, 10, 11, 12, 13, 14, 15],
            [16, 17, 18, 19, 20, 21, 22, 23],
            [24, 25, 26, 27, 28, 29, 30, 31],
            [32, 33, 34, 35, 36, 37, 38, 39],
        ]
        const grid = this.generateGrid()
        const tre = [6, 7, 14, 15] // top right edge
        const ble = [24, 25, 32, 33] // bottom left edge

        var type = ""
        if (tre.includes(userIndex)) type = "TRE"
        else if (ble.includes(userIndex)) type = "BLE"

        for (let i = 0; i < grid.length; i++) {
            if (this.isPrimaryVolume(userIndex, i, type, userIndexOn2DGrid)) {
                grid[i] = 1.0
            } else if (this.isSecondaryVolume(userIndex, i, type, userIndexOn2DGrid)) {
                grid[i] = 0.6
            } else {
                grid[i] = 0.05
            }
        }

        // remote people set volume of inperson stream based on layout
        // first check: remote check to see if inPersonUsers[0] is in primary or secondary
        // second check: if not in primary or secondary, then check for other inPersonUsers in primary or secondary
        // third check: if multiple inPersonUsers in primary or secondary, choose [0] from that smaller array and mute from inPersonUsers[0],
        let toUnsub = []
        let toSub = []

        for (var uid in layout) {
            if (uid !== user.info.uid) {
                if (inPersonUsers.includes(uid)) {
                    const layoutIndex = layout[uid]

                    const isPrimary = this.isPrimaryVolume(userIndex, layoutIndex, type, userIndexOn2DGrid)
                    const isSecondary = this.isSecondaryVolume(userIndex, layoutIndex, type, userIndexOn2DGrid)

                    if (inPersonUsers[0] === uid && (isPrimary || isSecondary)) {
                        // check to see if inPersonUsers[0] is in primary or secondary, add to toSub
                        toSub.push(uid)
                    } else if (inPersonUsers[0] === uid && (!isPrimary || !isSecondary)) {
                        // check to see if inPersonUsers[0] is not in primary or secondary, add to toUnsub
                        toUnsub.push(uid)
                    } else if (isPrimary || isSecondary) {
                        // check if is primary or secondary, add to toSub
                        toSub.push(uid)
                    } else if (!isPrimary || !isSecondary) {
                        // check if not primary or secondary, add to toUnsub
                        toUnsub.push(uid)
                    }
                }
            }
        }

        const deleted = toSub.splice(1, toSub.length) // only be subcribed to one inperson at a time
        toUnsub = toUnsub.concat(deleted)

        this.setState({ volumeGrid: grid, toUnsub, toSub }, () => {
            this.adjustVolumeForGrid()
        })
    }

    isPrimaryVolume(mainIndex, subIndex, type, userIndexOn2DGrid) {
        const n = mainIndex

        if (userIndexOn2DGrid + 1 > 7) {
            return (
                subIndex === n ||
                subIndex === n - 1 ||
                subIndex === n - 1 - 8 ||
                subIndex === n - 1 + 8 ||
                subIndex === n + 8 ||
                subIndex === n - 8
            )
        } else if (userIndexOn2DGrid - 1 < 0) {
            return (
                subIndex === n ||
                subIndex === n + 8 ||
                subIndex === n - 8 ||
                subIndex === n + 1 ||
                subIndex === n + 1 - 8 ||
                subIndex === n + 1 + 8
            )
        }

        return (
            subIndex === n ||
            subIndex === n - 1 ||
            subIndex === n - 1 - 8 ||
            subIndex === n - 1 + 8 ||
            subIndex === n + 8 ||
            subIndex === n - 8 ||
            subIndex === n + 1 ||
            subIndex === n + 1 - 8 ||
            subIndex === n + 1 + 8
        )
    }

    isSecondaryVolume(mainIndex, subIndex, type, userIndexOn2DGrid) {
        const n = mainIndex

        if (userIndexOn2DGrid + 1 > 7) {
            if (type === "TRE") {
                return (
                    subIndex === n - 2 ||
                    subIndex === n - 2 - 16 ||
                    subIndex === n - 2 - 8 ||
                    subIndex === n - 2 + 16 ||
                    subIndex === n - 2 + 8 ||
                    subIndex === n - 1 - 16 ||
                    subIndex === n - 1 + 16 ||
                    subIndex === n + 16 ||
                    subIndex === n - 16
                )
            }
            return (
                subIndex === n - 2 ||
                subIndex === n - 2 - 16 ||
                subIndex === n - 2 - 8 ||
                subIndex === n - 2 + 16 ||
                subIndex === n - 2 + 8 ||
                subIndex === n - 1 - 16 ||
                subIndex === n - 1 + 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 2 + 16
            )
        } else if (userIndexOn2DGrid + 2 > 7) {
            if (type === "TRE") {
                return (
                    subIndex === n - 2 ||
                    subIndex === n - 2 - 16 ||
                    subIndex === n - 2 - 8 ||
                    subIndex === n - 2 + 16 ||
                    subIndex === n - 2 + 8 ||
                    subIndex === n - 1 - 16 ||
                    subIndex === n - 1 + 16 ||
                    subIndex === n + 16 ||
                    subIndex === n - 16 ||
                    subIndex === n + 1 + 16
                )
            }
            return (
                subIndex === n - 2 ||
                subIndex === n - 2 - 16 ||
                subIndex === n - 2 - 8 ||
                subIndex === n - 2 + 16 ||
                subIndex === n - 2 + 8 ||
                subIndex === n - 1 - 16 ||
                subIndex === n - 1 + 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 1 - 16 ||
                subIndex === n + 2 + 16
            )
        } else if (userIndexOn2DGrid - 1 < 0) {
            if (type === "BLE") {
                return (
                    subIndex === n + 16 ||
                    subIndex === n - 16 ||
                    subIndex === n + 1 + 16 ||
                    subIndex === n + 1 - 16 ||
                    subIndex === n + 2 + 8 ||
                    subIndex === n + 2 + 16 ||
                    subIndex === n + 2 - 8 ||
                    subIndex === n + 2 - 16 ||
                    subIndex === n + 2
                )
            }
            return (
                subIndex === n - 2 - 16 ||
                subIndex === n - 1 - 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 1 - 16 ||
                subIndex === n + 2 + 8 ||
                subIndex === n + 2 + 16 ||
                subIndex === n + 2 - 8 ||
                subIndex === n + 2 - 16 ||
                subIndex === n + 2
            )
        } else if (userIndexOn2DGrid - 2 < 0) {
            if (type === "BLE") {
                return (
                    subIndex === n - 1 - 16 ||
                    subIndex === n - 1 + 16 ||
                    subIndex === n + 16 ||
                    subIndex === n - 16 ||
                    subIndex === n + 1 + 16 ||
                    subIndex === n + 1 - 16 ||
                    subIndex === n + 2 + 8 ||
                    subIndex === n + 2 + 16 ||
                    subIndex === n + 2 - 8 ||
                    subIndex === n + 2 - 16 ||
                    subIndex === n + 2
                )
            }
            return (
                subIndex === n - 2 - 16 ||
                subIndex === n - 1 - 16 ||
                subIndex === n - 1 + 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 1 - 16 ||
                subIndex === n + 2 + 8 ||
                subIndex === n + 2 + 16 ||
                subIndex === n + 2 - 8 ||
                subIndex === n + 2 - 16 ||
                subIndex === n + 2
            )
        } else {
            return (
                subIndex === n - 2 ||
                subIndex === n - 2 - 16 ||
                subIndex === n - 2 - 8 ||
                subIndex === n - 2 + 16 ||
                subIndex === n - 2 + 8 ||
                subIndex === n - 1 - 16 ||
                subIndex === n - 1 + 16 ||
                subIndex === n + 16 ||
                subIndex === n - 16 ||
                subIndex === n + 1 + 16 ||
                subIndex === n + 1 - 16 ||
                subIndex === n + 2 + 8 ||
                subIndex === n + 2 + 16 ||
                subIndex === n + 2 - 8 ||
                subIndex === n + 2 - 16 ||
                subIndex === n + 2
            )
        }
    }

    findIndexIn2DArray(layoutIndex, array, isLeft) {
        var userIndex = null
        var i = 0
        var j = 0

        while (!userIndex && i < array.length) {
            const row = array[i]
            j = isLeft ? row.length - 1 : 0

            if (isLeft) {
                while (j >= 0 && !userIndex) {
                    if (row[j] === layoutIndex) userIndex = j
                    j--
                }
            } else {
                while (j < row.length) {
                    if (row[j] === layoutIndex) userIndex = j
                    j++
                }
            }
            i++
        }

        return userIndex
    }

    getPanningIndex(subLayoutIndex) {
        const { stereoIndexes } = this.state

        const leftIndex = this.findIndexIn2DArray(subLayoutIndex, stereoIndexes.left, true)
        const rightIndex = this.findIndexIn2DArray(subLayoutIndex, stereoIndexes.right, false)

        let type = ""
        if (!leftIndex && !rightIndex) type = "CENTER"
        else if (rightIndex) type = "RIGHT"
        else if (leftIndex) type = "LEFT"

        return {
            type,
            index: type === "LEFT" ? leftIndex : rightIndex,
            length: type === "LEFT" ? stereoIndexes.left[0].length : stereoIndexes.right[0].length,
        }
    }

    getPanningValue(layoutInfo) {
        let panningValue = 0

        if (layoutInfo.type === "LEFT") {
            const indexOfLayout = layoutInfo.index
            const evenValue = 1 / layoutInfo.length

            panningValue = evenValue * (indexOfLayout + 1) * -1
        } else if (layoutInfo.type === "RIGHT") {
            const indexOfLayout = layoutInfo.index
            const evenValue = 1 / layoutInfo.length

            panningValue = evenValue * (indexOfLayout + 1)
        }

        return panningValue
    }

    async parseMicData() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
            this.setMicrophoneThreshold(stream)
        } catch (error) {
            this.microphoneThresholdError(error)
        }
    }

    setMicrophoneThreshold(mediaStream) {
        if (!mediaStream) return

        const { quality } = this.state

        var source = this.audioContext.createMediaStreamSource(mediaStream)

        var lowpass = this.audioContext.createBiquadFilter()
        var highpass = this.audioContext.createBiquadFilter()

        var minValue = 40
        var maxValue = this.audioContext.sampleRate / 2

        lowpass.type = "lowpass"
        lowpass.frequency.value = maxValue
        lowpass.Q.value = quality * 30

        highpass.type = "highpass"
        highpass.frequency.value = minValue
        highpass.Q.value = quality * 30

        source.connect(lowpass)
        lowpass.connect(highpass)
        highpass.connect(this.audioContext.destination)

        this.lowpass = lowpass
        this.highpass = highpass

        const values = [minValue, maxValue]
        this.setState({
            maxValue,
            domain: values,
            defaultValues: values,
            values: values.slice(),
            update: values.slice(),
        })
    }

    onUpdate = (update) => {
        // console.log("onUpdate", update)
        // this.setState({ update })
    }

    onChange = (values) => {
        this.setState({ values })

        this.lowpass.frequency.value = values[1]
        this.highpass.frequency.value = values[0]
    }

    microphoneThresholdError(error) {
        console.log("error getting microphone", error)
    }

    // TODO
    // fix state update with currentUserState, just put in param for now
    async startTheCall(currentUserState) {
        // if (!databaseSessionExists) return

        try {
            this.setState({ startingCall: true }, async () => {
                await this.startOTSession(currentUserState)
                this.getDevices()
                // await this.parseMicData()
            })

            ReactGA.event({
                category: "Call",
                action: `Joined/Started A Call`,
            })
        } catch (error) {
            alert(error)
            ReactGA.ga("send", "exception", {
                exDescription: JSON.stringify(error.message),
                exFatal: true,
            })
        }
    }

    async startOTSession(currentUserState) {
        const { currentRoom, user, isRoomHost, initialIndex } = this.state
        let userId = user.info.uid
        let sessionId = currentRoom.callSession.id
        let token = ""

        if (sessionId.length > 0) {
            // session already exists, create token from sessionId
            token = await this.createOTToken(sessionId, userId)
        } else {
            sessionId = await this.createOTSession(currentRoom.id)
            token = await this.createOTToken(sessionId, userId)
        }

        this.sessionHelper = OT.initSession("47252744", sessionId)

        // subscribe to created streams
        this.subscribeToStreamCreatedOT()
        this.subscribeToStreamDestroyedOT()



        this.publisher = OT.initPublisher(
            `${initialIndex}_video_content`,
            {
                insertMode: "append",
                width: "100%",
                height: "100%",
            },
            this.handleError
        )

        console.log("isRoomHost", isRoomHost)
        if (isRoomHost && currentUserState === "IN_PERSON") {
            this.publisher.on("audioLevelUpdated", (event) => {
                this.handleAudioLevel(event.audioLevel, userId)
            })
        }

        if (currentUserState === "REMOTE") {
            console.log("is a remoteUser, startListenerForPirmaryMic")
            this.startListenerForPirmaryMic()
        }

        // Connect to the session
        this.sessionHelper.connect(token, (error) => {
            // If the connection is successful, initialize a publisher and publish to the session
            if (error) {
                this.handleError(error)
            } else {
                this.sessionHelper.publish(this.publisher, this.handleError)
                document.addEventListener("keydown", this.handleKeyPress.bind(this), false)
                this.setState({ startingCall: false })
            }
        })
    }

    handleAudioLevel(audioLevel, uid) {
        let { lastUpdatedMic, layout } = this.state
        // incorporate layout into dictating which individual
        // remote user should be subscribed to which inperson user
        // could be all same or all different
        // for current implementation

        const BASE_AUDIO_LEVEL = 0.2 // initial audio threshold to count as talking
        const BASE_ACTIVE_TIME = 2000 // 2 second minimum active time
        const BASE_INACTIVE_TIME = 3000 // 3 second minimum active time
        const BASE_UPDATE_TIME = 5000 // 5 second minimum update time

        const now = Date.now()

        const userInfo = activity[uid]

        if (audioLevel > BASE_AUDIO_LEVEL) {
            console.log("audioLevel > BASE_AUDIO_LEVEL")
            if (!userInfo) {
                // starting talking for the FIRST TIME IN FOREVER
                activity[uid] = {
                    uid,
                    timestamp: now,
                    audioLevel: 0,
                    movingAvg: 0,
                    active: false,
                }
            } else if (userInfo["active"]) {
                // if active and still talking update audio level
                userInfo["timestamp"] = now
                userInfo["audioLevel"] = this.getAdjustedAudioLevel(uid, audioLevel)
                // console.log(`${uid} is talking, setting timestamp and updating audio level to ${audioLevel}`)
            } else if (now - userInfo["timestamp"] > BASE_ACTIVE_TIME) {
                // detected audio activity for more than 2s
                userInfo["active"] = true
                // console.log(`${uid} is active`)
            }
        } else if (userInfo && now - userInfo["timestamp"] > BASE_INACTIVE_TIME) {
            // detected low audio activity for more than 3s
            if (userInfo["active"]) {
                userInfo["active"] = false
                userInfo["audioLevel"] = 0
                userInfo["movingAvg"] = 0
                // console.log(`${uid} is inactive`)
            }
        }

        let timeThresholdPassed = now - lastUpdatedMic > BASE_UPDATE_TIME
        let isNowActive = audioLevel > BASE_AUDIO_LEVEL && userInfo && now - userInfo["timestamp"] > BASE_ACTIVE_TIME
        let isNowInactive = userInfo && userInfo["active"] === true && now - userInfo["timestamp"] > BASE_INACTIVE_TIME

        // update temp debug ui
        let index = layout[uid]
        document.getElementById(`${index}_audio`).innerText = userInfo ? userInfo["audioLevel"] : 0

        if (lastUpdatedMic === 0 || timeThresholdPassed || isNowActive || isNowInactive) {
            // update mic every 5 seconds or when uid has gona active or inactive
            this.setActiveMic()
        }
    }

    getAdjustedAudioLevel(uid, current) {

        let avg = activity[uid]["movingAvg"]

        if (avg === null || avg <= current) {
            avg = current;
        } else {
            avg = 0.7 * avg + 0.3 * current;
        }

        // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
        var logLevel = (Math.log(avg) / Math.LN10) / 1.5 + 1;
        logLevel = Math.min(Math.max(logLevel, 0), 1);

        activity[uid]["movingAvg"] = avg

        // console.log('logLevel', logLevel)
        // console.log('avg', avg)

        return logLevel

    }

    setActiveMic() {
        const { roomCode } = this.state
        let generalMicInfo = {}
        let lastUpdatedMic = Date.now()

        let deactivate = []
        let activate = []

        for (var uid in activity) {
            var info = activity[uid]

            if (!info.active) {
                deactivate.push(uid)
            } else {
                // activate depending on something
                if (activate.length === 0) {
                    // only push the first active mic (for now)
                    activate.push(uid)
                } else {
                    // push other mics to deactivate (for now)
                    deactivate.push(uid)
                }
            }
        }

        // console.log("deactivate", deactivate)
        // console.log("activate", activate)

        if (activate[0]) {
            database.ref(`/rooms/${roomCode}/callSession/activeGeneralMic`).set(activate[0])
        }
        this.setState({ lastUpdatedMic })
    }

    startListenerForPirmaryMic() {
        const { roomCode } = this.state

        database.ref(`/rooms/${roomCode}/callSession/activeGeneralMic`).on("value", (snap) => {
            const { inPersonUsers } = this.state
            if (snap.exists()) {
                const uidToSub = snap.val()
                console.log("got new activeGeneralMic", uidToSub)
                const toSub = [uidToSub]
                const toUnsub = inPersonUsers.filter((p) => p !== uidToSub)

                this.setState({ toSub, toUnsub }, () => {
                    this.adjustVolumeForGrid()
                })
            }
        })
    }

    handleError(error) {
        if (error) {
            alert(error.message)
        }
    }

    subscribeToStreamCreatedOT() {
        this.sessionHelper.on("streamCreated", (event) => {
            const { layout, streams, isRoomHost, inPersonUsers } = this.state
            const client_uid = JSON.parse(event.stream.connection.data).userId

            const indexInGrid = layout[client_uid]

            let id = `${indexInGrid}_video_content`
            if (!indexInGrid) {
                const index = this.getNextAvailableGridIndex()
                id = `${index}_video_content`
            }

            const subscriber = this.sessionHelper.subscribe(
                event.stream,
                id,
                {
                    insertMode: "append",
                    width: "100%",
                    height: "100%",
                },
                this.handleError
            )

            if (isRoomHost && inPersonUsers.includes(client_uid)) {
                // change to only have listener for in-person users
                subscriber.on("audioLevelUpdated", (event) => {
                    this.handleAudioLevel(event.audioLevel, client_uid)
                })
            }

            const newUser = new UserModel()
            newUser.setStreamManager(subscriber)
            newUser.setConnectionId(event.stream.connection.connectionId)
            newUser.setType("remote")
            newUser.setNickname("name_here")
            newUser.setUserId(client_uid)
            streams.push(newUser)

            this.setState({ streams })
        })
    }

    subscribeToStreamDestroyedOT() {
        this.sessionHelper.on("streamDestroyed", (event) => {
            const { layout } = this.state

            const client_uid = JSON.parse(event.stream.connection.data).userId
            const indexInGrid = layout[client_uid]

            this.deleteSubscriberOT(client_uid)

            const vidContent = document.getElementById(`${indexInGrid}_video_content`)
            while (vidContent.hasChildNodes) {
                vidContent.removeChild(vidContent.firstChild)
            }
            event.preventDefault()
        })
    }

    deleteSubscriberOT(client_uid) {
        const remoteUsers = this.state.streams
        const userStreamIndex = remoteUsers.findIndex((user) => user.getUserId() === client_uid)
        if (userStreamIndex > -1) {
            remoteUsers.splice(userStreamIndex, 1)
            this.setState({
                streams: remoteUsers,
            })
        }
    }

    handleKeyPress(event) {
        // event.keycode
        event.preventDefault()

        switch (event.keyCode) {
            case 38:
                this.handleMovementUp()
                break
            case 40:
                this.handleMovementDown()
                break
            case 37:
                this.handleMovementLeft()
                break
            case 39:
                this.handleMovementRight()
                break
            case 77:
                this.micStatusChanged()
                break
            case 86:
                this.camStatusChanged()
                break
            default:
                break
        }
    }

    handleMovementUp() {
        const { layout, user, availableGrid } = this.state
        const currentIndex = layout[user.info.uid]

        const te = [0, 1, 2, 3, 4, 5, 6, 7]

        if (te.includes(currentIndex)) return

        let indexes = []
        let index = currentIndex
        while (!te.includes(index)) {
            const nextIndex = index - 8 // above current index
            if (availableGrid.includes(nextIndex)) indexes.splice(0, 0, nextIndex)
            index = nextIndex
        }

        indexes.push(currentIndex)
        const newIndex = indexes.indexOf(currentIndex) - 1
        const indexToSet = indexes[newIndex]
        this.setGridIndex(false, indexToSet)
    }

    handleMovementDown() {
        const { layout, user, availableGrid } = this.state
        const currentIndex = layout[user.info.uid]

        const be = [32, 33, 34, 35, 36, 37, 38, 39]

        if (be.includes(currentIndex)) return

        let indexes = []
        let index = currentIndex
        while (!be.includes(index)) {
            const nextIndex = index + 8 // below current index
            if (availableGrid.includes(nextIndex)) indexes.push(nextIndex)
            index = nextIndex
        }

        indexes.splice(0, 0, currentIndex)
        const newIndex = indexes.indexOf(currentIndex) + 1
        const indexToSet = indexes[newIndex]
        this.setGridIndex(false, indexToSet)
    }

    handleMovementRight() {
        // add one to current layout index for user
        const { layout, user, availableGrid } = this.state

        const currentIndex = layout[user.info.uid]
        if (currentIndex === 39) return

        const newIndex = availableGrid.indexOf(currentIndex) + 1
        const indexToSet = availableGrid[newIndex]
        this.setGridIndex(false, indexToSet)
    }

    handleMovementLeft() {
        // minus one to current layout index for user
        const { layout, user, availableGrid } = this.state

        const currentIndex = layout[user.info.uid]
        if (currentIndex === 0) return

        const newIndex = availableGrid.indexOf(currentIndex) - 1
        const indexToSet = availableGrid[newIndex]
        this.setGridIndex(false, indexToSet)
    }

    leaveSession() {
        if (this.sessionHelper) this.sessionHelper.disconnect()
        this.sessionHelper = null
    }

    removeEntireSession() {
        const { roomUrl } = this.state
        database.ref(`/${roomUrl}/callSession/`).remove()
    }

    createOTSession(roomId) {
        return new Promise(async (resolve, reject) => {
            try {
                const requestBody = {
                    roomId: roomId,
                }

                const resp = await fetch("https://us-central1-between-87295.cloudfunctions.net/api/createOTSession", {
                    method: "POST",
                    body: JSON.stringify(requestBody),
                })

                if (resp.status === 200) {
                    // close modal and refresh spaces
                    const response = await resp.json()
                    resolve(response.sessionId)
                } else if (resp.status === 400) {
                    // ahh error, show modal with error and hope for the best
                    const response = await resp.json()
                    const errorMessage = response
                    alert("Error Creating Session: ", errorMessage)
                }
            } catch (error) { }
        })
    }

    createOTToken(sessionId, userId) {
        return new Promise(async (resolve, reject) => {
            try {
                const requestBody = {
                    sessionId: sessionId,
                    userId: userId,
                }

                const resp = await fetch("https://us-central1-between-87295.cloudfunctions.net/api/createOTToken", {
                    method: "POST",
                    body: JSON.stringify(requestBody),
                })

                if (resp.status === 200) {
                    // close modal and refresh spaces
                    const response = await resp.json()
                    resolve(response.token)
                } else if (resp.status === 400) {
                    // ahh error, show modal with error and hope for the best
                    const response = await resp.json()
                    const errorMessage = response
                    alert("Error Creating Session: ", errorMessage)
                }
            } catch (error) { }
        })
    }

    async getDevices(type) {
        // type: 'audio', 'video'
        OT.getDevices(async (error, devices) => {
            if (error) {
                alert("There was an error getting devices. Please check to see that you have proper permissions.")
                return
            }

            const videoDevices = devices.filter((d) => d.kind === "videoInput")
            const audioDevices = devices.filter((d) => d.kind === "audioInput")

            let currentAudioDevice = this.state.currentAudioDevice
            let currentVideoDevice = this.state.currentVideoDevice

            if (currentAudioDevice.length === 0) currentAudioDevice = audioDevices[0] ? audioDevices[0].deviceId : ""
            if (currentVideoDevice.length === 0) currentVideoDevice = videoDevices[0] ? videoDevices[0].deviceId : ""

            this.setState({ currentVideoDevice, currentAudioDevice, audioDevices, videoDevices })
        })
    }

    async changeAudioDevice(e) {
        const deviceId = e.target.value
        this.setState({ currentAudioDevice: deviceId })
        await this.setDevice(deviceId, "audio")
    }

    async changeVideoDevice(e) {
        const device = e.target.value
        this.setState({ currentVideoDevice: device })
        await this.setDevice(device, "video")
    }

    async setDevice(device, type) {
        if (type === "audio") {
            this.publisher.setAudioSource(device)
        } else if (type === "video") {
            this.publisher.setVideoSource(device)
        }
    }

    /* Misc Helpers */

    pushURL(url) {
        const { history } = this.props
        history.push(url)
    }

    toggleChat() {
        this.setState({ chat: !this.state.chat })
    }

    toggleSettingsModal() {
        this.setState({ isShowingSettings: !this.state.isShowingSettings })
    }

    toggleAnalyticsView() {
        this.setState({ isShowingAnalytics: !this.state.isShowingAnalytics })
    }

    toggleHelperCodeModal() {
        this.setState({ isShowingHelperCode: !this.state.isShowingHelperCode })
    }

    camStatusChanged() {
        this.publisher.publishVideo(!this.state.video)
        this.setState({ video: !this.state.video })
    }

    micStatusChanged() {
        this.publisher.publishAudio(!this.state.audio)
        this.setState({ audio: !this.state.audio })
    }

    goHome() {
        const { currentRoom, space } = this.state
        const { dispatch } = this.props

        const id = space.id

        dispatch(detachRoomListener(currentRoom.id))
        this.leave()
        this.goToLounge(id)
    }

    generateGrid() {
        let grid = []

        for (let i = 0; i < 40; i++) {
            grid.push(null)
        }

        return grid
    }

    setGridIndex(isInitialIndex, index) {
        const { layout, currentRoom, user } = this.state

        const allIndexes = Object.values(layout)
        const isIndexTaken = allIndexes.includes(index)
        if (isInitialIndex) this.setState({ initialIndex: index })
        if (isIndexTaken) console.log(`index ${index} taken with current layout`, layout)
        else {
            database.ref(`/rooms/${currentRoom.id}/callSession/layout/${user.info.uid}`).set(index)
        }

    }

    getNextAvailableGridIndex() {
        const { availableGrid } = this.state
        const randomIndex = Math.floor(Math.random() * (availableGrid.length - 1))
        return randomIndex
    }

    getAvailableGrid(layout) {
        const { grid, user } = this.state

        let indexesTaken = Object.values(layout)

        // don't include layout index of current user
        const userId = user.info.uid
        const currentUserLayoutIndex = layout[userId]
        const indexOfLayoutInLayout = indexesTaken.indexOf(currentUserLayoutIndex)
        indexesTaken.splice(indexOfLayoutInLayout, 1)

        const availableGrid = []
        grid.forEach((item, index) => {
            if (!indexesTaken.includes(index)) availableGrid.push(index)
        })

        return availableGrid
    }

    changeUserState() {
        const { currentUserState, currentRoom, user } = this.state
        const userId = user.info.uid

        if (currentUserState === "REMOTE") {
            // change to in-person
            database.ref(`/rooms/${currentRoom.id}/who/${userId}/type`).set("IN_PERSON")
        } else {
            // change to remote
            database.ref(`/rooms/${currentRoom.id}/who/${userId}/type`).set("REMOTE")
        }
    }

    // UI Helpers

    renderCell(isInActiveSession, index, type, item, who, layout) {
        let view = null

        const ids = Object.keys(who)
        let doesHaveVideo = false
        let name = ''
        let meetingTypeRaw = ''
        let meetingType = ''

        for (let i = 0; i < ids.length; i++) {
            if (layout[ids[i]] === index) {
                doesHaveVideo = true
                name = who[ids[i]].name
                meetingTypeRaw = who[ids[i]].type
            }
        }

        if (meetingTypeRaw === "REMOTE") meetingType = 'remote'
        else if (meetingTypeRaw === "IN_PERSON") meetingType = 'office'

        if (type === "empty") {
            view = (
                <div
                    id={`${index}_cell`}
                    onClick={() => this.setGridIndex(false, index)}
                    style={{ opacity: isInActiveSession ? item : 0 }}
                    className="video-cell"
                >
                    <div className="square-cell-base">
                        <div id={`${index}_video_content`} className="video-content"></div>
                    </div>
                    <div className={`${meetingType}-cell-hover-view`}>
                        <div className={`${meetingType}`}>
                            {doesHaveVideo ? <p className={`${meetingType}-text`}>{name} - {meetingType} </p> : null}
                        </div>
                    </div>
                    <p id={`${index}_audio`} className='debug-audio-text'></p>
                </div>
            )
        }

        return view
    }

    changeFreq(e) {
        if (!this.biquadFilter) return
        this.setState({ freq: e.target.value })
        const { maxValue } = this.state

        // Clamp the frequency between the minimum value (40 Hz) and half of the
        // sampling rate.
        var minValue = 40
        // Logarithm (base 2) to compute how many octaves fall in the range.
        var numberOfOctaves = Math.log(maxValue / minValue) / Math.LN2
        // Compute a multiplier from 0 to 1 based on an exponential scale.
        var multiplier = Math.pow(2, numberOfOctaves * (e.target.value - 1.0))
        // Get back to the frequency value between min and max.
        this.biquadFilter.frequency.value = maxValue * multiplier
    }

    changeQuality(e) {
        if (!this.biquadFilter) return
        this.setState({ quality: e.target.value })
        this.biquadFilter.Q.value = e.target.value * 30
    }

    renderGrid() {
        const { volumeGrid, currentUserState, currentRoom } = this.state

        const isInActiveSession = this.sessionHelper ? (this.sessionHelper.connection ? true : false) : false
        let who = {}
        let layout = {}
        if (currentRoom.who) who = currentRoom.who
        if (currentRoom.callSession.layout) layout = currentRoom.callSession.layout

        const content = volumeGrid.map((item, index) => {
            return this.renderCell(isInActiveSession, index, "empty", item, who, layout)
        })

        const toggleButton =
            currentUserState === "REMOTE" ? (
                <div onClick={this.changeUserState.bind(this)} className="toggle-mode-btn toggle-mode-btn-blue">
                    Remote Mode
                </div>
            ) : (
                <div onClick={this.changeUserState.bind(this)} className="toggle-mode-btn toggle-mode-btn-red">
                    In Office Mode
                </div>
            )

        return (
            <>
                <div id="no-activity-call-container" className="no-activity-call-container">
                    {currentUserState.length > 0 ? <div className="toggle-mode">{toggleButton}</div> : null}
                    {content}
                </div>
                {/* {isInActiveSession ? micStuff : null} */}
                {currentUserState.length === 0 ? this.renderChoosingView() : null}
            </>
        )
    }

    renderSettingsModal() {
        const { isShowingSettings, user, currentAudioDevice, currentVideoDevice, audioDevices, videoDevices } =
            this.state

        var callView = null

        // is in an active call/room, show video/audio settings
        if (this.sessionHelper) {
            callView = (
                <div className="d-flex flex-column">
                    <h6 className="mb-3 text-dark">Call Settings</h6>
                    <div className="d-flex flex-row">
                        <div className="col d-flex flex-row">
                            <FontAwesomeIcon icon={faMicrophone} size={"sm"} className="m-2 align-self-center" />
                            <Form.Control
                                as="select"
                                onChange={this.changeAudioDevice.bind(this)}
                                value={currentAudioDevice}
                            >
                                {audioDevices.map((audioDevice) => (
                                    <option key={audioDevice.label} value={audioDevice.deviceId}>
                                        {audioDevice.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                        <div className="col d-flex flex-row">
                            <FontAwesomeIcon icon={faVideo} size={"sm"} className="m-2 align-self-center" />
                            <Form.Control
                                as="select"
                                onChange={this.changeVideoDevice.bind(this)}
                                value={currentVideoDevice}
                            >
                                {videoDevices.map((videoDevice) => (
                                    <option key={videoDevice.label} value={videoDevice.deviceId}>
                                        {videoDevice.label}
                                    </option>
                                ))}
                            </Form.Control>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isShowingSettings}
                onHide={this.toggleSettingsModal.bind(this)}
            >
                <Modal.Body>
                    <h6 className="mb-0 text-dark">Your Information</h6>
                    <div className="d-flex justify-content-center align-items-center">
                        <img
                            src={user.info.photoURL}
                            alt="activityphoto"
                            height="80px"
                            width="80px"
                            className="rounded img-fluid mb-3"
                        />
                    </div>
                    <h3 className="text-center mb-0">{user.info.displayName}</h3>
                    <p className="text-center text-muted mb-2">{user.info.email}</p>
                    {callView}
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button className="orange-btn cool-btn active-button" onClick={this.toggleSettingsModal.bind(this)}>
                        Done
                    </button>
                    <button className="red-btn cool-btn" variant="danger" onClick={this.logUserOut.bind(this)}>
                        Log Out
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderHelperCodeModal() {
        const { isShowingHelperCode, helperCode } = this.state

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isShowingHelperCode}
                onHide={this.toggleHelperCodeModal.bind(this)}
            >
                <Modal.Body>
                    <h6 className="mb-0 text-dark">Your Helper Code</h6>
                    <h3 className="mb-0">{helperCode}</h3>
                    <p className="text-muted mb-2">Paste this into the Between helper app!</p>
                </Modal.Body>
                <Modal.Footer className="d-flex flex-row justify-content-between align-items-center">
                    <button
                        className="orange-btn cool-btn active-button"
                        onClick={this.toggleHelperCodeModal.bind(this)}
                    >
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderChatView() {
        const { chat, roomCode, user } = this.state

        const name = user.info.displayName
        const uid = user.info.uid

        return (
            <div className={`abs-chat-view cool-box-shadow ${!chat ? "hide-chat" : ""}`}>
                {/* <Chat type={'groups'} id={roomCode} displayName={name} uid={uid} isShowing={chat} toggleChat={this.toggleChat.bind(this)} /> */}
            </div>
        )
    }

    renderChoosingView() {
        return (
            <div className="type-choosing-container">
                <h1 className="hero_text align-self-center ">Where Are You?</h1>
                <div className="type-choosing-view">
                    <div className="type-choosing-image-container">
                        <img
                            src={require("../assets/call-remote.png")}
                            onClick={this.chooseTypeAndStartCall.bind(this, "REMOTE")}
                            alt="remote"
                        />
                    </div>
                    <div className="type-choosing-image-container">
                        <img
                            src={require("../assets/call-in-office.png")}
                            onClick={this.chooseTypeAndStartCall.bind(this, "IN_PERSON")}
                            alt="in-office"
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderRoomInfo() {
        const {
            user,
            space,
            chat,
            isShowingSettings,
            isShowingAnalytics,
            roomCode,
            currentRoom,
            startingCall,
            notifications,
            isOutOfTime,
            audio,
            video,
        } = this.state

        const isInActiveSession = this.sessionHelper ? (this.sessionHelper.connection ? true : false) : false
        const databaseSessionExists = currentRoom ? currentRoom.callSession.id.length > 0 : false
        const isRoomHost = currentRoom ? currentRoom.host === user.info.uid : false
        const { hasUnreadMessages } = notifications
        const hasActivitiesInQueue = currentRoom ? currentRoom.queue.list.length > 0 : false

        const mainView = <div className="no-activity-top-view">{this.renderGrid()}</div>

        // chat window view
        const chatView = this.renderChatView()

        const managingView = <LoungeManager isShowingInfo={true} company={space} />

        const defaultView = (
            <div className="room-main-view">
                <div className="room-content-holder">{isOutOfTime ? managingView : mainView}</div>
            </div>
        )

        return (
            <div className="main-bg container-grid">
                {chatView}
                {isShowingAnalytics ? <Analytics facultyName={space.name} /> : defaultView}
                <Toolbar
                    // data
                    roomId={roomCode}
                    codes={space.codes}
                    // isLoungeManager={userType === 'leader'}
                    isRoomHost={isRoomHost}
                    databaseSessionExists={databaseSessionExists}
                    // bools
                    isInRoom={true}
                    session={isInActiveSession}
                    toggleChat={this.toggleChat.bind(this)}
                    chat={chat}
                    settings={isShowingSettings}
                    showCompanyCode={isShowingAnalytics}
                    startingCall={startingCall}
                    hasUnreadMessages={hasUnreadMessages}
                    isOutOfTime={isOutOfTime}
                    hasActivitiesInQueue={hasActivitiesInQueue}
                    audio={audio}
                    video={video}
                    // functions
                    toggleSettingsModal={this.toggleSettingsModal.bind(this)}
                    toggleAnalytics={this.toggleAnalyticsView.bind(this)}
                    toggleAudio={this.micStatusChanged.bind(this)}
                    toggleVideo={this.camStatusChanged.bind(this)}
                    goHome={this.goHome.bind(this)}
                    joinCall={this.startTheCall.bind(this)}
                    endCall={this.goHome.bind(this)}
                    setChoosingView={this.setChoosingView.bind(this)}
                    getAndShowHelperCode={this.getHelperCode.bind(this)}
                />
                {this.renderSettingsModal()}
                {this.renderHelperCodeModal()}
            </div>
        )
    }

    renderError() {
        const { error, errorDetails } = this.state

        if (error === "NOT_IN_COMPANY") {
            return <CreateALounge showCodeEntry companyDetails={errorDetails} pushURL={this.pushURL.bind(this)} />
        } else if (error === "DOES_NOT_EXIST") {
            return (
                <div className="main-wrapper-group-page not-found-error">
                    <div className="not-found-container">
                        <img
                            src="https://gatherapp.live/media/background/error.png"
                            alt="404_image"
                            className="img-fluid"
                        />
                        <p className="text-center mt-0">The space you are looking for does not exist!</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <button className="orange-btn cool-btn" onClick={() => this.pushURL("/space")}>
                                Go Home
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else if (error === "NOT_IN_ANY") {
            return <CreateALounge pushURL={this.pushURL.bind(this)} />
        } else if (error === "ROOM_DOES_NOT_EXIST") {
            return (
                <div className="main-wrapper-group-page not-found-error">
                    <div className="not-found-container">
                        <img
                            src="https://gatherapp.live/media/background/error.png"
                            alt="404_image"
                            className="img-fluid"
                        />
                        <p className="text-center mt-0">The room you are looking for does not exist!</p>
                        <div className="d-flex align-items-center justify-content-center">
                            <button className="orange-btn cool-btn" onClick={() => this.pushURL("/space")}>
                                Go Home
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="main-wrapper-group-page">
                    <p className="dark-text text-center mt-4">{error}</p>
                </div>
            )
        }
    }

    render() {
        const { space, currentRoom, error } = this.state

        if (error) return this.renderError()
        else if (space && currentRoom && currentRoom.id.length > 0) return this.renderRoomInfo()
        else {
            return (
                <div className="main-wrapper-group-page d-flex align-items-center justify-content-center">
                    <h5 className="dark-text text-center">Loading...</h5>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        currentRoom: state.room,
        notifications: state.notify,
    }
}

export default withRouter(connect(mapStateToProps)(RoomPage))
