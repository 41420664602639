import * as React from "react";
import { connect } from "react-redux";
import firebase from "../firebase";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { notifyUser } from "../redux/actions";
import ReactGA from 'react-ga';
import "../assets/css/ChatView.css";
var isEqual = require("lodash.isequal");
var database = firebase.database();
class Chat extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isShowing: false,
            message: "",
            messages: [],
            lastReadMessageId: "",
        };
        this.messagesEnd = React.createRef();
    }
    componentDidMount() {
        this.startListenerForMessages();
        this.startListenerForLastRead();
    }
    componentWillUnmount() {
        const { type, id, uid } = this.props;
        database.ref(`/${type}/${id}/messages`).off("value");
        database.ref(`/${type}/${id}/messages_notifications/${uid}`).off("value");
    }
    componentDidUpdate(_, prevState) {
        if (!isEqual(prevState.messages, this.state.messages)) {
            const node = this.messagesEnd.current;
            node.scrollIntoView();
        }
        if (prevState.isShowing !== this.state.isShowing) {
            this.markLastMessageAsRead();
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.isShowing !== state.isShowing) {
            return { isShowing: props.isShowing };
        }
        return null;
    }
    toggle() {
        this.props.toggleChat();
    }
    shouldNotifyUser() {
        const { messages, lastReadMessageId } = this.state;
        var lastMessageId = "";
        if (messages.length > 1)
            lastMessageId = messages[messages.length - 1].id ? messages[messages.length - 1].id : "";
        else if (messages.length > 0)
            lastMessageId = messages[0].id ? messages[0].id : "";
        return {
            shouldNotify: lastMessageId && lastMessageId !== lastReadMessageId,
            lastMessageId,
        };
    }
    sendNotification() {
        const { shouldNotify } = this.shouldNotifyUser();
        const { dispatch } = this.props;
        dispatch(notifyUser(shouldNotify));
    }
    markLastMessageAsRead() {
        const { type, id, uid } = this.props;
        const { shouldNotify, lastMessageId } = this.shouldNotifyUser();
        if (shouldNotify)
            database.ref(`/${type}/${id}/messages_notifications/${uid}`).set(lastMessageId);
    }
    startListenerForLastRead() {
        const { type, id, uid } = this.props;
        database.ref(`/${type}/${id}/messages_notifications/${uid}`).on("value", (snap) => {
            if (snap.exists()) {
                this.setState({ lastReadMessageId: snap.val() }, () => {
                    this.sendNotification();
                });
            }
        });
    }
    startListenerForMessages() {
        const { type, id } = this.props;
        database.ref(`/${type}/${id}/messages`).on("value", (snap) => {
            const { isShowing } = this.state;
            if (snap.exists()) {
                const messages = Object.values(snap.val());
                this.setState({ messages: messages }, () => {
                    if (isShowing)
                        this.markLastMessageAsRead();
                    else
                        this.sendNotification();
                });
                const node = this.messagesEnd.current;
                node.scrollIntoView();
            }
            else {
                this.setState({ messages: [] });
            }
        });
    }
    handleMessageChange(e) {
        this.setState({ message: e.currentTarget.value });
    }
    sendMessage(e) {
        e.preventDefault();
        // validate message
        const { message } = this.state;
        const { type, id, uid, displayName } = this.props;
        if (message.length > 0) {
            database
                .ref(`/${type}/${id}/messages`)
                .push({
                    messageText: message,
                    timestamp: moment().valueOf(),
                    uid: uid,
                    displayName: displayName,
                })
                .then((snap) => {
                    const key = snap.key;
                    database.ref(`/${type}/${id}/messages/${key}/id`).set(key);
                });
            ReactGA.event({
                category: "Call",
                action: `Sent a message`,
            })
            // clear state variables
            this.setState({ message: "" });
        }
    }
    renderMessages() {
        const { messages } = this.state;
        const views = [];
        for (let i = 0; i < messages.length; i++) {
            const m = messages[i];
            const time = moment(m.timestamp);
            const fiveMinsAgo = time.subtract("5", "m").valueOf();
            const oneDayAgo = time.subtract("1", "d").valueOf();
            // default base props for ChatCell component
            var prevSameUid = false;
            var passShortLimit = false;
            var passLongLimit = false;
            // create date header
            const text = time.isSame(moment(), "day") ? "Today" : time.format("dddd, MMMM Do");
            const dateHeader = React.createElement(DateHeader, { key: `${m.timestamp}_header`, text: text });
            if (i > 0 && messages.length > 1) {
                const prevMessage = messages[i - 1];
                prevSameUid = prevMessage.uid === m.uid;
                passShortLimit = fiveMinsAgo >= prevMessage.timestamp;
                passLongLimit = oneDayAgo >= prevMessage.timestamp;
                if (passLongLimit)
                    views.push(dateHeader);
            }
            else {
                views.push(dateHeader);
            }
            views.push(React.createElement(ChatCell, { key: `${m.timestamp}${m.uid}`, message: m, prevSameUid: prevSameUid, passShortLimit: passShortLimit, passLongLimit: passLongLimit }));
        }
        return views;
    }
    render() {
        const { message } = this.state;
        const title = "Room Chat";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "chat-header" },
                React.createElement("h4", { className: "mb-0" }, title),
                React.createElement(FontAwesomeIcon, { className: "chat-close-button", icon: faTimes, onClick: this.toggle.bind(this) })),
            React.createElement("div", { className: "messages-feed" },
                this.renderMessages(),
                React.createElement("div", { style: { height: "1px" }, ref: this.messagesEnd })),
            React.createElement("form", { className: "message-form", onSubmit: this.sendMessage.bind(this) },
                React.createElement("input", { className: "new-message", placeholder: "Type Something...", value: message, onChange: this.handleMessageChange.bind(this) }),
                React.createElement("div", { className: "message-form-buttons" },
                    React.createElement(FontAwesomeIcon, { className: "send", icon: faPaperPlane, onClick: this.sendMessage.bind(this) })))));
    }
}
class ChatCell extends React.Component {
    validUrl(text) {
        if (text.includes("https") || text.includes("www")) {
            return true;
        }
        const url_pattern = /^(https?|ftp|torrent|image|irc):\/\/(-\.)?([^\s\/?\.#-]+\.?)+(\/[^\s]*)?$/i;
        return text.match(url_pattern);
    }
    renderText(messageText) {
        const isUrl = this.validUrl(messageText);
        if (isUrl)
            return (React.createElement("a", { className: "message-text m-0", target: "_blank", rel: "noopener noreferrer", href: messageText }, messageText));
        else
            return React.createElement("p", { className: "message-text m-0" }, messageText);
    }
    renderCell() {
        const { passLongLimit, passShortLimit, prevSameUid, message } = this.props;
        var view = null;
        const text = this.renderText(message.messageText);
        if (!passLongLimit && !passShortLimit && prevSameUid) {
            view = text;
        }
        else if (!prevSameUid || passLongLimit || passShortLimit) {
            const fancyDate = moment(message.timestamp).format("h:mm a");
            view = (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "d-flex flex-row justify-content-between" },
                    React.createElement("small", { className: "m-0 font-weight-bold" },
                        message.displayName,
                        " ",
                        React.createElement("span", { className: "washed-out" }, fancyDate))),
                text));
        }
        return (React.createElement("div", { className: "message-box" },
            React.createElement("div", { className: "message-content" }, view)));
    }
    render() {
        return this.renderCell();
    }
}
const DateHeader = ({ text }) => (React.createElement("div", { className: "date-header-container" },
    React.createElement("hr", { className: "f-1" }),
    React.createElement("small", null, text),
    React.createElement("hr", { className: "f-1" })));
function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}
const connector = connect(mapStateToProps);
export default connector(Chat);
