import React, { Component } from 'react'
import ReactGA from "react-ga";
import { connect } from "react-redux";
import * as moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import firebase from "../firebase";
import { loadStripe } from '@stripe/stripe-js';

import pino from './pino'

var database = firebase.database();
var isEqual = require("lodash.isequal");

const stripePromise = loadStripe('pk_live_51IGql1Kqw07TpgV5dwVMOe5m6QdzKGj7pBBYgrRdcDD2aQlH3HXhrgJAXyAXnQJYKmPeHXm1QrPDMURrvGBYD4hx00862ddBkr')

export class LoungeManager extends Component {

  constructor(props) {
    super(props)

    this.state = {

      subscriptionList: [],
      companyName: '',
      chosenForPurchase: null,
      roomCount: 0,
      goingToCheckout: false,

      isShowingInfo: props.isShowingInfo,
      company: null,
      user: null,
    }
  }

  async componentDidMount() {
    this.getSubscriptionList()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var shouldUpdateCompany = !isEqual(nextProps.company, prevState.company)
    var shouldUpdateUser = !isEqual(nextProps.user, prevState.user)
    var shouldUpdateToggle = !isEqual(nextProps.isShowingInfo, prevState.isShowingInfo)

    if (shouldUpdateCompany) {
      return { company: nextProps.company, companyName: nextProps.company.name };
    } else if (shouldUpdateToggle) {
      return { isShowingInfo: nextProps.isShowingInfo };
    } else if (shouldUpdateUser) {
      return { user: nextProps.user };
    } else {
      return null;
    }
  }

  async componentDidUpdate(_prevProps, prevState) {
    if (!isEqual(this.props.company, prevState.company)) {
      this.setState({ company: this.props.company, companyName: this.props.company.name });
    }

    if (!isEqual(this.props.user, prevState.user)) {
      this.setState({ user: this.props.user });
    }

    if (!isEqual(this.props.isShowingInfo, prevState.isShowingInfo)) {
      this.setState({ isShowingInfo: this.props.isShowingInfo });
    }
  }

  // Database Helpers

  async getSubscriptionList() {

    const snap = await database.ref(`/subscription_info`).once('value')
    this.setState({ subscriptionList: snap.val() })

  }

  // Misc Helpers

  updateType(roomCount) {
    const { subscriptionList } = this.state

    if (roomCount >= 1 && roomCount < 6) {
      this.chooseForPurchase(subscriptionList[0], roomCount)
    } else if (roomCount >= 6 && roomCount < 11) {
      this.chooseForPurchase(subscriptionList[1], roomCount)
    } else if (roomCount >= 11) {
      this.chooseForPurchase(subscriptionList[2], roomCount)
    }
  }

  incrementRoomCount() {
    const { roomCount } = this.state
    if (roomCount < 5) {
      const newRoomCount = roomCount + 1
      this.updateType(newRoomCount)
    }
  }

  decrementRoomCount() {
    const { roomCount } = this.state
    if (roomCount > 1) {
      const newRoomCount = roomCount - 1
      this.updateType(newRoomCount)
    }
  }

  handleRoomCount(e) {
    this.setState({ roomCount: e.target.value })
  }

  handleNameField(e) {
    this.setState({ companyName: e.target.value })
  }

  toggleInfoView() {
    this.props.toggleInfoView()
  }

  chooseForPurchase(chosenForPurchase, roomCount) {
    this.setState({ chosenForPurchase, roomCount })
  }

  // Payment Helpers

  async createCheckoutSession() {

    const { company, roomCount, chosenForPurchase, user } = this.state

    const priceId = chosenForPurchase.priceId
    const companyCode = company.codes.quickAccess
    const customerEmail = user.info.email ? user.info.email : user.info.providerData[0].email

    return new Promise(async (resolve, reject) => {
      const data = { priceId, roomCount, companyCode, companyId: company.id, customerEmail }

      console.log('sending data', data)

      try {
        const response = await fetch(
          "https://us-central1-gatheronline-573d7.cloudfunctions.net/api/createCheckoutSession",
          {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const resp = await response.json();
        console.log(resp)
        if (resp.sessionId) {
          resolve(resp.sessionId)
        } else {
          reject(resp.error.message)
        }
      } catch (error) {
        pino.error("error creating session", error);
        ReactGA.ga('send', 'exception', {
          'exDescription': JSON.stringify(error.message),
          'exFatal': true
        })
      }
    });

  }

  handleCheckoutFlow() {

    return new Promise(async (resolve, reject) => {

      try {

        this.setState({ goingToCheckout: true })

        const sessionId = await this.createCheckoutSession()
        const stripe = await stripePromise

        stripe.redirectToCheckout({
          sessionId: sessionId,
        }).then(error => {
          pino.error('redirectToCheckout', error)
          this.setState({ goingToCheckout: false })

        })

        resolve()
      } catch (error) {
        this.setState({ goingToCheckout: false })
        pino.error("error creating session", error);
        ReactGA.ga('send', 'exception', {
          'exDescription': JSON.stringify(error.message),
          'exFatal': true
        })
      }
    });

  }

  // UI Helpers

  renderStripeDetails() { }

  renderSubscriptionCell(s, updatingRooms) {

    const price = `$${s.price}/room`
    const name = s.name.toUpperCase()
    const description = s.description

    const featuresList = s.detail.split('\n')
    const featuresView = featuresList.map((f, i) => <p key={`${s.type}_${i}`}><FontAwesomeIcon icon={faCheck} className='mr-2' />{f}</p>)

    const click = updatingRooms ? this.chooseForPurchase.bind(this, null, 0) : this.chooseForPurchase.bind(this, s, s.min_rooms)

    const isEnabled = s.type === 'startups'
    const btnText = isEnabled ? updatingRooms ? 'Change' : 'Choose' : 'Coming Soon'

    return (
      <div className={`subscription-view ${s.type}`}>
        <h2 className='price-text'>{price}</h2>
        <h4 className='name'>{name}</h4>
        <h5 className='sub-header'>{description}</h5>
        <div className='detail-container'>{featuresView}</div>
        <div className='d-flex align-items-center justify-content-center'>
          <button disabled={!isEnabled} onClick={click} className={`${s.type}-btn hover-btn cool-btn`}>{btnText}</button>
        </div>
      </div>
    )

  }

  renderSubscriptionList() {

    const { subscriptionList } = this.state

    const view = subscriptionList.map(s => this.renderSubscriptionCell(s, false))

    return view

  }

  renderInformationView() {

    const { chosenForPurchase, roomCount, goingToCheckout } = this.state

    var subView = null

    if (chosenForPurchase) {

      const subTotal = `$${(roomCount * chosenForPurchase.price).toFixed(2)}`

      const btnText = goingToCheckout ? 'Loading...' : 'Confirm & Pay'

      subView = (
        <div className='subscription-container'>
          <div className='gcs-1 gce-2'>
            {this.renderSubscriptionCell(chosenForPurchase, true)}
          </div>
          <div className='gcs-2 gce-4'>
            <div className='d-flex flex-column align-items-center justify-content-center h-100 p-2'>
              <div className='f-1 d-flex flex-row justify-content-between align-items-center w-100'>
                <h5 className='font-weight-normal'>Rooms</h5>
                <div className='room-count-holder'>
                  <div onClick={this.decrementRoomCount.bind(this)} className='room-count-btn'><FontAwesomeIcon size='sm' icon={faMinus} /></div>
                  <input value={roomCount} min={1} max={30} readOnly />
                  <div onClick={this.incrementRoomCount.bind(this)} className='room-count-btn'><FontAwesomeIcon size='sm' icon={faPlus} /></div>
                </div>
              </div>
              <div className='f-1 w-100'>
                <hr />
                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                  <h3>Subtotal</h3>
                  <h3>{subTotal}</h3>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                  <button disabled={goingToCheckout} onClick={this.handleCheckoutFlow.bind(this)} className='green-btn hover-btn cool-btn'>{btnText}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      // show upgrade options

      subView = (
        <div className='subscription-container'>
          {this.renderSubscriptionList()}
        </div>
      )
    }

    return (
      <>
        <hr className='w-100' />
        <h4 className=''>Choose A Plan to Upgrade!</h4>
        <div className='d-flex flex-row align-items-center justify-content-center f-1'>
          {subView}
        </div>
      </>
    )

  }

  renderManageSubscriptionView() {

    return (
      <>
        <hr className='w-100' />
        <h4 className=''>Manage Your Subscription</h4>
      </>
    )
  }

  renderSubInfoView() {

    const { company } = this.state

    const subscriptionType = company.subscription.type

    if (subscriptionType === 'free') return this.renderInformationView()
    else return this.renderManageSubscriptionView()

  }

  render() {

    const { company } = this.state

    const rooms = `Rooms: ${Object.keys(company.rooms).length}`

    const subscriptionType = company.subscription.type[0].toUpperCase() + company.subscription.type.slice(1)

    const renewalDate = company.subscription.period.end * 1000 // convert into milliseconds
    const subscriptionRenewal = renewalDate !== 1000 ? moment(renewalDate).format('MMMM Do YYYY') : 'Never!'

    const leaderList = Object.values(company.users).filter(u => u.type === 'leader')
    const leaderName = leaderList.length > 0 ? leaderList[0].displayName : ''

    return (
      <div className='edit-view-container'>
        <h3 className="mb-3">Manage Your Lounge</h3>
        {/* <div className='manage-lounge-name'>
            <label className="">Edit Name</label>
            <div className="f-1">
              <input className="" value={companyName} onChange={this.handleNameField.bind(this)} />
            </div>
          </div> */}
        <div className='manage-holder'>
          <div className='manage-info-cube'>
            <h5>General Information</h5>
            <p>Leader: {leaderName}</p>
            <p>{rooms}</p>
          </div>
          <div className='manage-info-cube'>
            <h5>Subscription Information</h5>
            <p>Type: {subscriptionType}</p>
            <p>Renewal: {subscriptionRenewal}</p>
          </div>
        </div>
        {this.renderSubInfoView()}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(LoungeManager)
