import React, { Component } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faArrowRight } from "@fortawesome/free-solid-svg-icons"

import RoomCell from "./RoomCell"
import "./RoomView.css"
import "../Widgets.css"
var isEqual = require("lodash.isequal")

export class RoomView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOdd: null,
            rooms: props.rooms,
            allowedRooms: props.allowedRooms,
            isSpaceManager: props.isSpaceManager,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var shouldUpdateRooms = !isEqual(nextProps.rooms, prevState.rooms)

        if (shouldUpdateRooms) {
            return { rooms: nextProps.rooms }
        } else {
            return null
        }
    }

    async componentDidUpdate(_prevProps, prevState) {
        const { isSpaceManager } = this.props

        if (!isEqual(this.props.rooms, prevState.rooms)) {
            this.setState({ rooms: this.props.rooms })
        }
        if (isSpaceManager !== prevState.isSpaceManager) {
            this.setState({ isSpaceManager })
        }
    }

    renderRooms() {
        const { rooms, isSpaceManager } = this.state
        const { uid } = this.props

        if (rooms.length === 0) {
            return <h5 className="light-text mb-0 text-center f-1">Loading...</h5>
        }

        const views = []

        for (let i = 0; i < rooms.length; i++) {
            const room = rooms[i]

            views.push(
                <RoomCell
                    enterRoomURL={this.props.enterRoomURL.bind(this)}
                    key={room.id}
                    roomId={room.id}
                    uid={uid}
                    isSpaceManager={isSpaceManager}
                    users={this.props.users}
                />
            )
        }

        const premiumBanner = (
            <div className="room-cell-container">
                <h6 className="mb-2 hidden-text noselect">t</h6>

                <div key={"premium"} className="room-cell premium-border">
                    <div className="room-join-btn-container">
                        <button className="room-join-btn hover-btn orange-btn cool-btn">
                            Get Started <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                    <h6 className="mb-2 primary-orange">Add more rooms and much more!</h6>
                    <h4 className={`text-align-right m-0 primary-orange`}>
                        $15 <span className="h5">/month</span>
                    </h4>
                    {/* <h5 className={`text-align-right m-0 primary-orange`}>/month</h5> */}
                </div>
            </div>
        )

        return (
            <>
                {views}
                {/* premiumBanner */}
            </>
        )
    }

    renderCreatingRoomView() {
        const { allowedRooms, rooms, isSpaceManager } = this.state

        var view = null
        if (rooms.length < allowedRooms && isSpaceManager) {
            view = (
                <div className="room-cell-container">
                    <h6 className="mb-2 hidden-text noselect">t</h6>

                    <div key={"premium"} className="room-cell">
                        <div className="room-join-btn-container">
                            <button
                                className="room-join-btn hover-btn orange-btn cool-btn"
                                onClick={() => this.props.createNewRoom()}
                            >
                                Create <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center h-100 w-100">
                            <FontAwesomeIcon icon={faPlus} size="3x" className="light-text" />
                            <h4 className={`text-center mt-2 mb-0 light-text`}>Create A Room!</h4>
                        </div>
                    </div>
                </div>
            )
        }

        return view
    }

    render() {
        const layoutString = "gcs-1 gce-9 grs-2 gre-4" // this.props.dimensions

        return (
            <div className={`RoomView ${layoutString}`}>
                <div className="room-list f-1">
                    {this.renderRooms()}
                    {/*this.renderCreatingRoomView()*/}
                </div>
            </div>
        )
    }
}

export default RoomView
