import React, { Component } from 'react'

import firebase from "../firebase";
import '../assets/css/DevPortal.css'

import CallHistory from './dev_portal_tables/CallHistoryTable'
import Users from './dev_portal_tables/UsersTable'
import Companies from './dev_portal_tables/CompaniesTable'
import ActiveRooms from './dev_portal_tables/ActiveRoomsTable'
import { Navbar, Nav } from 'react-bootstrap'
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';



var database = firebase.database();
var isEqual = require("lodash.isequal");

class DevPortal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      view: null,
      loading: true,
      adminUsers: [],
    }
  }

  async componentDidMount() {
    await this.getValidDevUsers()
    this.setState({ loading: false })

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var shouldUpdateUser = !isEqual(nextProps.user, prevState.user);

    if (shouldUpdateUser) {
      return { user: nextProps.user };
    } else {
      return null;
    }
  }
  async getValidDevUsers() {

    const users = await database.ref(`/admin_users/`).once('value')
    const adminUsers = Object.values(users.val())

    this.setState({ adminUsers })

  }

  isValidUser() {
    const { adminUsers, user } = this.state

    if (!user || adminUsers.length === 0) return false

    return adminUsers.findIndex(u => u.id === user.info.uid) !== -1
  }

  returnTable(view) {
    if (view == 'Users') return <Users />
    else if (view == 'Companies') return <Companies />
    else if (view == 'Active Rooms') return <ActiveRooms />
    else if (view == 'Call History') return <CallHistory />
  }

  render() {

    const { view, loading } = this.state
    const isValidUser = this.isValidUser()

    if (loading) {
      return (<div className='main-wrapper-group-page h-100 m-3'><h1 className='text-center'>Checking access...</h1></div>)
    } else if (!isValidUser) return <Redirect exact from='/' to='/space/' />
    else {
      return (
        <>
          <Navbar bg="light" variant="light">
            <Navbar.Brand >Admin Dashboard 3: 3 Fast 3 Furious</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link onClick={() => this.setState({ view: 'Users' })}>Users</Nav.Link>
              <Nav.Link onClick={() => this.setState({ view: 'Companies' })}>Companies</Nav.Link>
              <Nav.Link onClick={() => this.setState({ view: 'Active Rooms' })}>Active Rooms</Nav.Link>
              <Nav.Link onClick={() => this.setState({ view: 'Call History' })}>Calls</Nav.Link>
            </Nav>
          </Navbar>
          {this.returnTable(view)}
        </>
      )
    }
  }

}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(DevPortal);
