import React from "react";

import { Route, Switch, Router } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from 'history';

import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./components/Login";
import CompanyPage from "./components/CompanyPage";
import CreateALounge from "./components/CreateALounge";
import RoomPage from "./components/RoomPage";
import DevPortal from "./components/DevPortal";

import { startTrackingSession, fetchUser } from "./redux/actions";
import LandingPage from "./components/LandingPage";
import EphemeralRoomPage from "./components/EphemeralRoomPage";

const history = createBrowserHistory({
  forceRefresh: true
})

function App(props) {
  const { isAuthenticated, isVerifying, isTrackingUser, user, dispatch } = props;

  if (!isVerifying && isAuthenticated && !isTrackingUser && user) {
    dispatch(startTrackingSession(user.info.uid));
    dispatch(fetchUser(user.info.uid));
  }

  return (
    <Router history={history}>
      <Switch>
        <ProtectedRoute
          exact
          path="/space/"
          component={LandingPage}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path="/space/:spaceId/:checkoutSession?"
          component={CompanyPage}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path="/room/:roomCode"
          component={EphemeralRoomPage}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <ProtectedRoute
          exact
          path="/admin"
          component={DevPortal}
          isAuthenticated={isAuthenticated}
          isVerifying={isVerifying}
        />
        <Route path="/" component={Login} />
      </Switch>
    </Router>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying,
    isTrackingUser: state.auth.isTrackingUser,
    user: state.auth.user,
  };
}
export default connect(mapStateToProps)(App);
