import firebase from "../../firebase"
import * as moment from "moment"
import ReactGA from "react-ga"
import pino from "../../components/pino"

var database = firebase.database()

export const CLEAR_ROOM = "CLEAR_ROOM"
export const UPDATE_ROOM = "UPDATE_ROOM"
export const UPDATE_WHO = "UPDATE_WHO"
export const UPDATE_CURRENT_ACTIVITY = "UPDATE_CURRENT_ACTIVITY"
export const UPDATE_TITANIC = "UPDATE_TITANIC"
export const UPDATE_CALL_SESSION = "UPDATE_CALL_SESSION"
export const UPDATE_QUEUE = "UPDATE_QUEUE"
export const UPDATE_HOST = "UPDATE_HOST"

const clearRoom = () => {
    return {
        type: CLEAR_ROOM,
    }
}

const updateRoom = (room) => {
    return {
        type: UPDATE_ROOM,
        payload: room,
    }
}

const updateWho = (who) => {
    return {
        type: UPDATE_WHO,
        payload: who,
    }
}

const updateCurrentActivity = (currentActivity) => {
    return {
        type: UPDATE_CURRENT_ACTIVITY,
        payload: currentActivity,
    }
}

const updateTitanic = (messages) => {
    return {
        type: UPDATE_TITANIC,
        payload: messages,
    }
}

const updateCallSession = (callSession) => {
    return {
        type: UPDATE_CALL_SESSION,
        payload: callSession,
    }
}

const updateQueue = (queue) => {
    return {
        type: UPDATE_QUEUE,
        payload: queue,
    }
}

const updateHost = (host) => {
    return {
        type: UPDATE_HOST,
        payload: host,
    }
}

export function listenToRoom(id) {
    return async (dispatch) => {
        var baseRoomRef = database.ref(`/rooms/${id}`)

        const baseRoomSnap = await baseRoomRef.once("value")
        const baseRoom = baseRoomSnap.val()

        const messages = parseMessages(baseRoom.messages)
        const queue = parseQueue(baseRoom.queue)
        const callSession = parseCallSession(baseRoom.callSession)

        baseRoom["messages"] = messages
        baseRoom["queue"] = queue
        baseRoom["callSession"] = callSession

        // base dispatch for queue and messages
        dispatch(updateRoom(baseRoom))

        // listen for who
        baseRoomRef.child("who").on("value", (whoSnap) => {
            dispatch(updateWho(whoSnap.val()))
        })

        // listen for currentActivity
        baseRoomRef.child("currentActivity").on("value", (currentActivitySnap) => {
            const currentActivity = currentActivitySnap.val()
            if (currentActivitySnap.exists()) {
                const isWeSketchAndHasStarted =
                    currentActivity.activityDetails.id === "notskribbl" && currentActivity.info.round > -1
                if (!isWeSketchAndHasStarted) {
                    dispatch(updateCurrentActivity(currentActivity))
                }
            } else {
                dispatch(updateCurrentActivity(currentActivity))
            }
        })

        // listen for titanic
        baseRoomRef.child("titanic").on("value", (titanicSnap) => {
            dispatch(updateTitanic(titanicSnap.val()))
        })

        // listen for callSession
        baseRoomRef.child("callSession").on("value", (callSessionSnap) => {
            const callSession = parseCallSession(callSessionSnap.val())
            dispatch(updateCallSession(callSession))
        })

        // listen for queue
        baseRoomRef.child("queue").on("value", (queueSnap) => {
            const queue = parseQueue(queueSnap.val())
            dispatch(updateQueue(queue))
        })

        // listen for host
        baseRoomRef.child("host").on("value", (hostSnap) => {
            dispatch(updateHost(hostSnap.val()))
        })
    }
}

function parseMessages(messages) {
    return messages ? Object.values(messages) : []
}

function parseQueue(queue) {
    const raw = queue ? queue : {}
    const list = raw ? Object.values(raw) : []

    return { raw, list }
}

function parseCallSession(callSession) {
    var sessionInfo = {
        id: "",
        layout: {},
        reserved: {},
        private: {},
    }

    if (callSession) {
        const id = callSession.id ? callSession.id : ""
        sessionInfo.id = id

        const layout = callSession.layout ? callSession.layout : {}
        sessionInfo.layout = layout

        const reserved = callSession.reserved ? callSession.reserved : {}
        sessionInfo.reserved = reserved

        const privateCalls = callSession.private ? callSession.private : {}
        sessionInfo.private = privateCalls
    }

    return sessionInfo
}

function getActivityQueue(restartCurrent, activityQueue, roomQueue) {
    const queueInfo = {
        current: "",
        next: "",
    }

    const queue = Object.keys(roomQueue)

    var currentKey = ""
    var nextKey = ""

    if (!activityQueue) {
        // activity queue doesn't exist
        // no activity in queue, get 0 from list and set

        currentKey = queue[0]

        if (queue.length === 1) nextKey = ""
        else {
            nextKey = queue[1]
        }
    } else {
        if (restartCurrent) {
            currentKey = activityQueue.queueInfo.current
            nextKey = activityQueue.queueInfo.next
        } else {
            currentKey = activityQueue.queueInfo.next
            nextKey = ""

            const currentIndex = queue.indexOf(currentKey)
            if (currentIndex === queue.length - 1) nextKey = ""
            else {
                nextKey = queue[currentIndex + 1]
            }
        }
    }

    queueInfo.current = currentKey
    queueInfo.next = nextKey

    return queueInfo
}

async function getActivityInfo(type, activityId) {
    var info = {
        link: "",
        details: "",
    }

    if (type === "internal") {
        if (activityId === "icebreakers") {
            info = {
                question: "",
            }
        } else if (activityId === "teamsquabble") {
            info = {
                round: -1,
                currentVote: {
                    showCalculatedVotes: false,
                    votingScreenRound: -1,
                    promptQueue: {
                        current: "",
                        keys: [],
                    },
                    answers: {
                        list: [],
                        queue: {
                            current: "",
                            keys: [],
                        },
                    },
                },
                score: {
                    teamOne: 0,
                    teamTwo: 0,
                },
            }
        } else if (activityId === "alias") {
            info = {
                round: -1,
                cards: [],
                started: false,
                roundWinner: {
                    team: -1,
                    showScreen: false,
                },
                score: {
                    teamOne: 0,
                    teamTwo: 0,
                },
            }
        } else if (activityId === "firedrill") {
            info = {
                question: "",
                timerEndBy: "",
            }
        } else if (activityId === "teatalk") {
            info = {
                currentData: {
                    question: "",
                    topic: "",
                },
                timerEndBy: "",
                screen: "choose_topic", // 'choose_topic', 'choose_topic_votes', 'main_view'
            }
        } else if (activityId === "show_and_tell") {
            info = {
                question: "",
            }
        } else if (activityId === "realorfake") {
            info = {
                currentTurn: {
                    question: "",
                    type: "",
                    answered: {},
                },
                questions: [],
                score: {},
                winner: {},
            }
        } else if (activityId === "unrealtalks") {
            info = {
                currentVote: {
                    currentUid: "",
                    nextUid: "",
                },
                allQuestions: [],
                showVotingScreen: false,
                score: {},
                winner: {},
            }
        } else if (activityId === "pubtrivia") {
            info = {
                currentTurn: {
                    question: "",
                    id: "",
                    options: [],
                    answered: {},
                },
                playerQueue: {
                    teamOne: {
                        current: "",
                        next: "",
                    },
                    teamTwo: {
                        current: "",
                        next: "",
                    },
                },
                allQuestions: [],
                prompt: -1,
                showAnswer: false,
                showLoading: false,
                score: {
                    teamOne: 0,
                    teamTwo: 0,
                },
                winner: {},
            }
        } else if (activityId === "notskribbl") {
            info = {
                currentTurn: {
                    chosenWord: "",
                },
                chosenOptions: {
                    rounds: 0,
                    timer: 0,
                },
                round: -1,
                timerEndBy: null,
                chat: {},
                started: false,
                playerQueue: {
                    list: [],
                    current: "",
                },
                score: {},
                winner: {},
            }
        } else if (activityId === "piranhapond") {
            info = {
                timerEndBy: null,
                started: false,
                showResults: false,
                players: {},
                presentingQueue: {
                    list: [],
                    current: "",
                    view: "",
                },
                responses: {},
            }
        } else if (activityId === "youtube") {
            info = {
                link: "",
            }
        }
    }

    if (activityId === "drawbattle") {
        try {
            const response = await fetch("https://api.drawbattle.io/games", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            })

            const resp = await response.json()
            const gameId = resp.gameId
            info.link = `https://drawbattle.io/${gameId}`
        } catch (e) {
            pino.error("e", e)
        }
    }

    return info
}

function resetCallLayout(room) {
    const id = room.id
    const callUsersDict = room.who
    const callUsers = Object.values(callUsersDict)

    const mid = Math.ceil(callUsers.length / 2)

    var i = 0
    for (var userId in callUsersDict) {
        // if past half point, switch to right side
        callUsersDict[userId]["isLeft"] = i >= mid ? false : true

        i += 1
    }

    console.log("updated callUsersDict", callUsersDict)

    database.ref(`groups/${id}/gameGridView/`).remove()
    database.ref(`groups/${id}/who/`).set(callUsersDict)
}

export const startActivity = (restartCurrent) => async (dispatch, getState) => {
    const { room } = getState()
    const queueList = room.queue.list
    const queueRaw = room.queue.raw

    if (queueList.length === 0) return

    const id = room.id

    // set queue
    const queueInfo = getActivityQueue(restartCurrent, room.currentActivity, queueRaw)

    // get activity metadata
    const activityDetails = queueRaw[queueInfo.current].activity

    // get dynamic information for activity, based on requirements
    // such as round, cards, questions, etc..
    const activityInfo = await getActivityInfo(activityDetails.type, activityDetails.id)

    const activity = {
        info: activityInfo,
        didStartGathering: true,
        didEndGathering: false,
        startDate: moment().format(),
        endDate: "",
        activityDetails,
        queueInfo,
    }

    ReactGA.event({
        category: "Activities",
        action: "Started Activity",
        label: activityDetails.name,
    })

    resetCallLayout(room)
    database.ref(`/groups/${id}/currentActivity`).set(activity)
}

export const endActivity = () => (dispatch, getState) => {
    const { room } = getState()

    const id = room.id
    const endDate = moment().format()

    // calculate end date
    const start = room.currentActivity.startDate
    const end = moment(endDate)
    let activityTime = end.diff(start)

    let previousActivity = room.currentActivity

    previousActivity.endDate = endDate
    previousActivity.activityTime = activityTime

    ReactGA.event({
        category: "Activities",
        action: "Ended Activity",
        label: previousActivity.activityDetails.name,
        value: activityTime,
    })

    database.ref(`/groups/${id}/currentActivity/didEndGathering`).set(true)

    // resetCallLayout(room)

    // append and push to database
    if (room.callSession.id.length > 0)
        database.ref(`/groups/${id}/callSession/previousActivities`).push(previousActivity)
    database.ref(`/groups/${id}/currentActivity/didEndGathering`).off("value")
    database.ref(`/groups/${id}/`).update({
        currentActivity: null,
        queue: null,
        gameGridView: null,
    })
}

export const detachRoomListener = (id) => (dispatch) => {
    var baseRoomRef = database.ref(`/groups/${id}`)
    baseRoomRef.child(`/who`).off("value")
    baseRoomRef.child(`/currentActivity`).off("value")
    baseRoomRef.child(`/titanic`).off("value")
    baseRoomRef.child(`/callSession`).off("value")
    baseRoomRef.child(`/queue`).off("value")
    dispatch(clearRoom())
}
