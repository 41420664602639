import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/database'

const config = {
  apiKey: "AIzaSyBWUipRB6i6YbxDOk9L-fTl4D6iYii185g",
  authDomain: "between-87295.firebaseapp.com",
  databaseURL: "https://between-87295-default-rtdb.firebaseio.com",
  projectId: "between-87295",
  storageBucket: "between-87295.appspot.com",
  messagingSenderId: "508176178355",
  appId: "1:508176178355:web:99e890398e6aadc60624c6",
  measurementId: "G-H9B1B4H2NV"
};

firebase.initializeApp(config)
export default firebase