import { UPDATE_NOTIFICATION } from "../actions/notify";

const initialState = {
  hasUnreadMessages: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        hasUnreadMessages: action.payload,
      };
    default:
      return state;
  }
};