import React, { Component } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import * as moment from "moment"
import { v4 as uuidv4 } from "uuid"
import ReactGA from "react-ga"

import firebase from "../firebase"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

var database = firebase.database()
var isEqual = require("lodash.isequal")

class CreateALounge extends Component {
    constructor(props) {
        super(props)
        this.state = {
            viewType: props.showCodeEntry ? "enter_company_code" : "default", // default, company_creation, enter_company_code
            showContinueView: false,
            companyDetails: props.companyDetails,

            user: {},

            userType: "leader",
            spaceName: "",
            code: "",
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        var shouldUpdateUser = !isEqual(nextProps.user, prevState.user)

        if (shouldUpdateUser) {
            return { user: nextProps.user }
        } else {
            return null
        }
    }

    componentDidMount() {
        // this.debugCompanies()
    }

    async componentDidUpdate(_prevProps, prevState) {
        if (!isEqual(this.props.user, prevState.user)) {
            this.setState({ user: this.props.user })
        }
    }

    async debugCompanies() {
        const users = await database.ref(`/users`).once("value")
        users.forEach((user) => {
            const userData = user.val()
            const uid = userData.info.uid

            database.ref(`/users/${uid}/account_info`).set({
                type: "free",
            })
            database.ref(`/users/${uid}/spaces`).remove()
        })
    }

    // Lounge Helpers

    async createLounge() {
        const { spaceName, user } = this.state

        if (spaceName.length < 0) return

        try {
            const userId = user.info.uid
            const requestBody = {
                spaceName: spaceName,
                userId,
            }

            const resp = await fetch("https://us-central1-between-87295.cloudfunctions.net/api/createSpace", {
                method: "POST",
                body: JSON.stringify(requestBody),
            })

            ReactGA.event({
                category: "Space",
                action: `Created a space`,
                label: spaceName,
            })

            if (resp.status === 200) {
                // close modal and refresh spaces
                const response = await resp.json()
                console.log("response", response)
                this.setState({ error: null, viewType: "default" }) // reset to regular state
                this.pushURL(`/space/${response.id}`)
            } else if (resp.status === 400) {
                // ahh error, show modal with error and hope for the best
                const response = await resp.json()
                const errorMessage = response.message
                alert("Error Creating Space: ", errorMessage)
            }
        } catch (error) {
            alert("Error Creating Space: ", error)
        }
    }

    async onboardUser(e) {
        e.preventDefault()
        const { user, code } = this.state

        if (code.length === 0) {
            alert("Invalid code!")
            return
        }

        try {
            const userId = user.info.uid
            const requestBody = {
                spaceCode: code,
                userId,
            }

            const resp = await fetch("https://us-central1-between-87295.cloudfunctions.net/api/joinSpace", {
                method: "POST",
                body: JSON.stringify(requestBody),
            })

            if (resp.status === 200) {
                // close modal and refresh spaces
                const response = await resp.json()
                console.log("response", response)
                this.setState({ error: null })
                this.pushURL(`/space/${response.id}`)
            } else if (resp.status === 400) {
                // ahh error, show modal with error and hope for the best
                const response = await resp.json()
                console.log("response", response)
                const errorMessage = response.message
                alert(`Error Joining Space: ${errorMessage}`)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    async onboardUserWithCompany(e) {
        e.preventDefault()
        const { user, code, companyDetails } = this.state
        const default_metrics = {
            enjoyment: 50,
            connectivity: 50,
            cooperation: 50,
            wellness: 50,
            creativity: 50,
        }

        if (code.length === 0) {
            alert("Invalid code!")
            return
        }

        const codeSnap = await database.ref(`/codes/${code}`).once("value")

        if (!codeSnap.exists()) {
            alert("Invalid code!")
            return
        }
        const codeInfo = codeSnap.val()

        if (codeInfo.spaceId === companyDetails.id) {
            const uid = user.info.uid

            const requestBody = {
                spaceCode: code,
                userId: uid,
            }

            const resp = await fetch("https://us-central1-between-87295.cloudfunctions.net/api/joinSpace", {
                method: "POST",
                body: JSON.stringify(requestBody),
            })

            if (resp.status === 200) {
                // close modal and refresh spaces
                const response = await resp.json()
                console.log("response", response)
                this.setState({ error: null })
                window.location.reload()
            } else if (resp.status === 400) {
                // ahh error, show modal with error and hope for the best
                const response = await resp.json()
                const errorMessage = response.message
                alert("Error Joining Space: ", errorMessage)
            }
        } else {
            alert("Invalid code!")
        }
    }

    generateSharingCode() {
        return Math.random().toString(16).substr(2, 8).toUpperCase()
    }

    handleSpaceNameChange(event) {
        this.setState({ spaceName: event.target.value })
    }

    handleCodeChange(event) {
        this.setState({ code: event.target.value })
    }

    pushURL(url) {
        const { history } = this.props

        if (this.props.pushURL) {
            this.props.pushURL(url)
        } else {
            history.push(url)
        }
    }

    redirectToDefaultLounge() {
        const { user } = this.state

        const defaultSpaceId = user.account_info.id
        if (defaultSpaceId) this.pushURL(`/space/${defaultSpaceId}`)
    }

    // UI Helpers

    renderCompanyCreation() {
        const { user, spaceName } = this.state

        const isValid = spaceName.length > 0

        return (
            <div className="w-50">
                <div className="onboarding-box-md">
                    <h1 className="text-center">Setup Your Profile!</h1>
                    <h6 className="text-center font-weight-normal w-75">
                        You’re one step closer to Gathering your team! Tell us about your organization to continue!
                    </h6>
                    <form className="mt-2 d-flex flex-column w-50">
                        <label className="mb-1">Your Name</label>
                        <input className="login-input mb-3" value={user.info.displayName} readOnly />
                        <label className="mb-1">Your Organization</label>
                        <input
                            type="text"
                            className="login-input mb-3"
                            value={spaceName}
                            onChange={this.handleSpaceNameChange.bind(this)}
                            required
                        />
                    </form>
                    <div className="d-flex flex-row activity-buttons w-100 pl-3 pr-3">
                        <p onClick={() => this.setState({ viewType: "default" })} className="back-button mb-0">
                            back
                        </p>
                        <button
                            className="orange-btn cool-btn align-self-center"
                            disabled={!isValid}
                            type="button"
                            onClick={this.createLounge.bind(this)}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    renderDefaultView() {
        const { code, user } = this.state

        if (!user) return null

        const enterCodeView = (
            <div className="onboarding-box-container">
                <div className="onboarding-box-sm align-self-start">
                    <h4 className="light-text text-center mb-2">Enter A Code!</h4>
                    <form className="form w-75">
                        <input
                            className="cool-reg-input-purple-sm w-100"
                            type="text"
                            value={code}
                            onChange={this.handleCodeChange.bind(this)}
                        />
                        <div className="d-flex justify-content-center mt-4">
                            <button className="orange-btn cool-btn" onClick={this.onboardUser.bind(this)}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )

        const continueView = (
            <div className="d-flex align-items-center justify-content-center mt-5">
                <div className="onboarding-box-xs align-self-start" onClick={this.redirectToDefaultLounge.bind(this)}>
                    <h5 className="light-text text-center mb-0">
                        Go to Your Space <FontAwesomeIcon icon={faArrowRight} />
                    </h5>
                </div>
            </div>
        )

        const userHasDefaultCompany = user.account_info.type !== "free"
        const rightView = userHasDefaultCompany ? continueView : null

        return (
            <>
                <div className="onboarding-header-container">
                    <h1 className="onboarding-header">Meet </h1>
                    <h1 className="onboarding-header-second">Between</h1>
                </div>

                <div className="d-flex flex-row w-75">
                    <div className="onboarding-box-container">
                        <div
                            className="onboarding-box-sm align-self-end"
                            onClick={() => this.setState({ viewType: "company_creation" })}
                        >
                            <div className="d-flex align-items-center justify-content-center mt-2">
                                <FontAwesomeIcon icon={faPlus} size="6x" className="light-text" />
                            </div>
                            <h5 className="light-text text-center mt-3 mb-0">Create A Space</h5>
                        </div>
                    </div>
                    <h5 className="mr-5 ml-5 text-align-center text-light align-self-center">OR</h5>
                    {enterCodeView}
                </div>
                {rightView}
            </>
        )
    }

    renderCompanyCodeView() {
        const { code, user, companyDetails } = this.state

        if (!user) return null

        const enterCodeView = (
            <div className="onboarding-box-container">
                <div className="onboarding-box-sm align-self-center">
                    <h4 className="light-text text-center mb-2">Enter The Passcode</h4>
                    <form className="form w-75">
                        <input
                            className="cool-reg-input-purple-sm w-100"
                            type="text"
                            value={code}
                            onChange={this.handleCodeChange.bind(this)}
                        />
                        <div className="d-flex justify-content-center mt-4">
                            <button className="orange-btn cool-btn" onClick={this.onboardUserWithCompany.bind(this)}>
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )

        const continueView = (
            <div className="d-flex align-items-center justify-content-center mt-5">
                <div className="onboarding-box-xs align-self-start" onClick={this.pushURL.bind(this, "/space")}>
                    <h5 className="light-text text-center mb-0">
                        <FontAwesomeIcon icon={faArrowLeft} /> Go Home
                    </h5>
                </div>
            </div>
        )

        return (
            <>
                <h1 className="onboarding-header">Join {companyDetails.name} Lounge</h1>
                <div className="d-flex flex-row w-75">{enterCodeView}</div>
                {continueView}
            </>
        )
    }

    render() {
        const { viewType } = this.state
        var view = null

        if (viewType === "default") view = this.renderDefaultView()
        else if (viewType === "company_creation") view = this.renderCompanyCreation()
        else if (viewType === "enter_company_code") view = this.renderCompanyCodeView()

        return (
            <div className="main-wrapper main-bg">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">{view}</div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    }
}

export default withRouter(connect(mapStateToProps)(CreateALounge))
