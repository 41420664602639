import * as React from 'react';

import '../assets/css/LoadingAnimation.css';


export default function LoadingAnimation(props) {

    return (
        <div className="loading-animation">
            <div className='animation-container'>
                <h1>Creating your call...</h1>
                <img
                    className='loading-spinner'
                    src={require("../assets/spinner.gif")}
                    alt={'loading'}
                />
            </div>
        </div>
    );
}