import React, { Component } from "react";

import AnalyticsCardSingle from "./AnalyticsCardSingle";
import AnalyticsCardTotal from "./AnalyticsCardTotal";

import "../assets/css/Analytics.css";

export class Analytics extends Component {
  render() {
    return (
      <div className="Analytics justify-content-center">
        <div className="pt-5">
          <h1 className="hero_text">{this.props.facultyName} Lobby</h1>
        </div>
        <div className='row container'>
        <div className="col-8">
          <div className='row'>
          <AnalyticsCardSingle points={20} category={"Creativity"} />
          <AnalyticsCardSingle points={4} category={"Rapport"} />
          <AnalyticsCardSingle points={10} category={"Enjoyment"} />
          </div>
          <div className='row'>

            <AnalyticsCardSingle points={7} category={"Connection"} />
            <AnalyticsCardSingle points={12} category={"Well Being"} />
            <AnalyticsCardSingle points={10} category={"Cooperation"} />
            </div>

          </div>

          <div className="fullsize-card col-4">
            <AnalyticsCardTotal
              totalPoints={57}
              healthDescription={
                "Your team health is poor and needs connection and rapport."
              }
              scheduleTime={"We recommend Tea Talk at Friday, 5:00 PM KST"}
            />
          </div>
        </div>
        </div>
    );
  }
}

export default Analytics;
