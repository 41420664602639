import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, isAuthenticated, isVerifying, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!isVerifying && isAuthenticated) {
        return <Component {...props} />
      } else if (!isVerifying && !isAuthenticated) {
        return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      }
    }}
  />
);
export default ProtectedRoute;