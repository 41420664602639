import {
    UPDATE_ROOM,
    UPDATE_WHO,
    UPDATE_CURRENT_ACTIVITY,
    UPDATE_TITANIC,
    UPDATE_CALL_SESSION,
    UPDATE_QUEUE,
    CLEAR_ROOM,
    UPDATE_HOST,
} from "../actions/room"

const initialState = {
    id: "",
    name: "",
    type: "",
    host: "",
    who: {},
    titanic: null,
    currentActivity: null,
    callSession: {
        id: "",
    },
    queue: {
        raw: {},
        list: [],
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_ROOM:
            return initialState
        case UPDATE_ROOM:
            return action.payload
        case UPDATE_WHO:
            return {
                ...state,
                who: action.payload,
            }
        case UPDATE_CURRENT_ACTIVITY:
            return {
                ...state,
                currentActivity: action.payload,
            }
        case UPDATE_TITANIC:
            return {
                ...state,
                titanic: action.payload,
            }
        case UPDATE_CALL_SESSION:
            return {
                ...state,
                callSession: action.payload,
            }
        case UPDATE_QUEUE:
            return {
                ...state,
                queue: action.payload,
            }
        case UPDATE_HOST:
            return {
                ...state,
                host: action.payload,
            }
        default:
            return state
    }
}
