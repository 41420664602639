import React, { Component } from "react"

import GoogleLogin from "react-google-login"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import ReactGA from "react-ga"

import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import {
    loginWithFacebook,
    loginWithGoogle,
    receiveError,
    loginWithMicrosoft,
    createUserWithEmailPassword,
    loginWithEmailPassword,
} from "../redux/actions"

import pino from './pino'
import useCheckBrowser from './hooks/useCheckBrowser'
import useGetOs from './hooks/useGetOs'


import "../assets/css/App.css"
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css"

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOnLoginPage: false,
            name: "",
            email: "",
            password: "",
        }
    }

    componentDidMount() { }

    handleNameChange(e) {
        this.setState({ name: e.target.value })
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value })
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value })
    }

    handleResponse = (response) => {
        const { dispatch } = this.props
        if (response.accessToken) {
            dispatch(loginWithFacebook(response.accessToken))
        } else {
            dispatch(receiveError(response.error, response.details))
        }
    }

    handleGoogleResponse = (response) => {
        const { dispatch } = this.props
        if (response.accessToken && response.tokenId) {
            dispatch(loginWithGoogle(response.tokenId, response.accessToken))
        } else {
            console.log("response", response)
            if (response.error !== "popup_closed_by_user") {
                ReactGA.ga("send", "exception", {
                    exDescription: response.error.message,
                    exFatal: true,
                })
                dispatch(receiveError(response.error, response.details))
            }
        }
    }

    handleMicrosoftResponse = (error, response) => {
        const { dispatch } = this.props
        dispatch(loginWithMicrosoft())
    }

    handleCreatingUser() {
        if (this.state.name.length > 1) {
            const { dispatch } = this.props
            dispatch(createUserWithEmailPassword(this.state.name, this.state.email, this.state.password))
        } else {
            alert("Please enter a name!")
        }
    }

    handleLoginUser() {
        const { dispatch } = this.props
        dispatch(loginWithEmailPassword(this.state.email, this.state.password))
    }

    renderSignUpUI() {
        return (
            <div className="d-flex flex-column justify-content-center col-md-6 col-xs-12">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="onboarding-header-container d-flex flex-column align-items-center">
                        <h1 className="onboarding-header mb-0">Welcome to </h1>
                        <h1 className="onboarding-header-second mb-0">Between</h1>
                    </div>
                    <h5 className="welcome-subheader light-text">Hybrid Chat.</h5>
                </div>
                <div className="mt-4">
                    <GoogleLogin
                        clientId="508176178355-r862ipr8kpq6k18p9gcqa7vrburpkfev.apps.googleusercontent.com"
                        buttonText="Login with Google"
                        onSuccess={this.handleGoogleResponse.bind(this)}
                        onFailure={this.handleGoogleResponse.bind(this)}
                        render={(renderProps) => (
                            <button
                                className="google-button w-100"
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                <div>
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/1024px-Google_%22G%22_Logo.svg.png"
                                        width="22"
                                        height="22"
                                        alt="logo"
                                    />
                                </div>
                                Login with Google
                            </button>
                        )}
                        cookiePolicy={"single_host_origin"}
                    />
                </div>
            </div>
        )
    }

    renderError() {
        const { loginError } = this.props

        const browser = useCheckBrowser()
        const os = useGetOs()

        const log = {
            browser,
            os,
            error: loginError,
            context: 'Error while logging in'
        }

        pino.error(log)

        var contentView = (
            <div>
                <p className="dark-text mb-0">Error Code: {loginError.error}</p>
                <p className="dark-text mb-0">Details: {loginError.details}</p>
            </div>
        )

        if (loginError.error === "idpiframe_initialization_failed") {
            // cookies are not enabled

            contentView = (
                <div>
                    <p className="dark-text mb-2">Error Code: {loginError.error}</p>
                    <p className="dark-text mb-0">
                        Cookies are not enabled in current environment, this can usually happen if you're in a private
                        window, switch to a normal window and try again!
                    </p>
                </div>
            )
        }

        return (
            <div className="main-wrapper">
                <div className="d-flex align-items-center h-100">
                    <div className="d-flex justify-content-center w-100">
                        <div className="field-holder p-5 w-50">
                            <h2 className="text-center dark-text">Error Logging In</h2>
                            <div className="mt-4">
                                <p className="dark-text mb-0 font-weight-bold mb-2">
                                    Please contact us at team@gatherapp.live with these details:
                                </p>
                                {contentView}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { loginError, isAuthenticated, location, isVerifying } = this.props
        if (loginError) {
            return this.renderError()
        } else if (isAuthenticated) {
            if (location.state) {
                return <Redirect exact from={"/"} to={location.state.from} />
            } else {
                return <Redirect exact from={"/"} to="/space" />
            }
        } else if (isVerifying) {
            return (
                <div className="main-wrapper">
                    <div className="d-flex align-items-center h-100">
                        <div className="d-flex justify-content-center w-100"></div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="main-wrapper">
                    <div className="container scroll-view-fixed" style={{ flex: 2, overflow: "hidden" }}>
                        <div className="d-flex justify-content-center align-items-center h-100 row">
                            {this.renderSignUpUI()}
                        </div>
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying,
    }
}
export default connect(mapStateToProps)(Login)
